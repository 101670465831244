import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';

class NoteForm extends Component {

  render() {
    const {
      isNoteFormOpen,
      orderToAddNote,
      isSubmitting,
      handleNotesFormClose,
      handleOrderNotesChange,
      saveOrderNotes
    } = this.props;

    return (
      <Dialog
        fullWidth={true}
        open={isNoteFormOpen}
        onClose={() => handleNotesFormClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Notes</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "2rem" }}>
            Add notes for {orderToAddNote && orderToAddNote.description} by{" "}
            {orderToAddNote && orderToAddNote.name}
          </DialogContentText>
          <TextField
            autoFocus
            id="name"
            label="Note"
            type="text"
            name="orderNotes"
            onChange={handleOrderNotesChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => handleNotesFormClose()}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            variant="contained"
            onClick={() => saveOrderNotes(orderToAddNote.id)}
            color="primary"
          >
            Add Note
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default NoteForm;
