import React from 'react';
import { findIndex } from 'lodash';

const OrderNotes = ({ notes }) => {
  const indexOfCreditCard = findIndex(notes, (note) => {
    return new RegExp("####").test(note);
  });

  if (indexOfCreditCard !== -1) {
    return notes.map((note, index) => {
      if (index > indexOfCreditCard + 1) {
        return (
          <React.Fragment key={index}>
            <span>- {note.replace("-", "")}</span>
            <br />
          </React.Fragment>
        );
      }

      return null;
    });
  } else {
    return notes.map((note, index) => (
      <React.Fragment key={index}>
        <span>- {note}</span>
        <br />
      </React.Fragment>
    ));
  }
}

export default OrderNotes;