import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class DialogConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
  }

  destroy() {
    this.setState({ open: false });
    this.props.destroy();
  }

  onConfirm() {
    this.setState({ open: false });
    this.props.destroy();
    this.props.onConfirm();
  } 
  
  onCancel() {
    this.setState({ open: false });
    this.props.destroy();
    this.props.onCancel();
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.onCancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.onCancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => this.onConfirm()} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogConfirm.defaultProps = {
  title: "Confirm",
  description: "",
}

export default DialogConfirm;