import React from "react";
import { Typography } from "@material-ui/core";

export default () =>
  <div className="NotFound">
    <Typography
      variant="display2"
      color="error">
      Page not found...
    </Typography>
  </div>;
