import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import BLUE_JAYS_ROWS from "../constants/BlueJaysSeatRows";

class BlueJaysRowSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  getRows() {
    const { section } = this.props;
    const sectionNo = section.split(" ").pop();

    if (["1", "5"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 5, "WC");
    } else if (["2", "3", "4"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 5);
    } else if (["16", "17", "18", "19", "29", "30", "31", "32", "242", "243"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 11);
    } else if (["20", "21", "22", "23", "24", "25", "26", "27", "28"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 8);
    } else if (sectionNo === "108") {
      return BLUE_JAYS_ROWS.generateRows(22, 36, "WC");
    } else if (sectionNo === "109") {
      return BLUE_JAYS_ROWS.generateRows(21, 39, "WC");
    } else if (["110", "111", "112"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(7, 39, "WC");
    } else if (["113", "114", "115", "134", "135"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 36, "WC");
    } else if (["116", "132"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(7, 40, "41D");
    } else if (["117", "121", "127", "131"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 16, "WC");
    } else if (["118", "119", "129", "130"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 20, "21D");
    } else if (["120", "128"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 19, "20D");
    } else if (["122", "126"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 15, "WC");
    } else if (["123", "124", "125"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 14, "WC");
    } else if (sectionNo === "133") {
      return BLUE_JAYS_ROWS.generateRows(1, 40, "41D");
    } else if (["136", "137"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 39, "WC");
    } else if (sectionNo === "138") {
      return BLUE_JAYS_ROWS.generateRows(2, 39, "WC");
    } else if (sectionNo === "139") {
      return BLUE_JAYS_ROWS.generateRows(12, 39, "WC");
    } else if (sectionNo === "140") {
      return BLUE_JAYS_ROWS.generateRows(22, 39);
    } else if (sectionNo === "141") {
      return BLUE_JAYS_ROWS.generateRows(26, 32, "WC");
    } else if (["142", "143", "239", "240", "241"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 9);
    } else if (["Lower Zone B 144", "Lower Zone B 145", "Lower Zone B 146"].includes(section)) {
      return BLUE_JAYS_ROWS.generateRows(1, 9);
    } else if (["Lower Zone A 144", "Lower Zone A 145", "Middle Zone 244"].includes(section)) {
      return BLUE_JAYS_ROWS.generateRows(1, 6);
    } else if (section === "Lower Zone A 146") {
      return BLUE_JAYS_ROWS.generateRows(1, 4);
    } else if (section === "Lower Zone A 147") {
      return BLUE_JAYS_ROWS.generateRows(1, 3);
    } else if (section === "Lower Zone B 147") {
      return BLUE_JAYS_ROWS.generateRows(1, 7, "WC");
    } else if (section === "Lower Zone A 148") {
      return BLUE_JAYS_ROWS.generateRows(1, 2);
    } else if (section === "Lower Zone B 148") {
      return BLUE_JAYS_ROWS.generateRows(1, 7);
    } else if (["CORONA ROOFTOP PATIO", "LEFT FIELD BALCONY", "RIGHT FIELD BLEACHERS", "ROGERS LANDING", "SCHNEIDERS PORCH", "TD PARK SOCIAL", "THE CATCH BAR", "THE STOP"].includes(section)) {
      return BLUE_JAYS_ROWS.GA_ROW;
    } else if (section.includes("Courtside")) {
      return BLUE_JAYS_ROWS.COURTSIDE_ROWS
    } else if (["TM LOUNGE", "HOME RUN ZONE"].includes(section)) {
      return BLUE_JAYS_ROWS.SINGLE_ROW;
    } else if (["W111L", "W111R"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.F_ROW;
    } else if (["224", "225", "226", "227", "233"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 10);
    } else if (["228", "229", "230", "231", "232", "234", "235", "236", "237", "238"].includes(sectionNo)) {
      return BLUE_JAYS_ROWS.generateRows(1, 13);
    } else if (section.includes("Lower Zone")) {
      return BLUE_JAYS_ROWS.LOWER_ZONE_ROWS;
    } else if (section.includes("WESTJET FLIGHT DECK")) {
      return BLUE_JAYS_ROWS.WESTJETFLIGHTDECK_ROW;
    } else {
      return BLUE_JAYS_ROWS.DEFAULT_ROWS;
    }
  };

  handleSelection = (event) => {
    this.setState({
      value: event.target.value,
    });

    this.props.onRowSelection(event.target.value);
  };  

  render() {
    return (
      <FormControl>
        <InputLabel>Row</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: "row",
            id: "row",
          }}
          placeholder="Seat Row"
          fullWidth
        >
          {this.props.section && this.getRows().map((row, index) => (
            <MenuItem selected={row.value === this.props.value} value={row.value} key={index}>
              {row.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

BlueJaysRowSelect.propsType = {
  classes: PropTypes.object.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  row: PropTypes.string.isRequired,
};

export default BlueJaysRowSelect;
