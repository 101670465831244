import React from 'react';
import ReactDOM from "react-dom";
import DialogAlert from './DialogAlert';
import DialogConfirm from './DialogConfirm';
import DialogConfirmInput from './DialogConfirmInput';
import DialogLoading from './DialogLoading';

const renderAlert = (title, description, type) => {
  const modalEl = document.createElement("div");
  const rootElement = document.getElementById("root");
  rootElement.appendChild(modalEl);

  function destroy() {
    rootElement.removeChild(modalEl);
  }

  function render() {
    ReactDOM.render(<DialogAlert type={type} title={title} description={description} destroy={destroy} />, modalEl);
  }

  render();
}

const alert = (title, description) => {
  renderAlert(title, description, null);
}

const success = (title, description) => {
  renderAlert(title, description, "success");
}

const error = (title, description) => {
  renderAlert(title, description, "error");
}

const apiError = (error) => {
  let description;
  if (error.response) {
    description = `${error.response.status} ${error.response.statusText}`;
  } else {
    description = "Internal server error.";
  }
  renderAlert("Error", description, "error");
}

const info = (title, description) => {
  renderAlert(title, description, "info");
}

const warning = (title, description) => {
  renderAlert(title, description, "warning");
}

const confirm = (title, description) => {
  const modalEl = document.createElement("div");
  const rootElement = document.getElementById("root");
  rootElement.appendChild(modalEl);

  function destroy() {
    rootElement.removeChild(modalEl);
  }

  return new Promise((resolve, reject) => {
    function onConfirm() {
      resolve(true);
    }
    function onCancel() {
      resolve(false);
    }
    ReactDOM.render(
      <DialogConfirm title={title} description={description} destroy={destroy} onConfirm={onConfirm} onCancel={onCancel} />,
      modalEl
    );
  })
};

const confirmInput = ({ title, description, value, inputRequired, inputType, inputLabel }) => {
  const modalEl = document.createElement("div");
  const rootElement = document.getElementById("root");
  rootElement.appendChild(modalEl);

  function destroy() {
    rootElement.removeChild(modalEl);
  }

  return new Promise((resolve, reject) => {
    function onConfirm(value) {
      resolve({ confirmed: true, value });
    }
    function onCancel() {
      resolve({ confirmed: false, value: null });
    }
    ReactDOM.render(
      <DialogConfirmInput
        title={title}
        description={description}
        value={value}
        inputLabel={inputLabel}
        inputType={inputType}
        inputRequired={inputRequired}
        destroy={destroy}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />,
      modalEl
    );
  })
};

const loading = (open) => {
  const rootElement = document.getElementById("root");
  let loadingEl = document.getElementById("cdialog-loading");

  if (!loadingEl) {
    loadingEl = document.createElement("div");
    loadingEl.setAttribute('id', 'cdialog-loading');
  }

  if (open) {
    rootElement.appendChild(loadingEl);
    ReactDOM.render(<DialogLoading />, loadingEl);
  } else {
    ReactDOM.unmountComponentAtNode(loadingEl);
  }
}

export default { alert, success, error, apiError, info, warning, confirm, confirmInput, loading }