const defaultRows = () => {
  const arr = [];

  for (let i = 1; i <= 29; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

export default {
  DEFAULT_ROWS: defaultRows(),
  WC_ROWS: [
    {
      value: 'WC',
      label: `Row WC`,
    },
  ],
};