const accessiblePages = [
  "/",
  "/orders",
  "/sell-requests",
  "/sell-requests/:params",
  "/sell-request-approval",
  "/search_orders",
  "/seats",
  "/ticket-evolution",
];

export const hasAccess = (pathName, userGroup) => {
  const isAccessiblePage = accessiblePages.find((path) => {
    const urlPath = pathName.split("/");
    const allowedPath = path.split("/");
    const isAllowed = allowedPath.map((pathChunk, index) => {
      const isChunkEqual =
        pathChunk === urlPath[index] && urlPath.length === allowedPath.length;
      return pathChunk === ":params" ? true : isChunkEqual;
    });
    return String(isAllowed.find((chunk) => !chunk)) === "false" ? false : true;
  });

  if (userGroup === "admins") {
    return true;
  }

  if (userGroup === "supports" && isAccessiblePage) {
    return true;
  }

  return false;
};

export const isAllowedAction = (userGroup) => {
  if (userGroup === "supports") {
    return false;
  }

  if (userGroup === "admins") {
    return true;
  }

  return false;
};

export const isValidUserGroup = (userGroup) => {
  return ["admins", "supports"].includes(userGroup);
};
