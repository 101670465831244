export const TeamSlugs = {
  "calgary-flames": "Calgary Flames",
  "edmonton-oilers": "Edmonton Oilers",
  "vancouver-canucks": "Vancouver Canucks",
  "winnipeg-jets": "Winnipeg Jets",
  "ottawa-senators": "Ottawa Senators",
  "toronto-maple-leafs": "Toronto Maple Leafs",
  "toronto-raptors": "Toronto Raptors",
};

export const getTeamsBySlug = (teamSlugs) => {
  const nbaOptions = [
    "TBD",
    "Celtics",
    "Magic",
    "Wizards",
    "Timberwolves",
    "Bucks",
    "Kings",
    "Lakers",
    "Clippers",
    "Bulls",
    "Grizzlies",
    "Cavaliers",
    "Pistons",
    "Rockets",
    "Thunder",
    "Nuggets",
    "Mavericks",
    "Nets",
    "Hawks",
    "Raptors",
    "Trail Blazers",
    "Heat",
    "Suns",
    "Warriors",
    "76ers",
    "Pelicans",
    "Pacers",
    "Hornets",
    "Spurs",
    "Jazz",
    "Knicks",
  ];

  const nhlOptions = [
    "TBD",
    "Hurricanes",
    "Blue Jackets",
    "Devils",
    "Islanders",
    "Rangers",
    "Flyers",
    "Penguins",
    "Capitals",
    "Bruins",
    "Sabres",
    "Red Wings",
    "Panthers",
    "Canadiens",
    "Senators",
    "Lightning",
    "Leafs",
    "Coyotes",
    "Blackhawks",
    "Avalanche",
    "Stars",
    "Wild",
    "Predators",
    "Blues",
    "Jets",
    "Ducks",
    "Flames",
    "Oilers",
    "Kings",
    "Sharks",
    "Kraken",
    "Canucks",
    "Golden Knights",
  ];
  const cflOptions = [
    "Calgary Stampeders",
    "Edmonton Elks",
    "Winnipeg Blue Bombers",
    "BC Lions",
    "Toronto Argonauts",
    "Ottawa Redblacks",
    "Saskatchewan Roughriders",
    "Hamilton Tiger-Cats",
    "Grey Cup"
  ];
  switch (teamSlugs) {
    case "calgary-flames":
      return nhlOptions;
    case "winnipeg-jets":
      return nhlOptions;
    case "edmonton-oilers":
      return nhlOptions;
    case "vancouver-canucks":
      return nhlOptions;
    case "toronto-maple-leafs":
      return nhlOptions;
    case "ottawa-senators":
      return nhlOptions;
    case "toronto-raptors":
      return nbaOptions;
    case "calgary-stampeders": {
      return cflOptions;
    }
    case "edmonton-elks": {
      return cflOptions;
    }
    case "winnipeg-blue-bombers": {
      return cflOptions;
    }
    case "bc-lions": {
      return cflOptions;
    }
    case "hamilton-tigercats": {
      return cflOptions;
    }
    case "saskatchewan-roughriders": {
      return cflOptions;
    }
    case "grey-cup": {
      return cflOptions;
    }
    case "toronto-argonauts": {
      return cflOptions;
    }
    case "ottawa-redblacks": {
      return cflOptions;
    }
    case "toronto-blue-jays":
      return ["TBD"];
    default:
      return ["TBD"];
  }
};
