import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import validator from "validator";
import { get, has, isEmpty, isNil } from 'lodash';
import CDialog from '../CDialog';
import { API } from 'aws-amplify';

class TransferredDetailsForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      input: {
        orderId: null,
        email: "",
        transferMethod: ""
      },
      isSubmitted: false,
      isSending: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!isNil(this.props.order)) {
      const { order } = this.props;
      const prevOrderId = prevProps.order ? prevProps.order.id : null;
      if (order.id !== prevOrderId) {
        this.setState({
          input: {
            orderId: order.id,
            email: order.email,
            transferMethod: ""
          }
        });
      }
    }
  }

  validateInput() {
    const { email, transferMethod } = this.state.input;
    return {
      ...(validator.isEmpty(email) && { email: "Email is required" }),
      ...(!validator.isEmpty(email) && !validator.isEmail(email) && { email: "Please enter a valid email address" }),
      ...(validator.isEmpty(transferMethod) && { transferMethod: "Transfer method is required" })
    };
  }

  handleChange(event) {
    this.setState({
      input: {
        ...this.state.input,
        [event.target.name]: event.target.value
      }
    });
  }

  sendTicket = async (e) => {
    e.preventDefault();

    this.setState({ isSubmitted: true });
    if (!isEmpty(this.validateInput())) {
      return;
    }

    try {
      this.setState({ isSending: true });
      const { currUser, onSuccess } = this.props;
      const { input } = this.state;
      const response = await API.post("v2", `marketplace/orders/${input.orderId}/mark-as-sent`, {
        headers: { email: currUser.email },
        body: input
      });

      this.setState({
        isSubmitted: false,
        isSending: false
      });
      onSuccess(response);
    } catch (e) {
      this.setState({ isSending: false });
      CDialog.error("Error!", "Failed to send");
    }
  }

  render() {
    const { isOpen, order, handleClose } = this.props;
    const { input, isSending, isSubmitted } = this.state;
    const validated = this.validateInput();

    return (
      <Dialog fullWidth={true} open={isOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Transferred Details</DialogTitle>
        <DialogContent>
          {order && (
            <>
              <Typography variant="body1">{order.name}</Typography>
              <Typography variant="body1">{order.mobileNo}</Typography>
              <Typography variant="body1">{order.email}</Typography>
              <Typography variant="body1" style={{ marginBottom: "1rem" }}>
                {order.description}
              </Typography>
            </>
          )}
          <form onSubmit={this.sendTicket}>
            <TextField
              margin="dense"
              id="email"
              name="email"
              label="Email Sent To *"
              type="email"
              value={input.email}
              onChange={this.handleChange}
              fullWidth
              error={isSubmitted && has(validated, 'email')}
              helperText={isSubmitted && get(validated, 'email')}
            />
            <FormControl fullWidth margin="dense" error={isSubmitted && has(validated, 'transferMethod')}>
              <InputLabel htmlFor="transfer-method">
                Transfer Method *
              </InputLabel>
              <Select
                value={input.transferMethod}
                onChange={this.handleChange}
                inputProps={{
                  name: 'transferMethod',
                  id: 'transfer-method',
                }}
              >
                <MenuItem value=""><em>Select</em></MenuItem>
                <MenuItem value="Account Manager">Account Manager</MenuItem>
                <MenuItem value="Ticketmaster">Ticketmaster</MenuItem>
                <MenuItem value="Link">Link</MenuItem>
              </Select>
              {isSubmitted && has(validated, 'transferMethod') && (
                <FormHelperText>{get(validated, 'transferMethod')}</FormHelperText>
              )}
            </FormControl>
            <Grid container justify="flex-end" style={{ marginTop: "1rem" }}>
              <Grid item>
                <Button disabled={isSending} onClick={() => handleClose()}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button type="submit" disabled={isSending} variant="contained" color="primary">
                  {isSending ? 'Sending' : 'Send'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

export default TransferredDetailsForm;
