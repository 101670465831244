import React, { Component } from "react";
import { API } from "aws-amplify";
import querySearch from "stringquery";
import moment from "moment-timezone";
import _ from "lodash";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import { renderZoneName, renderRowName, getTeamFullName } from "../libs/shared/helpers";

const styles = (theme) => ({
  gamesList: {
    maxWidth: 720,
    backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.up("md")]: {
    root: {
      margin: "3rem 0",
    },
    requestDetails: {
      margin: "3rem 0",
    },
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      margin: "1rem 0 3rem",
    },
    requestDetails: {
      margin: "2rem 0",
    },
  },
});

class SellRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      request: null,
      isSubmitting: "",
      isApproving: false,
      isRejecting: false,
    };
  }

  async componentDidMount() {
    const request = await this.request();
    this.setState({
      request
    });
  }

  request() {
    const { id } = this.props.match.params;
    const { sellerId } = querySearch(this.props.location.search);
    var data = API.get(
      "v2",
      `marketplace/sellRequests/by/sellerId/${sellerId}/?requestId=${id}&includeMinPrices=true`
    );
    return data;
  }

  renderRequest(request) {
    const { classes } = this.props;
    const game = request.games && request.games.length > 0 ? request.games[0] : null;
    let teamName = "";
    let sectionName = `${request.section} ${request.sectionNo}`;
    let rowName = `${request.row}`;
    if (game) {
      teamName = getTeamFullName(game.homeTeamSlug);
      sectionName = renderZoneName(request.section, request.sectionNo, request.row, game.homeTeamSlug, game.isSpecial);
      rowName = renderRowName(request.section, request.row, game.homeTeamSlug, game.isSpecial);
    }
    const source = request.source;

    return (
      <div className={classes.requestDetails}>
        <Typography variant="caption">Seller Email</Typography>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          {request.sellerEmail}
        </Typography>

        <Typography variant="caption">Seller Mobile No.</Typography>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          {request.sellerMobileNo ? request.sellerMobileNo : "-"}
        </Typography>

        <Typography variant="caption">Seller IP Address</Typography>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          {request.sellerIpAddress ? request.sellerIpAddress : "-"}
          {source && (
            <>
              {" "}
              {source.city ? source.city.replace("-", "") : null}{" "}
              {source.region ? source.region.replace("-", "") : null}{" "}
              {source.country ? source.country.replace("-", "") : null}
            </>
          )}
        </Typography>

        <Typography variant="caption">Previous Approved Requests</Typography>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          {request.totalApprovedRequests && request.totalApprovedRequests > 0 ? request.totalApprovedRequests : "NEW"}
        </Typography>

        <Typography variant="caption">Team</Typography>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          {teamName}
        </Typography>

        <Typography variant="caption">Seat Details</Typography>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          {sectionName} Row {rowName}{" "}
          {/* {request.isAisleSeats === true ? "Aisle Seats" : ""} */}
        </Typography>

        <Typography variant="caption">No. of Seats</Typography>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          {request.noOfSeats}
        </Typography>

        <Typography variant="caption">Request Date</Typography>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          {moment
            .tz(Number(request.createdAt), "America/Edmonton")
            .format("MMM DD, YYYY hh:mm A")}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          {request.isAisleSeat ? "*Aisle Seats" : ""}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          {request.isWheelchair ? "*Wheelchair" : null}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          {request.isObstructedView ? "*Obstructed View" : ""}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          {Number(request.seatNumberStart) > 0
            ? `Seats ${request.seatNumberStart} - ${request.seatNumberEnd}`
            : ""}
        </Typography>
      </div>
    );
  }

  async approveSingleListing(sellerId, id, gameId) {
    const { currUser } = this.props;
    try {
      this.setState({ isSubmitting: gameId });

      await API.post(
        "v2",
        `marketplace/sellRequests/approve?sellerId=${sellerId}&requestId=${id}&games=${gameId}`,
        { headers: { email: currUser.email } }
      );
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  }

  async rejectSingleListing(sellerId, id, gameId) {
    const { currUser } = this.props;
    try {
      this.setState({ isRejecting: gameId });

      await API.post(
        "v2",
        `marketplace/sellRequests/reject?sellerId=${sellerId}&requestId=${id}&games=${gameId}`,
        { headers: { email: currUser.email } }
      );

      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  }

  async approveRequest(sellerId, id) {
    const { currUser } = this.props;
    try {
      this.setState({ isApproving: true });

      await API.post(
        "v2",
        `marketplace/sellRequests/approve?sellerId=${sellerId}&requestId=${id}`,
        { headers: { email: currUser.email } }
      );

      this.props.history.push("/sell-requests");
    } catch (e) {
      this.setState({ isApproving: false });
      console.error(e);
    }
  }

  async rejectRequest(sellerId, id) {
    const { currUser } = this.props;
    try {
      this.setState({ isRejecting: true });

      await API.post(
        "v2",
        `marketplace/sellRequests/reject?sellerId=${sellerId}&requestId=${id}`,
        { headers: { email: currUser.email } }
      );

      this.props.history.push("/sell-requests");
    } catch (e) {
      console.error(e);
    }
  }

  async getNumberOfPriorRequestApprovals(requests) {
    const requestCount = _.filter(requests, (request) => {
      return request.isApproved === true;
    }).length;
    return requestCount;
  }

  formatPrice(standardizedSection, game) {
    const { price } = game;
    const priceString = parseFloat(price).toFixed(2);
    const minSectionPrice = this.getMinSectionPrice(standardizedSection, game);
    // check if the price is lower than the min price for that zone
    // if it is, add the price difference to the price string
    // check if minSectionPrice is null
    let requestedPriceString = `Requested Price: CAD$ ${priceString}`;
    var priceDifferenceString = "";
    var initPriceDifferenceString = "";
    if ("priceDifference" in game && game.priceDifference && game.priceDifference <= -0.0000001) {
      const initPriceDifferencePercentage = parseFloat(game.priceDifference * 100).toFixed(2);
      initPriceDifferenceString = ` (${initPriceDifferencePercentage}%)`;
    }
    if (minSectionPrice !== null && price < minSectionPrice) {
      const priceDifferenceDecimal =
        (minSectionPrice - price) / minSectionPrice;
      const priceDifferencePercentage = parseFloat(
        priceDifferenceDecimal * 100
      ).toFixed(2);
      priceDifferenceString = ` (-${priceDifferencePercentage}% Live)`;
    }
    return requestedPriceString + initPriceDifferenceString + priceDifferenceString;
  }

  // write a function that takes in section and game
  // and returns the min price for that section and game
  getMinSectionPrice(standardizedSection, game) {
    // retrieve the price and min prices from game object
    const { minPrices } = game;
    // find the min price for the section'
    const minSectionPrice = minPrices[standardizedSection + "MinPrice"];
    // return the min price for the section
    return minSectionPrice;
  }

  renderGames({ sellerId, id, games, isApproved, standardizedSection }) {
    const { classes } = this.props;
    const { isSubmitting, isApproving, isRejecting } = this.state;

    const isAllGamesApproved =
      games.filter((game) => !game.isApproved).length === 0;
    const isAllGamesRejected =
      games.filter((game) => !game.isRejected).length === 0;

    const sortedGames = _.sortBy(games, ["date", "orderNo"], "asc");

    return (
      <div className={classes.gamesList}>
        <Typography variant="title" style={{ marginBottom: ".5rem" }}>
          Games
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => this.approveRequest(sellerId, id)}
          disabled={
            isApproved ||
            isApproving ||
            isAllGamesApproved ||
            isAllGamesRejected
          }
        >
          {isApproved || isAllGamesApproved ? "Approved" : "Approve All"}
        </Button>
        <Button
          style={{ marginLeft: "1rem" }}
          color="secondary"
          variant="contained"
          onClick={() => this.rejectRequest(sellerId, id)}
          disabled={
            isApproved ||
            isRejecting ||
            isAllGamesApproved ||
            isAllGamesRejected
          }
        >
          {isAllGamesRejected ? "Rejected" : "Reject All"}
        </Button>
        <List>
          {sortedGames.map((game) => {
            const regularGameDate = moment.tz(game.date, game.timezone)
              .format("MMM DD, YYYY");
            const playoffsGameDate = game.game;
            const gameDate =
              game.isPlayoffs && !game.showOpponent
                ? playoffsGameDate
                : regularGameDate;

            return (
              <React.Fragment key={game.id}>
                <ListItem>
                  <ListItemText
                    primary={
                      gameDate !== null
                        ? game.shortName
                        : `${game.shortName} ${gameDate}`
                    }
                    secondary={
                      <>
                        {gameDate} <br />
                        {this.formatPrice(standardizedSection, game)}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ marginRight: "1rem" }}
                      onClick={() =>
                        this.approveSingleListing(sellerId, id, game.id)
                      }
                      disabled={
                        game.isApproved ||
                        isSubmitting === game.id ||
                        game.isRejected
                      }
                    >
                      {game.isApproved ? "Approved" : "Approve"}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() =>
                        this.rejectSingleListing(sellerId, id, game.id)
                      }
                      disabled={game.isRejected || isRejecting === game.id}
                    >
                      {game.isRejected ? "Rejected" : "Reject"}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      </div>
    );
  }

  render() {
    const { request } = this.state;
    const { classes } = this.props;
    return (
      request && (
        <div className={classes.root} id="SellRequests">
          <Grid container justify="center">
            <Grid item xs={12} md={6}>
              <Typography variant="display2">Sell Request</Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} md={6}>
              {this.renderRequest(request)}
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} md={6}>
              {this.renderGames(request)}
            </Grid>
          </Grid>
        </div>
      )
    );
  }
}

export default withStyles(styles)(SellRequest);
