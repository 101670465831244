import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import SeatSections from '../constants/RaptorsSeatSections';
import { InputLabel, FormControl } from '@material-ui/core';

const zones = SeatSections.ZONES.map((menu, index) => {
  return (
    <MenuItem value={menu.label} key={index}>
      {menu.label}
    </MenuItem>
  );
});

class RaptorsSectionSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.section
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.section !== this.props.section) {
      this.setState({ value: nextProps.section });
    }
  }

  handleSelection = event => {
    this.setState({
      [event.target.name]: event.target.value
    });

    this.props.onSectionSelection(event.target.value);
  };

  render() {
    return (
      <FormControl>
        <InputLabel>Section</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: 'section',
            id: 'section'
          }}
          placeholder="Section"
        >
          {zones}
        </Select>
      </FormControl>
    );
  }
}

RaptorsSectionSelect.propsType = {
  onSectionSelection: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired
};

export default RaptorsSectionSelect;
