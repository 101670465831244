import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MAPLE_LEAFS_ROWS from "../constants/MapleLeafsSeatRows";

class MapleLeafsRowSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  getRows() {
    const { section } = this.props;

    if (section.includes("Upper")) {
      return MAPLE_LEAFS_ROWS.UPPER_ROWS;
    } else if (section.includes("Molson") || section.includes("GBOX") || section.includes("Suite")) {
      return MAPLE_LEAFS_ROWS.SINGLE_ROW;
    } else if (section === "Lower Zone 112" || section === "Lower Zone 113" || section === "Lower Zone 114" ||
      section === "Lower Zone 115" || section === "Lower Zone 116" || section === "Lower Zone 101" || section === "Lower Zone 102" ||
      section === "Lower Zone 103" || section === "Lower Zone 104") {
      return MAPLE_LEAFS_ROWS.NUMBER_AND_LETTER_ROWS;
    } else {
      return MAPLE_LEAFS_ROWS.DEFAULT_ROWS;
    }
  };

  handleSelection = (event) => {
    this.setState({
      value: event.target.value,
    });

    this.props.onRowSelection(event.target.value);
  };

  render() {
    return (
      <FormControl>
        <InputLabel>Row</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: "row",
            id: "row",
          }}
          placeholder="Seat Row"
          fullWidth
        >
          {this.props.section && this.getRows().map((row, index) => (
            <MenuItem selected={row.value === this.props.value} value={row.value} key={index}>
              {row.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

MapleLeafsRowSelect.propsType = {
  classes: PropTypes.object.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  row: PropTypes.string.isRequired,
};

export default MapleLeafsRowSelect;
