import React, { Component } from "react";
import PropTypes from "prop-types";
import CanucksRowSelect from "./CanucksRowSelect";
import LionsSpecialRowSelect from "./LionsSpecialRowSelect";
import RowSelect from "./RowSelect";
import LionsRowSelect from "./LionsRowSelect";
import AbbotsfordCanucksRowSelect from "./AbbotsfordCanucksRowSelect";

class SpecialRowSelect extends Component {
  render() {
    const { selectedTeam, section, rowNo, onRowSelection } = this.props;
    if (selectedTeam === "toronto-raptors") {
      return (
        <CanucksRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "vancouver-whitecaps-fc") {
      return (
        <LionsRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "bc-lions") {
      return (
        <LionsSpecialRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else if (selectedTeam === "vancouver-canucks") {
      return (
        <AbbotsfordCanucksRowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    } else {
      return (
        <RowSelect
          section={section}
          row={rowNo}
          onRowSelection={onRowSelection}
        />
      );
    }
  }
}

SpecialRowSelect.propsType = {
  selectedTeam: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  rowNo: PropTypes.string.isRequired,
  onRowSelection: PropTypes.func.isRequired,
};

export default SpecialRowSelect;