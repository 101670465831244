import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';

class TableLoading extends Component {
  render() {
    return (
      <div style={{ textAlign: 'center', marginTop: '5px' }}>
        <CircularProgress />
      </div>
    );
  }
}

export default TableLoading;