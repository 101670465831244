import { flatten } from "lodash";

const zoneGroup = [
  {
    label: "Lower Zone",
    numbers: [
      ...["101", "102", "103", "104", "105", "106", "107", "108", "109"],
      ...["111", "112", "113", "114", "115", "116", "117", "118", "119"],
    ],
  },
  {
    label: "Premium Zone",
    numbers: ["C1", "C2", "C3", "C4", "C5", "C6", "C7", "C8", "C9", "C10", "C11"]
  },
  {
    label: "Upper Zone",
    numbers: [
      ...["202", "203", "204", "205", "206", "207", "208"],
      ...["212", "213", "214", "215", "216", "217", "218"]
    ],
  },
];

const otherZones = [
  {
    label: "The Striply",
  },
  {
    label: "Touchdown Lounge",
  },
  {
    label: "Stelco Northend",
  },
];

const zones = zoneGroup.map((zone) => {
  return zone.numbers.map((number) => ({ label: `${zone.label} ${number}` }));
});

export default Object.freeze({
  ZONES: flatten([...zones, ...otherZones]),
});

