import React, { Component } from "react";
import { API } from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  ListItem,
  ListItemText,
  List
} from "@material-ui/core";
import TableLoading from "../components/Table/TableLoading";
import TableEmpty from "../components/Table/TableEmpty";
import CDialog from "../components/CDialog";
import validator from "validator";
import { get, has, isEmpty, map } from "lodash";

const styles = {
  root: {
    flexGrow: 1,
  },
  tableContainer: {
    marginTop: "5px"
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    bottom: 0,
  },
  listItem: {
    paddingLeft: 0
  },
  note: {
    cursor: "pointer"
  }
};

class SellRequestApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoApproval: [],
      manualApproval: [],
      isLoadingAuto: true,
      isLoadingManual: true,
      isSaving: false,
      openForm: false,
      openNote: false,
      isSubmitted: false,
      input: {
        approval_type: "",
        approval_by: "",
        value: "",
        note: ""
      },
      edit: null,
      searchAuto: "",
      searchManual:""
    };

    this.handleChange = this.handleChange.bind(this);
    this.changeNote = this.changeNote.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.searchAuto = this.searchAuto.bind(this);
    this.searchManual = this.searchManual.bind(this);
  }

  componentWillMount() {
    this.getAutoData();
    this.getManualData();
  }

  getSellReqApproval(approvalType, search = "") {
    const searchQuery = isEmpty(search) ? "" : `&search=${search}`;
    return API.get(
      "v2",
      `marketplace/sellRequests/approval?approval_type=${approvalType}${searchQuery}`
    );
  }

  async getAutoData(search) {
    const responseAutoApproval = await this.getSellReqApproval("AUTO", search);
    this.setState({
      isLoadingAuto: false,
      autoApproval: responseAutoApproval.approval_list
    });
  }

  async getManualData(search) {
    const responseManualApproval = await this.getSellReqApproval("MANUAL", search);
    this.setState({
      isLoadingManual: false,
      manualApproval: responseManualApproval.approval_list,
    });
  }

  openForm(approvalType) {
    this.setState({
      openForm: true,
      input: {
        ...this.state.input,
        approval_type: approvalType
      }
    });
  }

  closeForm() {
    this.setState({
      openForm: false,
      isSubmitted: false,
      input: {
        approval_type: "",
        approval_by: "",
        value: "",
        note: ""
      }
    });
  }

  handleChange(event) {
    this.setState({
      input: {
        ...this.state.input,
        [event.target.name]: event.target.value
      }
    });
  };
  
  searchChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  changeNote(event) {
    this.setState({
      edit: {
        ...this.state.edit,
        note: event.target.value
      }
    });
  };

  validateInput() {
    const { approval_by, value } = this.state.input;
    const { autoApproval, manualApproval } = this.state;
    const approvalData = map([...autoApproval, ...manualApproval], 'value');
    return {
      ...(validator.isEmpty(approval_by) && { approval_by: "Approval by is required" }),
      ...(validator.isEmpty(value) && { value: "Value is required" }),
      ...(!validator.isEmpty(value) && approval_by === "email" && !validator.isEmail(value) && { value: "Please enter a valid email address" }),
      ...(!validator.isEmpty(value) && approvalData.includes(value) && { value: "Value already exists" })
    };
  }

  handleEdit(item) {
    this.setState({ edit: item, openNote: true });
  }

  closeNote(item) {
    this.setState({ edit: null, openNote: false });
  }

  save() {
    const { currUser} = this.props;

    this.setState({ isSubmitted: true });
    if (!isEmpty(this.validateInput())) {
      return;
    }

    this.setState({ isSaving: true });

    API.post("v2", "marketplace/sellRequests/create-approval", {
      headers: { email: currUser.email },
      body: this.state.input
    })
      .then(response => {
        this.setState({
          isSaving: false,
          isSubmitted: false,
        });

        if (this.state.input.approval_type === "AUTO") {
          this.setState({ autoApproval: [...this.state.autoApproval, response] })
        } else if (this.state.input.approval_type === "MANUAL") {
          this.setState({ manualApproval: [...this.state.manualApproval, response] })
        }
        this.closeForm();
        CDialog.success("Success", "New approval has been saved");
      }).catch(error => {
        if (error.response) {
          const { status, data } = error.response;
          if (status === 403) {
            CDialog.warning("Error", data.message);
          } else {
            CDialog.error("Error", "Internal Server Error");
          }
        } else {
          CDialog.error("Error", "Internal Server Error");
        }
        this.setState({ isSaving: false });
      });
  }

  update() {
    this.setState({ isSaving: true });
    const { edit, autoApproval, manualApproval } = this.state;
    const { currUser} = this.props;

    API.post("v2", "marketplace/sellRequests/update-approval", {
      headers: { email: currUser.email },
      body: edit
    })
      .then(response => {
        if (edit.approvalType === "AUTO") {
          const newAutoApproval = autoApproval.map(item => {
            if (response.id === item.id) {
              return { ...item, note: response.note }
            }
            return item;
          });
          this.setState({ autoApproval: newAutoApproval });
        } else if (edit.approvalType === "MANUAL") {
          const newManualApproval = manualApproval.map(item => {
            if (response.id === item.id) {
              return { ...item, note: response.note }
            }
            return item;
          });
          this.setState({ manualApproval: newManualApproval });
        }
        this.setState({ isSaving: false });
        this.closeNote();
        CDialog.success("Success", "The note has been saved");
      }).catch(error => {
        if (error.response) {
          const { status, data } = error.response;
          if (status === 403) {
            CDialog.warning("Error", data.message);
          } else {
            CDialog.error("Error", "Internal Server Error");
          }
        } else {
          CDialog.error("Error", "Internal Server Error");
        }
        this.setState({ isSaving: false });
      });
  }

  remove(approval) {
    const { currUser} = this.props;
    const { id, approvalType, value } = approval;
    CDialog.loading(true);
    API.post("v2", "marketplace/sellRequests/remove-approval", {
      headers: { email: currUser.email },
      body: {
        id,
        approval_type: approvalType
      }
    })
      .then(response => {
        CDialog.loading(false);
        CDialog.success("Success", `${value} successfully removed from ${approvalType}-APPROVED`);
        if (approvalType === "AUTO") {
          const newAutoApproval = this.state.autoApproval.filter(item => item.id !== id);
          this.setState({ autoApproval: newAutoApproval })
        } else if (approvalType === "MANUAL") {
          const newManualApproval = this.state.manualApproval.filter(item => item.id !== id);
          this.setState({ manualApproval: newManualApproval })
        }
      }).catch(error => {
        CDialog.loading(false);
        CDialog.error("Error", "Internal Server Error");
      });
  }

  handleDelete(approval) {
    const { approvalType, value } = approval;
    CDialog.confirm("Are you sure?", `Remove ${value} from ${approvalType}-APPROVED`)
      .then(confirm => {
        if (confirm) {
          this.remove(approval);
        }
      });
  }

  async searchAuto(e) {
    e.preventDefault();
    this.setState({ isLoadingAuto: true });
    this.getAutoData(this.state.searchAuto);
  }

  async searchManual(e) {
    e.preventDefault();
    this.setState({ isLoadingManual: true });
    this.getManualData(this.state.searchManual);
  }

  renderTable(approvalData, isLoading) {
    const { classes } = this.props;

    return (
      <Paper className={classes.tableContainer}>
        <Table padding="dense">
          <TableHead>
            <TableRow>
              <TableCell>Approval By</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Note</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {approvalData && approvalData.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.approvalBy}</TableCell>
                  <TableCell>{item.value}</TableCell>
                  <TableCell>
                    <div onClick={() => this.handleEdit(item)} className={classes.note}>{item.note ? item.note : "-"}</div>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={() => this.handleDelete(item)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
            {!isLoading && approvalData && approvalData.length === 0 && (
              <TableEmpty colSpan={10} />
            )}
          </TableBody>
        </Table>
        {isLoading && approvalData.length === 0 && <TableLoading />}
      </Paper>
    );
  }

  render() {
    const { classes } = this.props;
    const { autoApproval, manualApproval, input, isSubmitted, edit, isLoadingAuto, isLoadingManual, searchAuto, searchManual } = this.state;
    const validated = this.validateInput();

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="display2" style={{ marginBottom: "3rem" }}>
              Sell Request Approval
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={24}>
              <Grid item xl={5} md={6} sm={10}>
                <Typography component="h2" variant="headline" gutterBottom>
                  AUTO-APPROVED
                </Typography>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <form autoComplete="off" onSubmit={this.searchAuto}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <TextField
                            label="Search"
                            name="searchAuto"
                            value={searchAuto}
                            onChange={this.searchChange}
                          />
                        </Grid>
                        <Grid item>
                          <Button variant="contained" size="small" color="primary" type="submit" disabled={isLoadingAuto}>
                            Search
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => this.openForm("AUTO")}
                      disabled={isLoadingAuto}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                {this.renderTable(autoApproval, isLoadingAuto)}
              </Grid>
              <Grid item xl={5} md={6} sm={10}>
                <Typography component="h2" variant="headline" gutterBottom>
                  MANUAL-APPROVED
                </Typography>
                <Grid container justify="space-between">
                  <Grid item>
                    <form autoComplete="off" onSubmit={this.searchManual}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <TextField
                            label="Search"
                            name="searchManual"
                            value={searchManual}
                            onChange={this.searchChange}
                          />
                        </Grid>
                        <Grid item>
                          <Button variant="contained" size="small" color="primary" type="submit" disabled={isLoadingManual}>
                            Search
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => this.openForm("MANUAL")}
                      disabled={isLoadingManual}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                {this.renderTable(manualApproval, isLoadingManual)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.openForm}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">Add Approval</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Type: <b>{input.approval_type}</b>
            </DialogContentText>
            <br />
            <FormControl error={isSubmitted && has(validated, 'approval_by')} fullWidth>
              <InputLabel htmlFor="approval_by">Approval By *</InputLabel>
              <Select
                value={input.approval_by}
                onChange={this.handleChange}
                inputProps={{
                  name: 'approval_by',
                  id: 'approval_by',
                }}
              >
                <MenuItem value="email">email</MenuItem>
              </Select>
              {isSubmitted && has(validated, 'approval_by') && <FormHelperText id="approval_by">{get(validated, 'approval_by')}</FormHelperText>}
            </FormControl>
            <TextField
              margin="dense"
              id="value"
              name="value"
              label="Value *"
              type="text"
              fullWidth
              value={input.value}
              onChange={this.handleChange}
              error={isSubmitted && has(validated, 'value')}
              helperText={isSubmitted && get(validated, 'value')}
            />
            <TextField
              margin="dense"
              id="note"
              name="note"
              label="Note"
              type="text"
              fullWidth
              value={input.note}
              onChange={this.handleChange}
              error={isSubmitted && has(validated, 'note')}
              helperText={isSubmitted && get(validated, 'note')}
              autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeForm()} color="primary" disabled={this.state.isSaving}>
              Cancel
            </Button>
            <Button onClick={() => this.save()} color="primary" disabled={this.state.isSaving}>
              {this.state.isSaving ? 'Saving...' : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
        {edit && (
          <Dialog
            open={this.state.openNote}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Edit Note</DialogTitle>
            <DialogContent>
              <List disablePadding>
                <ListItem className={classes.listItem}>
                  <ListItemText primary={edit.approvalType} secondary={edit.value} />
                </ListItem>
              </List>
              <TextField
                margin="dense"
                id="note"
                name="note"
                label="Note"
                type="text"
                fullWidth
                value={edit.note}
                onChange={this.changeNote}
                autoFocus
                autoComplete="off"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.closeNote()} color="primary" disabled={this.state.isSaving}>
                Cancel
              </Button>
              <Button onClick={() => this.update()} color="primary" disabled={this.state.isSaving}>
                {this.state.isSaving ? 'Saving...' : 'Save'}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(SellRequestApproval);
