import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { API } from "aws-amplify";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import validator from "validator";
import { defaultTo, get, has, isEmpty, isNil } from 'lodash';

class FinalizeOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGame: props.selectedGame,
      game: null,
      selectedSeat: props.selectedSeat,
      qty: props.qty,
      seat: null,
      isLoading: true,
      paymentRefNo: null,
      succeeded: false,
      buyer: {
        name: "",
        email: "",
        mobileNo: "",
      },
      isSubmitted: false
    };

    this.submitForm = this.submitForm.bind(this);
  }

  async componentDidMount() {
    const game = await this.game(this.props.selectedGame);
    const seat = await this.seat(
      this.props.selectedSeat,
      this.props.selectedGame
    );

    if (seat.isArchived) {
      alert("This seat is recently archived... Please select another...");
    }

    if (seat.isSold || seat.noOfSeats - seat.seatsSold === 0) {
      alert("This seat has been sold... Please select another...");
      this.props.history.push(
        `/orders/create/?step=select-seat&game=76ada460-97ef-11e9-907b-130afab5b563&qty=2`
      );
    }

    this.setState({
      seat,
      isLoading: false,
      game,
    });
  }

  game(id) {
    return API.get("v2", `marketplace/events/by/eventId/${id}`);
  }

  seat(seat, game) {
    return API.get(
      "v2",
      `marketplace/listings/by/eventId/${game}?seatId=${seat}&include_seller_details=true`
    );
  }

  async submitForm(e) {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    if (!isEmpty(this.validateInput(this.state.buyer))) {
      return;
    }
    
    this.setState({
      isLoading: true,
    });

    const {
      paymentRefNo,
      notes,
      selectedGame,
      selectedSeat,
      qty,
      seat,
      game,
      buyer
    } = this.state;
    
    const section = seat.zone === "Upper Zone" ? seat.zone : "Section";

    const gameDate = `${moment
      .tz(game.date, game.timezone)
      .format("ddd, MMM D YYYY")} at ${moment
      .tz(game.date, game.timezone)
      .format("h:mm A")}`;

    const description = `${this.state.qty} ticket(s): ${game.longName} - ${gameDate}, ${section} ${seat.zoneNo} Row ${seat.row}`;

    try {
      await API.post("v2", "marketplace/orders/manual-checkout", {
        body: {
          name : buyer.name,
          email : buyer.email,
          mobileNo : buyer.mobileNo,
          paymentRefNo,
          notes,
          description,
          noOfSeats: qty,
          game: selectedGame,
          seat: selectedSeat,
          ticketPrice: seat.price,
          amount: qty * seat.price,
        },
      });

      this.setState({
        isLoading: false,
        succeeded: true,
      });

      alert("Order created!");
    } catch (e) {
      if (e.response) {
        const { data } = e.response;

        if (!data.isSeatAvailable) {
          alert(data.message);
        }
      } else {
        console.error(e);
        throw e;
      }
    }
  }

  validateInput(input) {
    const { name, email, mobileNo } = input;
    return {
      ...(!isNil(name) && validator.isEmpty(name) && { name: "Name is required" }),
      ...(!isNil(email) && validator.isEmpty(email) && { email: "Email is required" }),
      ...(!isNil(email) && !validator.isEmpty(email) && !validator.isEmail(email) && { email: "Please enter a valid email address" }),
      ...(!isNil(mobileNo) && validator.isEmpty(mobileNo) && { mobileNo: "Phone number is required" }),
      ...(!isNil(mobileNo) && !validator.isEmpty(mobileNo) && !validator.isMobilePhone(mobileNo, 'any', { strictMode: true }) && { mobileNo: "Please enter a valid phone number including country code" }),
    };
  }

  renderGameDetails() {
    const { classes } = this.props;
    const { longName, date, timezone } = this.state.game;

    return (
      <div className={classes.gameDetails}>
        <Typography variant="body2">{longName}</Typography>
        <Typography variant="body1">
          {moment.tz(date, timezone).format("ddd, MMM DD YYYY")} at{" "}
          {moment.tz(date, timezone).format("h:mm A")}
        </Typography>
      </div>
    );
  }

  renderSeatDetails() {
    const { classes } = this.props;

    if (!this.state.seat) {
      return (
        <div className={classes.seatDetails}>
          <Typography variant="body2">
            Loading seat... Please wait...
          </Typography>
        </div>
      );
    }

    const { zone, zoneNo, row, price, sellerFullName, sellerEmail } =
      this.state.seat;

    return (
      <div className={classes.seatDetails}>
        <Typography variant="body2">
          {zone} {zoneNo} Row {row}
        </Typography>
        <Typography variant="body1">
          {sellerFullName} - {sellerEmail}
        </Typography>

        <Typography
          variant="body2"
          style={{ marginTop: "1rem", color: "#4caf50" }}
        >
          ${price.toFixed(2)} / seat
        </Typography>
        <Typography variant="body2">
          ${(price.toFixed(2) * this.state.qty).toFixed(2)} Total
        </Typography>
      </div>
    );
  }

  renderForm() {
    const { buyer, isSubmitted } = this.state;
    const { classes } = this.props;
    const validated = this.validateInput(buyer);
    return (
      <form onSubmit={this.submitForm} className={classes.form}>
        <TextField
          disabled={this.state.isLoading}
          id="name"
          label="Name"
          className={classes.textField}
          value={this.state.buyer.name}
          onChange={e => this.setState({ buyer: { ...buyer, name: e.target.value } })}
          error={isSubmitted && has(validated, 'name')}
          helperText={isSubmitted && get(validated, 'name')}
          margin="normal"
          fullWidth={true}
          required
        />

        <TextField
          disabled={this.state.isLoading}
          id="email"
          label="Email"
          className={classes.textField}
          value={this.state.buyer.email}
          onChange={e => this.setState({ buyer: { ...buyer, email: e.target.value.toLowerCase() } })}
          error={isSubmitted && has(validated, 'email')}
          helperText={isSubmitted && get(validated, 'email')}
          margin="normal"
          fullWidth={true}
          required
        />

        <TextField
          disabled={this.state.isLoading}
          id="mobileNo"
          label="Mobile No."
          className={classes.textField}
          value={this.state.buyer.mobileNo}
          onChange={e => this.setState({ buyer: { ...buyer, mobileNo: e.target.value } })}
          error={isSubmitted && has(validated, 'mobileNo')}
          helperText={isSubmitted && get(validated, 'mobileNo')}
          margin="normal"
          fullWidth={true}
          required
        />

        <TextField
          disabled={this.state.isLoading}
          id="paymentRefNo"
          label="Payment Ref#"
          className={classes.textField}
          value={this.state.paymentRefNo}
          onChange={e => this.setState({ paymentRefNo: e.target.value.toLowerCase } )}
          margin="normal"
          fullWidth={true}
        />

        <TextField
          disabled={this.state.isLoading}
          id="notes"
          label="Notes"
          className={classes.textField}
          value={this.state.notes}
          multiline
          rowsMax="4"
          onChange={e => this.setState({ notes: e.target.value.toLowerCase } )}
          margin="normal"
          fullWidth={true}
        />

        <div className={classes.buttons}>
          <Button
            disabled={this.isLoading}
            className={classes.button}
            color="default"
            variant="contained"
            href={`/orders/create/?step=select-seat&game=${this.state.selectedGame}&seat=${this.state.selectedSeat}&qty=${this.state.qty}`}
          >
            Back to Seats
          </Button>
          <Button
            className={classes.button}
            color="secondary"
            variant="contained"
            disabled={
              !this.state.selectedSeat ||
              this.state.isLoading ||
              this.state.succeeded
            }
            type="submit"
            onClick={this.submitForm}
          >
            Submit Order
          </Button>
        </div>
      </form>
    );
  }

  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="display2" style={{ marginBottom: "2rem" }}>
            3. Finalize Order
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {this.state.isLoading ? (
            <Typography variant="body2">Loading...</Typography>
          ) : (
            <React.Fragment>
              {this.renderGameDetails()}
              {this.renderSeatDetails()}
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {this.renderForm()}
        </Grid>
      </Grid>
    );
  }
}

FinalizeOrder.propsType = {
  selectedGame: PropTypes.string.isRequired,
  selectedSeat: PropTypes.string.isRequired,
  qty: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default FinalizeOrder;
