const zones = [
    {
      label: "Lower Zone 1",
    },
    {
      label: "Lower Zone 2",
    },
    {
      label: "Lower Zone 3",
    },
    {
      label: "Lower Zone 4",
    },
    {
      label: "Lower Zone 5",
    },
    {
      label: "Lower Zone 6",
    },
    {
      label: "Lower Zone 7",
    },
    {
      label: "7B",
    },
    {
      label: "Lower Zone 8",
    },
    {
      label: "8B",
    },
    {
      label: "Lower Zone 9",
    },
    {
      label: "9B",
    },
    {
      label: "Lower Zone 10",
    },
    {
      label: "Lower Zone 11",
    },
    {
      label: "11B",
    },
    {
      label: "Lower Zone 12",
    },
    {
      label: "Lower Zone 13",
    },
    {
      label: "Lower Zone 14",
    },
    {
      label: "Lower Zone 15",
    },
    {
      label: "Lower Zone 16",
    },
    {
      label: "Lower Zone 17",
    },
    {
      label: "Lower Zone 18",
    },
    {
      label: "Lower Zone 19",
    },
    {
      label: "Lower Zone 20",
    },
    {
      label: "Lower Zone 21",
    },
    {
      label: "Party Zone",
    },
    {
      label: "VIP Zone",
    },
  ];
  
  export default Object.freeze({
    ZONES: zones,
  });
  