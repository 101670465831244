import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Divider, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { API } from "aws-amplify";
import CDialog from '../components/CDialog';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import validator from "validator";
import { chain, get, has, isBoolean, isEmpty, isNil, isNull } from 'lodash';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { parsePhoneNumberFromString } from "libphonenumber-js";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    bottom: 0,
    marginTop: "2rem"
  },
  container: {
    marginTop: theme.spacing.unit * 3,
  },
  riskyBadge: {
    height: 'auto',
    width: 'auto',
    top: '-14px',
    right: '-44px',
    padding: '5px'
  },
});

function MobileNoCustomInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format="(###) ### ####"
      thousandSeparator=""
      prefix=""
      mask="_"
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
    />
  );
}

class Seller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: undefined,
      inputEmail: "",
      email: "",
      isLoading: false,
      isUpdating: false,
      openResetDialog: false,
      openEditDialog: false,
      editedUser: {
        id: "",
        name: "",
        email: "",
        old_email: "",
        phone_number: ""
      },
      resetUser: {
        password: "",
        confirm_password: ""
      },
    };

    this.getUser = this.getUser.bind(this);
  }

  validateInput(input) {
    const { name, email, phone_number } = input;
    return {
      ...(validator.isEmpty(name) && { name: "Name is required" }),
      ...(validator.isEmpty(email) && { email: "Email is required" }),
      ...(!validator.isEmpty(email) && !validator.isEmail(email) && { email: "Please enter a valid email address" }),
      ...(!validator.isEmpty(phone_number) && !validator.isMobilePhone(phone_number, ["en-CA"]) && { phone_number: "Please enter a valid phone number" })
    };
  }

  validatePassword(input) {
    const { password, confirm_password } = input;
    return {
      ...(validator.isEmpty(password) && { password: "Password is required" }),
      ...(!validator.isEmpty(password) && password.length < 6 && { password: "Password must be at least 6 characters" }),
      ...(validator.isEmpty(confirm_password) && { confirm_password: "Confirm password is required" }),
      ...(!validator.isEmpty(confirm_password) && !validator.equals(password, confirm_password) && { confirm_password: "Passwords do not match" }),
    };
  }

  getUser = (e) => {
    e.preventDefault();
    const { inputEmail } = this.state;
    this.setState({
      isLoading: true,
      email: inputEmail
    });
    API.get("v2", `marketplace/users/by/email/${inputEmail}?includeApprovedSeats=true&includeRejectedSeats=true`)
      .then(response => {
        this.setState({
          user: response.user,
          isLoading: false
        });
      }).catch(error => {
        CDialog.error("Error", "Internal Server Error");
        this.setState({ isLoading: false });
      })
  }

  editUser = () => {
    const { editedUser, user } = this.state;
    const { currUser} = this.props;

    this.setState({ isSubmitted: true });
    if (!isEmpty(this.validateInput(editedUser))) {
      return;
    }
    this.setState({ isUpdating: true });

    let phoneNumber = "";
    if (!isEmpty(editedUser.phone_number)) {
      const parsedPhoneNumber = parsePhoneNumberFromString(editedUser.phone_number, "CA")
      phoneNumber = parsedPhoneNumber.number;
    }

    const newInput = { ...editedUser, phone_number: phoneNumber, username: editedUser.id };

    API.post("v2", "marketplace/users/update", {
      headers: { email: currUser.email },
      body: newInput
    })
      .then(response => {
        this.setState({
          isUpdating: false,
          isSubmitted: false,
          user: { ...user, ...response.user_db }
        });
        this.closeEditDialog();
        CDialog.success("Success", "User has been updated");
      }).catch(error => {
        this.setState({ isUpdating: false });
        if (error.response) {
          const { data } = error.response;
          CDialog.error("Error", data.message);
        } else {
          CDialog.error("Error", "Internal Server Error");
        }
      });
  }

  resetPassword = () => {
    const { resetUser, user } = this.state;
    const { currUser} = this.props;
    this.setState({ isSubmitted: true });

    if (!isEmpty(this.validatePassword(resetUser))) {
      return;
    }
    this.setState({ isUpdating: true });
    API.post("v2", "marketplace/users/set-password", {
      headers: { email: currUser.email },
      body: {
        username: user.id,
        email: user.email,
        password: resetUser.password
      }
    })
      .then(response => {
        this.setState({ isUpdating: false });
        this.closeResetDialog();
        CDialog.success("Success", "Password successfully changed");
      }).catch(error => {
        CDialog.error("Error", "Internal Server Error");
        this.setState({ isUpdating: false });
      });
  }

  openResetDialog() {
    this.setState({ openResetDialog: true });
  }

  closeResetDialog() {
    this.setState({
      selectedUser: null,
      resetUser: {
        password: "",
        confirm_password: ""
      },
      openResetDialog: false,
      isSubmitted: false,
    });
  }

  openEditDialog(user) {
    let phoneNumber = get(user, "phone_number", "");
    if (!isEmpty(phoneNumber)) {
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
      if (!isNil(parsedPhoneNumber)) {
        phoneNumber = parsedPhoneNumber.formatNational().replace(/[^\d]+/g, "");
      }
    }

    this.setState({
      editedUser: {
        id: user.id,
        name: get(user, "name", ""),
        email: user.email,
        old_email: user.email,
        phone_number: phoneNumber
      },
      openEditDialog: true
    });
  }

  closeEditDialog() {
    this.setState({
      editedUser: {
        id: "",
        name: "",
        email: "",
        old_email: "",
        phone_number: ""
      },
      openEditDialog: false,
      isSubmitted: false,
    });
  }

  handleDelete(user) {
    const name = this.findKey(user.Attributes, 'name');
    const email = this.findKey(user.Attributes, 'email');
    CDialog.confirm("Are you sure?", `Remove ${name} (${email}) from support group`)
      .then(confirm => {
        if (confirm) {
          this.removeGroup(user);
        }
      })
  }

  renderEditDialog() {
    const { openEditDialog, editedUser, isUpdating, isSubmitted } = this.state;
    const validated = this.validateInput(editedUser, true);
    return (
      <Dialog open={openEditDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Edit Seller</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={editedUser.name}
            onChange={e => this.setState({ editedUser: { ...editedUser, name: e.target.value } })}
            error={isSubmitted && has(validated, 'name')}
            helperText={isSubmitted && get(validated, 'name')}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            value={editedUser.email}
            onChange={e => this.setState({ editedUser: { ...editedUser, email: e.target.value.toLowerCase() } })}
            error={isSubmitted && has(validated, 'email')}
            helperText={isSubmitted && get(validated, 'email')}
            fullWidth
          />
          <TextField
            id="phone_number"
            name="phone_number"
            margin="dense"
            onChange={e => this.setState({ editedUser: { ...editedUser, phone_number: e.target.value } })}
            placeholder="Phone Number"
            value={editedUser.phone_number}
            InputProps={{
              startAdornment: (
                <span
                  style={{ fontSize: ".875rem", marginRight: ".5rem" }}
                >
                  +1
                </span>
              ),
              inputComponent: MobileNoCustomInput,
            }}
            error={isSubmitted && has(validated, 'phone_number')}
            helperText={isSubmitted && get(validated, 'phone_number')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.closeEditDialog()} color="primary" disabled={isUpdating}>
            Cancel
          </Button>
          <Button onClick={() => this.editUser()} color="primary" variant="contained" disabled={isUpdating}>
            {isUpdating ? 'Loading...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderResetDialog() {
    const { openResetDialog, isUpdating, resetUser, isSubmitted } = this.state;
    const validated = this.validatePassword(resetUser);
    return (
      <Dialog open={openResetDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Reset User Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            value={resetUser.password}
            onChange={e => this.setState({ resetUser: { ...resetUser, password: e.target.value } })}
            error={isSubmitted && has(validated, 'password')}
            helperText={isSubmitted && get(validated, 'password')}
            fullWidth
          />
          <TextField
            margin="dense"
            id="confirm-password"
            label="Confirm Password"
            type="password"
            value={resetUser.confirm_password}
            onChange={e => this.setState({ resetUser: { ...resetUser, confirm_password: e.target.value } })}
            error={isSubmitted && has(validated, 'confirm_password')}
            helperText={isSubmitted && get(validated, 'confirm_password')}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.closeResetDialog()} color="primary" disabled={isUpdating}>
            Cancel
          </Button>
          <Button onClick={() => this.resetPassword()} color="primary" variant="contained" disabled={isUpdating}>
            {isUpdating ? 'Loading...' : 'Reset'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderChangelogs(changeLogs) {
    return (
      <List>
        {chain(changeLogs)
          .orderBy("date", "desc")
          .map((changes, index) => (
            <div key={index}>
              <ListItem alignitems="flex-start" style={{ padding: '0' }}>
                <ListItemText
                  secondary={
                    Object.keys(changes).map((key) => (
                      <React.Fragment key={key}>
                        {key === "date"
                          ? (
                            <Typography variant="caption" color="textPrimary">
                              {moment.tz(Number(changes[key]), "America/Edmonton")
                                .format("MMM DD, YYYY hh:mm A")}
                            </Typography>
                          )
                          : (
                            <Typography variant="caption" gutterBottom>
                              {key}:{" "}{isBoolean(changes[key]) ? (changes[key] ? 'Yes' : 'No') : changes[key]}
                            </Typography>
                          )}
                      </React.Fragment>
                    ))
                  }
                />
              </ListItem>
              <Divider />
            </div>
          ))
          .value()}
      </List>
    )
  }

  render() {
    const { classes } = this.props;
    const { user, email, inputEmail, isLoading } = this.state;
    return (
      <React.Fragment>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="display2">Seller</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <form onSubmit={this.getUser}>
              <Grid container alignItems="flex-end">
                <Grid item xs={8}>
                  <TextField
                    id="email"
                    fullWidth={true}
                    name="email"
                    label="Seller Email"
                    onChange={e => this.setState({ inputEmail: e.target.value })}
                    value={inputEmail}
                    disabled={isLoading}
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <Button type="submit" color="primary" variant="contained" disabled={isLoading}>
                    {isLoading ? 'Searching' : 'Search'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        {isNull(user) && !isLoading && (
          <Typography variant="subheading" gutterBottom style={{ textAlign: 'center', marginTop: "1rem" }}>
            There is no seller with email "{email}"
          </Typography>
        )}
        {!isNil(user) && (
          <div className={classes.container}>
            <Grid
              container
              direction="row"
              spacing={24}
            >
              <Grid item xs={12} sm={8} md={6}>
                <Typography variant="title">
                  Seller Info
                </Typography>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          Name
                        </TableCell>
                        <TableCell>
                          : {user.name ? user.name : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Email
                        </TableCell>
                        <TableCell>
                          : {user.email}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Phone Number
                        </TableCell>
                        <TableCell>
                          : {user.phone_number ? user.phone_number : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Group
                        </TableCell>
                        <TableCell>
                          : {user.groups.length > 0 ? user.groups.join(" | ") : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Approved Listings
                        </TableCell>
                        <TableCell>
                          : {user.totalApprovedSeats}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Rejected Listings
                        </TableCell>
                        <TableCell>
                          : {user.totalRejectedSeats}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Created
                        </TableCell>
                        <TableCell>
                          : {moment.tz(Number(user.created), "America/Edmonton")
                            .format("MMM DD, YYYY hh:mm A")}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
                <div style={{ marginTop: '5px' }}>
                  <Button variant="contained" color="primary" style={{ marginRight: '3px' }} onClick={() => this.openEditDialog(user)}>
                    Edit
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => this.openResetDialog()}>
                    Reset Password
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="title">
                  Changelogs
                </Typography>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {user.changeLogs && user.changeLogs.length > 0 ? this.renderChangelogs(user.changeLogs) : "Empty"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
        {this.renderEditDialog()}
        {this.renderResetDialog()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Seller);