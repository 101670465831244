import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LIONS_SPECIAL_ROWS from "../constants/LionsSpecialSeatRows";

class LionsSpecialRowSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  getRows() {
    const { section } = this.props;
    const sectionNo = section.split(" ").pop();

    if (sectionNo === "1") {
      return LIONS_SPECIAL_ROWS.generateAlphabetRows(["A", "B", "C", "D", "E"]);
    } else if (sectionNo === "2") {
      return LIONS_SPECIAL_ROWS.generateAlphabetRows(["A", "B", "C", "D", "E","F"]);
    } else if (["3", "5"].includes(sectionNo)) {
      return LIONS_SPECIAL_ROWS.generateAlphabetRows(["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"]);
    } else if (["6", "7", "8", "9", "11", "12"].includes(sectionNo)) {
      return LIONS_SPECIAL_ROWS.generateAlphabetRows(["A", "B", "C", "D", "E", "F", "G", "H"]);
    } else if (["4", "10"].includes(sectionNo)) {
      return LIONS_SPECIAL_ROWS.generateAlphabetRows(["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"]);
    } else if (["13", "14", "15", "16", "17", "18", "19", "20", "21"].includes(sectionNo)) {
      return LIONS_SPECIAL_ROWS.DEFAULT_ROWS;
    } else if (["7B", "8B", "9B", "11B"].includes(sectionNo)) {
      return LIONS_SPECIAL_ROWS.GA_ROW;
    } else if (["Party Zone", "VIP Zone"].includes(section)) {
      return LIONS_SPECIAL_ROWS.GA_ROW;
    } else {
      return LIONS_SPECIAL_ROWS.DEFAULT_ROWS;
    }
  };

  handleSelection = (event) => {
    this.setState({
      value: event.target.value,
    });

    this.props.onRowSelection(event.target.value);
  };  

  render() {
    return (
      <FormControl>
        <InputLabel>Row</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: "row",
            id: "row",
          }}
          placeholder="Seat Row"
          fullWidth
        >
          {this.props.section && this.getRows().map((row, index) => (
            <MenuItem selected={row.value === this.props.value} value={row.value} key={index}>
              {row.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

LionsSpecialRowSelect.propsType = {
  classes: PropTypes.object.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  row: PropTypes.string.isRequired,
};

export default LionsSpecialRowSelect;
