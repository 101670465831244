import React, { Component } from "react";
import { API } from "aws-amplify";
import moment from "moment-timezone";

import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import TableLoading from "../components/Table/TableLoading";
import TableEmpty from "../components/Table/TableEmpty";
import { renderZoneName, renderRowName, getTeamFullName } from "../libs/shared/helpers";

const styles = {
  root: {
    flexGrow: 1,
  },
  request: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    bottom: 0,
  },
  loadMoreBtn: {
    margin: "2rem 0",
    marginRight: "1rem",
  },
};

class SellRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: [],
      filteredRequests: [],
      isApproving: false,
      email: "",
      isLoading: true,
      isLoadingSearch: false,
      lastKey: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.loadMoreItems = this.loadMoreItems.bind(this);
  }

  async componentWillMount() {
    const response = await this.requests();
    const requests = response.sell_requests;
    const lastKey = response.last_evaluated_key;

    this.setState({
      isLoading: false,
      requests,
      lastKey,
    });
  }

  requests(lastEvaluatedKey, limit = 50, email = null) {
    return API.get(
      "v2",
      `marketplace/sellRequests/by/recent?paginate=true${
        lastEvaluatedKey ? `&lastEvaluatedKey=${lastEvaluatedKey}` : ""
      }${limit ? `&limit=${limit}` : ""}${email ? `&email=${email}` : ""}
      `
    );
  }
  
  allRequests(email = null) {
    return API.get("v2", `marketplace/sellRequests/by/recent?paginate=false${email ? `&email=${email}` : ""}`);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value.toLowerCase() });
  }

  async onFilter(e) {
    e.preventDefault();

    const { email } = this.state;
    this.setState({
      isLoading: true,
      isLoadingSearch: true,
    });

    const response = await this.requests(null, 50, email);
    const requests = response.sell_requests;
    const lastKey = response.last_evaluated_key;
    
    this.setState({
      isLoading: false,
      isLoadingSearch: false,
      requests,
      lastKey,
    });
  }

  renderFilter() {
    const { email, isLoadingSearch } = this.state;

    return (
      <form onSubmit={this.onFilter}>
        <Grid container alignItems="flex-end">
          <Grid item xs={8}>
            <TextField
            id="email"
            fullWidth={true}
            name="email"
            label="Seller Email"
            onChange={this.handleChange}
            value={email}
          ></TextField>
          </Grid>
          <Grid item xs={4}>
            <Button type="submit" color="primary" variant="contained" disabled={isLoadingSearch}>
              {isLoadingSearch ? 'Searching' : 'Search' } 
            </Button>
          </Grid>
        </Grid>                
      </form>
    );
  }

  renderRequests() {
    const { requests, isLoading } = this.state;
    const { classes } = this.props;
    
    return (
      requests && (
        <Paper className={classes.requests}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Game Date</TableCell>
                <TableCell>Seller</TableCell>
                <TableCell>Seller Email</TableCell>
                <TableCell>Seller Mobile No.</TableCell>
                <TableCell>Seller IP Address</TableCell>
                <TableCell>Seat Details</TableCell>
                <TableCell>No. of Seats</TableCell>
                <TableCell>No. of Games</TableCell>
                <TableCell>Date of Request</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.map((request) => {
                const games = request.games;

                const pendingForApproval = games.filter(
                  (game) => !game.isApproved && !game.isRejected
                );

                const isReviewed = games.length !== pendingForApproval.length;
                const sectionName = renderZoneName(request.section, request.sectionNo, request.row, games[0].homeTeamSlug, games[0].isSpecial);
                const rowName = renderRowName(request.section, request.row, games[0].homeTeamSlug, games[0].isSpecial);
                const source = request.source;

                return (
                  <TableRow key={request.id}>
                    <TableCell>
                      <Button
                        disabled={this.state.isApproving}
                        href={`/sell-requests/${request.id}?sellerId=${request.sellerId}`}
                      >
                        {isReviewed ? (
                          <Chip
                            label="Reviewed"
                            style={{
                              backgroundColor: "#4caf50",
                              color: "white",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <Chip label="Needs Approval" color="secondary" style={{
                            cursor: "pointer",
                          }} />
                        )}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {request.games[0].homeTeamSlug ? 
                        getTeamFullName(request.games[0].homeTeamSlug)
                      : ""}
                    </TableCell>
                    <TableCell>
                      {moment
                        .tz(request.games[0].date, request.games[0].timezone)
                        .format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell>
                      {request.sellerFullName ? request.sellerFullName : "-"}
                    </TableCell>
                    <TableCell>{request.sellerEmail}</TableCell>
                    <TableCell>
                      {request.sellerMobileNo ? request.sellerMobileNo : "-"}
                    </TableCell>
                    <TableCell>
                      {request.sellerIpAddress ? request.sellerIpAddress : "-"}
                      {source && (
                        <>
                          {" "}
                          {source.city ? source.city.replace("-", "") : null}{" "}
                          {source.region ? source.region.replace("-", "") : null}{" "}
                          {source.country ? source.country.replace("-", "") : null}
                        </>
                      )}                      
                    </TableCell>
                    <TableCell>
                      {sectionName}{" "}
                      Row {rowName}
                    </TableCell>
                    <TableCell>
                      {request.noOfSeats ? request.noOfSeats : "-"}
                    </TableCell>
                    <TableCell>
                      {request.games && request.games.length}
                    </TableCell>
                    <TableCell>
                      {moment
                        .tz(Number(request.createdAt), "America/Edmonton")
                        .format("MMM DD, YYYY")}
                    </TableCell>
                    {/* <TableCell> hide this base on req https://trello.com/c/W6bl8ruz/199-tiny-change-when-there-is-time-on-the-sell-request-page-sell-req
                      <Button
                        disabled={this.state.isApproving}
                        href={`/sell-requests/${request.id}?sellerId=${request.sellerId}`}
                      >
                        View Details
                      </Button>
                    </TableCell> */}
                  </TableRow>
                );
              })}
              {!isLoading && requests.length === 0 && (
                <TableEmpty colSpan={10} />
              )}              
            </TableBody>
          </Table>
          {isLoading && <TableLoading />}
        </Paper>
      )
    );
  }

  async loadMoreItems(limit) {
    this.setState({
      isLoading: true,
    });

    const keys = JSON.stringify(this.state.lastKey);
    const response = await this.requests(keys, limit, this.state.email);
    const requests = response.sell_requests;
    const lastKey = response.last_evaluated_key;
    this.setState({
      isLoading: false,
      requests: [...this.state.requests, ...requests],
      lastKey,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root} id="SellRequests">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="display2" style={{ marginBottom: "3rem" }}>
              Sell Requests
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            {this.renderFilter()}
          </Grid>
          <Grid item xs={12}>
            {this.renderRequests()}

            {this.state.lastKey && (
              <div className={classes.buttons}>
                <Button
                  onClick={() => this.loadMoreItems(50)}
                  variant="extendedFab"
                  color="secondary"
                  size="large"
                  className={classes.loadMoreBtn}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? "Loading..." : "Load 50 More"}
                </Button>
                <Button
                  onClick={() => this.loadMoreItems(500)}
                  variant="extendedFab"
                  color="secondary"
                  size="large"
                  className={classes.loadMoreBtn}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? "Loading..." : "Load 500 More"}
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SellRequests);
