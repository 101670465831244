export const generateRows = (start, end) => {
  const arr = [];

  for (let i = start; i <= end; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
}

export const WCRow = [{
  value: 'WC',
  label: 'Row WC'
}];

export default {
  generateRows,
  WC_ROW: WCRow
};
