import { flatten } from "lodash";

const zoneGroup = [
  {
    label: "Lower Zone",
    numbers: [
      ...["100", "101", "101L", "102", "103", "104", "105", "106", "107", "107L", "107Box", "108", "109", "110", "111", "111L", "111Box", "112", "113", "114", "115", "116", "117", "117L"],
    ],
  },
  {
  label: "Upper Zone",
    numbers: [
      ...["301", "302", "303", "304", "305", "306", "307", "308", "309", "310", "311", "312", "313", "314", "315", "316", "317", "318", "319"],
    ],
  },
];

const otherZones = [
  {
    label: "South",
  },
  {
    label: "Fan Zone",
  },
  {
    label: "Mezzanine",
  },
  {
    label: "North",
  },
];

const zones = zoneGroup.map((zone) => {
  return zone.numbers.map((number) => ({ label: `${zone.label} ${number}` }));
});


export default Object.freeze({
  ZONES: flatten([...zones, ...otherZones]),
});