import React from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import TEAMS from '../../constants/Teams';
import { isEmpty } from 'lodash';

const SearchForm = ({
  isSent,
  isPaid,
  isRefunded,
  purchaseEndDate,
  purchaseStartDate,
  gameStartDate,
  gameEndDate,
  teamSelected,
  sellerEmail,
  buyerEmail,
  orderNumber,
  isLoading,
  handleChange,
  getOrders,
  classes
}) => {
  return (
    <form onSubmit={getOrders}>
      <TextField
        id="purchaseStartDate"
        label="Purchase Start Date"
        type="date"
        name="purchaseStartDate"
        value={purchaseStartDate}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isLoading || !isEmpty(orderNumber)}
        onChange={handleChange}
        style={{ marginRight: "1.5rem", marginBottom: "1rem" }}
      />
      <TextField
        id="purchaseEndDate"
        label="Purchase End Date"
        type="date"
        name="purchaseEndDate"
        value={purchaseEndDate}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isLoading || !isEmpty(orderNumber)}
        onChange={handleChange}
        style={{ marginRight: "1.5rem", marginBottom: "1rem" }}
      />
      <TextField
        id="gameStartDate"
        label="Game Start Date"
        type="date"
        name="gameStartDate"
        value={gameStartDate}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isLoading || !isEmpty(orderNumber)}
        onChange={handleChange}
        style={{ marginRight: "1.5rem", marginBottom: "1rem" }}
      />
      <TextField
        id="gameEndDate"
        label="Game End Date"
        type="date"
        name="gameEndDate"
        value={gameEndDate}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isLoading || !isEmpty(orderNumber)}
        onChange={handleChange}
        style={{ marginRight: "1.5rem", marginBottom: "1rem" }}
      />
      <TextField
        id="sellerEmail"
        label="Seller Email"
        type="text"
        name="sellerEmail"
        value={sellerEmail}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isLoading || !isEmpty(orderNumber)}
        onChange={handleChange}
        style={{ marginRight: "1.5rem", marginBottom: "1rem" }}
      />
      <TextField
        id="buyerEmail"
        label="Buyer Email"
        type="text"
        name="buyerEmail"
        value={buyerEmail}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isLoading || !isEmpty(orderNumber)}
        onChange={handleChange}
        style={{ marginRight: "1.5rem", marginBottom: "1rem" }}
      />
      <FormControl
        style={{
          minWidth: "75px",
          marginRight: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        <InputLabel htmlFor="team-selector">Team</InputLabel>
        <Select
          id="team-selector"
          value={teamSelected}
          label="team"
          name="teamSelected"
          onChange={handleChange}
          disabled={isLoading || !isEmpty(orderNumber)}
        >
          <MenuItem value={""}></MenuItem>
          {TEAMS.map(team => (
            <MenuItem key={team.homeTeamSlug} value={team.homeTeamSlug}>{team.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        style={{
          minWidth: "100px",
          marginRight: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        <InputLabel htmlFor="isSent-selector">Is Sent?</InputLabel>
        <Select
          id="isSent-selector"
          value={isSent}
          label="isSent"
          name="isSent"
          onChange={handleChange}
          disabled={isLoading || !isEmpty(orderNumber)}
        >
          <MenuItem value={""}></MenuItem>
          <MenuItem value={"true"}>True</MenuItem>
          <MenuItem value={"false"}>False</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        style={{
          minWidth: "100px",
          marginRight: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        <InputLabel htmlFor="isPaid-selector">Is Paid?</InputLabel>
        <Select
          id="isPaid"
          value={isPaid}
          label="isPaid"
          name="isPaid"
          onChange={handleChange}
          disabled={isLoading || !isEmpty(orderNumber)}
        >
          <MenuItem value={""}></MenuItem>
          <MenuItem value={"true"}>True</MenuItem>
          <MenuItem value={"false"}>False</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        style={{
          minWidth: "125px",
          marginRight: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        <InputLabel htmlFor="isRefunded-selector">Is Refunded?</InputLabel>
        <Select
          id="isRefunded"
          value={isRefunded}
          label="isRefunded"
          name="isRefunded"
          onChange={handleChange}
          disabled={isLoading || !isEmpty(orderNumber)}
        >
          <MenuItem value={""}></MenuItem>
          <MenuItem value={"true"}>True</MenuItem>
          <MenuItem value={"false"}>False</MenuItem>
        </Select>
      </FormControl>
      <TextField
        id="orderNumber"
        label="Order Number"
        type="text"
        name="orderNumber"
        value={orderNumber}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isLoading}
        onChange={handleChange}
        style={{ marginRight: "1.5rem", marginBottom: "1rem" }}
      />
      <Button
        variant="contained"
        color="secondary"
        type="submit"
        disabled={isLoading}
        style={{
          marginTop: "0.75rem",
          marginRight: "1.5rem",
          marginBottom: "1rem",
        }}
      >
        Get Orders
      </Button>
    </form>
  )
}

export default SearchForm;