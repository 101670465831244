import React, { Component } from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import _ from "lodash";
import moment from "moment-timezone";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import TeamSelector from "./TeamSelector";
import GameSelector from "./GameSelector";

class SelectGameStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTeam: null,
      selectedGame: null,
      games: [],
      isLoading: true,
    };

    this.onTeamSelection = this.onTeamSelection.bind(this);
    this.onGameSelection = this.onGameSelection.bind(this);
  }

  async componentWillMount() {
    let games = await this.games();
    const currentTime = new Date().getTime();
    const tbdGames = _.chain(games)
      .filter((game) => {
        return game.isTbd && !game.testGame && !game.isArchived;
      })
      .sortBy("playoffSequence", "asc")
      .value();
    console.log({ tbdGames });
    const regularGames = _.chain(games)
      .filter((game) => {
        return (
          moment.tz(game.date, game.timezone).add(120, "minutes").valueOf() >=
            moment.tz(currentTime, game.timezone).valueOf() &&
          !game.testGame &&
          !game.isTbd &&
          !game.isArchived
        );
      })
      .sortBy("date", "asc")
      .value();
    games = [...regularGames, ...tbdGames];
    console.log({ games });
    this.setState({
      games,
      isLoading: false,
    });
  }

  games() {
    return API.get("v2", `marketplace/events/all?includePrevious=true`);
  }

  onTeamSelection(selectedTeam) {
    this.setState({
      selectedTeam,
    });
  }

  onGameSelection(selectedGame) {
    this.setState({
      selectedGame,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="display2" style={{ marginBottom: "2rem" }}>
            1. Select Game
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TeamSelector
            onTeamSelection={this.onTeamSelection}
            isLoading={this.state.isLoading}
          />
          <GameSelector
            selectedTeam={this.state.selectedTeam}
            onGameSelection={this.onGameSelection}
            games={this.state.games}
            isLoading={this.state.isLoading}
          />

          <div className={classes.buttons}>
            <Button
              color="secondary"
              variant="contained"
              disabled={!this.state.selectedGame || this.state.selectedGame === "1"}
              href={`/orders/create/?step=select-seat&game=${this.state.selectedGame}`}
            >
              Proceed to Seat Selection
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

SelectGameStep.propsType = {
  selectedGame: PropTypes.string,
  classes: PropTypes.func.isRequired,
};

export default SelectGameStep;
