import React, { Component } from "react";
import { API } from "aws-amplify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { InputAdornment } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
} from "@material-ui/core";

const styles = {
  root: {
    flexGrow: 1,
  },
  FormControl: {
    marginTop: "1rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
};

class CreateCoupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      name: null,
      isSubmitting: false,
      value: null,
      isActive: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentWillMount() {}

  handleChange(e) {
    if (e.target.name === "isActive") {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({
        [e.currentTarget.name]: e.target.value,
      });
    }
  }

  async submit() {
    const { currUser} = this.props;
    this.setState({ isSubmitting: true });

    try {
      const { email, name, isActive, value } = this.state;
      await API.post("v2", "marketplace/coupons/create", {
        headers: { email: currUser.email },
        body: { email, name, isActive, value },
      });
      this.setState({ isSubmitting: false });
      alert("Coupon Created");
      this.props.history.push("/coupons");
    } catch (e) {
      this.setState({ isSubmitting: false });
      alert(e);
    }
  }

  render() {
    const { classes } = this.props;
    const { email, value, name, isActive } = this.state;

    return (
      <div className={classes.root} id="CreateCoupon">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="display2">Create Coupon</Typography>

            <form className={classes.form}>
              <FormControl className={classes.FormControl}>
                <TextField
                  name="email"
                  value={email}
                  type="email"
                  label="Recipient Email"
                  placeholder="Recipient Email"
                  onChange={this.handleChange}
                />
              </FormControl>
              <FormControl className={classes.FormControl}>
                <TextField
                  name="name"
                  value={name}
                  type="string"
                  label="Recipient Name"
                  placeholder="Recipient Name"
                  onChange={this.handleChange}
                />
              </FormControl>
              <FormControl className={classes.FormControl}>
                <TextField
                  name="value"
                  value={value}
                  type="currency"
                  label="Value"
                  min="1"
                  max="9999999"
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormControlLabel
                style={{ height: "40px", marginTop: "10px" }}
                control={
                  <Checkbox
                    textAlign="center"
                    onChange={this.handleChange}
                    defaultChecked={isActive}
                    color="rgb(33, 150, 243)"
                    height="40px"
                  ></Checkbox>
                }
                label={
                  <Typography
                    style={{
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      margin: "0px",
                      fontSize: "1rem",
                    }}
                    className={classes.FormControl}
                  >
                    Is Active
                  </Typography>
                }
              />
              <FormControl className={classes.FormControl}>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={this.state.isSubmitting}
                  onClick={this.submit}
                >
                  Send Coupon
                </Button>
              </FormControl>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(CreateCoupon);
