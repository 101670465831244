const zones = [
  {
    label: "Upper Zone AA2",
  },
  {
    label: "Upper Zone BB2",
  },
  {
    label: "Upper Zone CC2",
  },
  {
    label: "Upper Zone A2",
  },
  {
    label: "Upper Zone B2",
  },
  {
    label: "Upper Zone C2",
  },
  {
    label: "Upper Zone D2",
  },
  {
    label: "Upper Zone E2",
  },
  {
    label: "Upper Zone F2",
  },
  {
    label: "Upper Zone G2",
  },
  {
    label: "Upper Zone H2",
  },
  {
    label: "Upper Zone I2",
  },
  {
    label: "Upper Zone J2",
  },
  {
    label: "Upper Zone K2",
  },
  {
    label: "Upper Zone L2",
  },
  {
    label: "Upper Zone M2",
  },
  {
    label: "Upper Zone DD2",
  },
  {
    label: "Upper Zone EE2",
  },
  {
    label: "Upper Zone FF2",
  },
  {
    label: "Lower Zone AA",
  },
  {
    label: "Lower Zone BB",
  },
  {
    label: "Lower Zone CC",
  },
  {
    label: "Lower Zone A",
  },
  {
    label: "Lower Zone B",
  },
  {
    label: "Lower Zone C",
  },
  {
    label: "Lower Zone D",
  },
  {
    label: "Lower Zone E",
  },
  {
    label: "Lower Zone F",
  },
  {
    label: "Lower Zone G",
  },
  {
    label: "Lower Zone H",
  },
  {
    label: "Lower Zone I",
  },
  {
    label: "Lower Zone J",
  },
  {
    label: "Lower Zone K",
  },
  {
    label: "Lower Zone L",
  },
  {
    label: "Lower Zone M",
  },
  {
    label: "Lower Zone DD",
  },
  {
    label: "Lower Zone EE",
  },
  {
    label: "Lower Zone FF",
  },
  {
    label: "Lower Zone PDFF",
  },
  {
    label: "Lower Zone GG",
  },
  {
    label: "Lower Zone HH",
  },
  {
    label: "Lower Zone JJ",
  },
  {
    label: "Lower Zone KK",
  },
  {
    label: "Lower Zone LL",
  },
  {
    label: "Lower Zone MM",
  },
  {
    label: "Lower Zone NN",
  },
  {
    label: "Lower Zone PDNN",
  },
  {
    label: "Lower Zone OO",
  },
  {
    label: "Lower Zone N",
  },
  {
    label: "Lower Zone O",
  },
  {
    label: "Lower Zone P",
  },
  {
    label: "Lower Zone Q",
  },
  {
    label: "Lower Zone R",
  },
  {
    label: "Lower Zone S",
  },
  {
    label: "Lower Zone T",
  },
  {
    label: "Lower Zone U",
  },
  {
    label: "Lower Zone V",
  },
  {
    label: "Lower Zone W",
  },
  {
    label: "Lower Zone X",
  },
  {
    label: "Lower Zone Y",
  },
  {
    label: "Lower Zone Z",
  },
  {
    label: "Lower Zone PP",
  },
  {
    label: "Lower Zone QQ",
  },
  {
    label: "Lower Zone RR",
  },
  {
    label: "Upper Zone RR2",
  },
  {
    label: "Upper Zone QQ2",
  },
  {
    label: "Upper Zone PP2",
  },
  {
    label: "Upper Zone Z2",
  },
  {
    label: "Upper Zone Y2",
  },
  {
    label: "Upper Zone X2",
  },
  {
    label: "Upper Zone W2",
  },
  {
    label: "Upper Zone V2",
  },
  {
    label: "Upper Zone U2",
  },
  {
    label: "Upper Zone T2",
  },
  {
    label: "Upper Zone S2",
  },
  {
    label: "Upper Zone R2",
  },
  {
    label: "Upper Zone Q2",
  },
  {
    label: "Upper Zone P2",
  },
  {
    label: "Upper Zone O2",
  },
  {
    label: "Upper Zone N2",
  },
  {
    label: "Upper Zone OO2",
  },
  {
    label: "Upper Zone NN2",
  },
  {
    label: "Upper Zone MM2",
  },
  {
    label: "Twisted Tea Party Deck",
  },
  {
    label: "Coors Light Party Deck",
  },
  {
    label: "Rooftop Patio",
  },
  {
    label: "ENDZONE",
  }
];

export default Object.freeze({
  ZONES: zones,
});
