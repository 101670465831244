import React, { Component } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from "prop-types";

class TableEmpty extends Component {
  render() {
    return (
      <TableRow>
        <TableCell colSpan={this.props.colSpan} style={{ textAlign: 'center' }}>
          There is no data
        </TableCell>
      </TableRow>
    );
  }
}

TableEmpty.propTypes = {
  colSpan: PropTypes.number
}

TableEmpty.defaultProps = {
  colSpan: 1
}

export default TableEmpty;