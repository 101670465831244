const zones = [
    {
      label: "Lower Zone 201",
    },
    {
      label: "Lower Zone 202",
    },
    {
      label: "Lower Zone 203",
    },
    {
      label: "Lower Zone 204",
    },
    {
      label: "Lower Zone 206",
    },
    {
      label: "Lower Zone 207",
    },
    {
      label: "Lower Zone 209",
    },
    {
      label: "Lower Zone 210",
    },
    {
      label: "Lower Zone 211",
    },
    {
      label: "Lower Zone 212",
    },
    {
      label: "Lower Zone 213",
    },
    {
      label: "Lower Zone 214",
    },
    {
      label: "Lower Zone 215",
    },
    {
      label: "Lower Zone 216",
    },
    {
      label: "Lower Zone 217",
    },
    {
      label: "Lower Zone 218",
    },
    {
      label: "Lower Zone 219",
    },
    {
      label: "Lower Zone 221",
    },
    {
      label: "Lower Zone 222",
    },
    {
      label: "Lower Zone 224",
    },
    {
      label: "Lower Zone 225",
    },
    {
      label: "Lower Zone 226",
    },
    {
      label: "Lower Zone 227",
    },
    {
      label: "Lower Zone 228",
    },
    {
      label: "Lower Zone 229",
    },
    {
      label: "Lower Zone 230",
    },
    {
      label: "Lower Zone 231",
    },
    {
      label: "Lower Zone 233",
    },
    {
      label: "Lower Zone 234",
    },
    {
      label: "Lower Zone 236",
    },
    {
      label: "Lower Zone 237",
    },
    {
      label: "Lower Zone 238",
    },
    {
      label: "Lower Zone 239",
    },
    {
      label: "Lower Zone 240",
    },
    {
      label: "Lower Zone 241",
    },
    {
      label: "Lower Zone 242",
    },
    {
      label: "Lower Zone 243",
    },
    {
      label: "Lower Zone 244",
    },
    {
      label: "Lower Zone 245",
    },
    {
      label: "Lower Zone 246",
    },
    {
      label: "Lower Zone 248",
    },
    {
      label: "Lower Zone 249",
    },
    {
      label: "Lower Zone 251",
    },
    {
      label: "Lower Zone 252",
    },
    {
      label: "Lower Zone 253",
    },
    {
      label: "Lower Zone 254",
    },
    {
      label: "Club Seats 336",
    },
    {
      label: "Club Seats 337",
    },
    {
      label: "Club Seats 338",
    },
    {
      label: "Club Seats 339",
    },
    {
      label: "Club Seats 340",
    },
    {
      label: "Club Seats 341",
    },
    {
      label: "Club Seats 342",
    },
    {
      label: "Club Seats 343",
    },
    {
      label: "Club Seats 344",
    },
    {
      label: "Club Seats 345",
    },
    {
      label: "Club Seats 346",
    },
    {
      label: "General Admission",
    },
  ];
  
  export default Object.freeze({
    ZONES: zones,
  });
  