import React, { Component } from 'react';
import { Divider, LinearProgress, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Switch, Typography } from '@material-ui/core';
import { API } from "aws-amplify";
import CDialog from '../CDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CreditCard } from '@material-ui/icons';

class ThreeDSStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "on",
      isLoading: false,
      isUpdating: false,
      isError: false,
    };
  }

  componentDidMount() {
    this.getStatus();
  }

  getStatus = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await API.get("v2", `marketplace/threeds-status/get`);
      this.setState({
        status: response.status,
        isLoading: false
      });
    } catch (error) {
      this.setState({
        isError: true,
        isLoading: false
      });
    }
  };

  setStatus = async (status) => {
    this.setState({ isUpdating: true });
    try {
      const response = await API.post("v2", `marketplace/threeds-status/set`, {
        headers: { email: this.props.currUser.email },
        body: { status },
      });
      this.setState({
        status: response.status,
        isUpdating: false,
      });
    } catch (error) {
      this.setState({ isUpdating: false });
      CDialog.error("Error", "Failed to save the setting");
    }
  };

  handleToggle = (e) => {
    const status = e.target.checked ? "on" : "off";
    this.setStatus(status);
  }

  render() {
    const { isLoading, isError, isUpdating } = this.state;
    return (
      <React.Fragment>
        <ListItem>
          <ListItemIcon>
            <CreditCard />
          </ListItemIcon>
          <ListItemText primary="3DS Status" />
          <ListItemSecondaryAction>
            {isLoading && !isError && (
              <CircularProgress size={20} />
            )}
            {isError && (
              <Typography component="span" color="secondary">
                Failed to get the status
              </Typography>
            )}
            {!isLoading && !isError && (
              <Switch
                onChange={this.handleToggle}
                checked={this.state.status === "on"}
                disabled={isUpdating}
              />
            )}
          </ListItemSecondaryAction>
        </ListItem>
        {isUpdating ? <LinearProgress /> : <Divider />}
      </React.Fragment>
    );
  }
}

export default ThreeDSStatus;
