import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import { renderSeatNumbers, renderZoneName, renderRowName } from "../../libs/shared/helpers";
import { chain, get, isBoolean } from 'lodash';

const Loading = () => (
  <Typography variant="caption" color="textPrimary" style={{ marginBottom: ".5rem" }}>
    Loading...
  </Typography>
)

const SeatInfo = ({ showDialog, isLoadingUser, onClose, order, seller }) => {
  const getCreatedDate = () => {
    if (seller && seller.created) {
      return moment.tz(seller.created, "America/Edmonton").format("MMM DD, YYYY hh:mm A");
    }
    return "-"
  }

  if (order) {
    const { seat } = order;
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={showDialog}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Seat Information</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justify="space-between" spacing={16}>
            <Grid item>
              <Typography variant="body2">Seller</Typography>
              <Typography variant="caption">Seller Name</Typography>
              <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                {isLoadingUser ? <Loading /> : get(seller, "name", "-")}
              </Typography>
              <Typography variant="caption">Total Sales</Typography>
              <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                {isLoadingUser ? <Loading /> : get(seller, "totalSellerOrders", "-")}
              </Typography>
              <Typography variant="caption">Seller Email</Typography>
              <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                {isLoadingUser ? <Loading /> : get(seller, "email", "-")}
              </Typography>
              <Typography variant="caption">Seller Mobile No</Typography>
              <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                {isLoadingUser ? <Loading /> : get(seller, "phone_number", "-")}
              </Typography>
              <Typography variant="caption">Created</Typography>
              <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                {isLoadingUser ? <Loading /> : getCreatedDate()}
              </Typography>
              <Typography variant="body2">Changelogs</Typography>
              {isLoadingUser ? <Loading /> : (
                <List>
                  {seller && seller.changeLogs && seller.changeLogs.length > 0 ? chain(seller.changeLogs)
                    .orderBy("date", "desc")
                    .map((changes, index) => (
                      <div key={index}>
                        <ListItem alignitems="flex-start" style={{ padding: '0' }}>
                          <ListItemText
                            secondary={
                              Object.keys(changes).map((key) => (
                                <React.Fragment key={key}>
                                  {key === "date"
                                    ? (
                                      <Typography variant="caption" color="textPrimary">
                                        {moment.tz(Number(changes[key]), "America/Edmonton")
                                          .format("MMM DD, YYYY hh:mm A")}
                                      </Typography>
                                    )
                                    : (
                                      <Typography variant="caption" gutterBottom>
                                        {key}:{" "}{isBoolean(changes[key]) ? (changes[key] ? 'Yes' : 'No') : changes[key]}
                                      </Typography>
                                    )}
                                </React.Fragment>
                              ))
                            }
                          />
                        </ListItem>
                        <Divider style={{ marginBottom: ".5rem" }} />
                      </div>
                    ))
                    .value() : (
                    <Typography variant="caption" gutterBottom>
                      Empty
                    </Typography>
                  )}
                </List>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body2">Seat</Typography>
              <Typography variant="caption">Section</Typography>
              <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                {renderZoneName(seat.zone, seat.zoneNo, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}
              </Typography>
              <Typography variant="caption">Row</Typography>
              <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                {renderRowName(seat.zone, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)} {seat.isInstantDelivery ? "Insta" : ""}
              </Typography>
              <Typography variant="caption">Seat Numbers</Typography>
              <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                {renderSeatNumbers(seat, "-")}
              </Typography>
              <Typography variant="caption">Price</Typography>
              <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                CAD$ {seat.price.toFixed(2)} ea
              </Typography>
              <Typography variant="caption">Available Seats</Typography>
              <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                {seat.noOfSeats - seat.seatsSold} / {seat.noOfSeats}
              </Typography>
              {seat.isAisleSeat ? (
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  *Aisle Seats
                </Typography>
              ) : null}
              {seat.isWheelchair ? (
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  *Wheelchair
                </Typography>
              ) : null}
              <Typography variant="body2">Changelogs</Typography>
              <List>
                {seat.changeLogs && seat.changeLogs.length > 0 ?
                  chain(seat.changeLogs)
                    .orderBy("date", "desc")
                    .map(({ date, changes, user }) => (
                      <div key={date}>
                        <ListItem alignitems="flex-start" style={{ padding: '0' }}>
                          <ListItemText
                            secondary={
                              <React.Fragment>
                                <Typography variant="caption" color="textPrimary">
                                  {moment.tz(date, "America/Edmonton")
                                    .format("MMM DD, YYYY hh:mm A")}{" "}
                                  &mdash; {user && user}
                                </Typography>
                                {Object.keys(changes).map((key) => (
                                  <Typography key={key} variant="caption" gutterBottom>
                                    {key}:{" "}{isBoolean(changes[key]) ? (changes[key] ? 'Yes' : 'No') : changes[key]}
                                  </Typography>
                                ))}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        <Divider style={{ marginBottom: ".5rem" }} />
                      </div>
                    ))
                    .value() : (
                    <Typography variant="caption" gutterBottom>
                      Empty
                    </Typography>
                  )}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return null;
}

export default SeatInfo;
