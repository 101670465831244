import React, { Component } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({});

class GameSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTeam: props.selectedTeam,
      selectedGame: props.selectedGame ? props.selectedGame : "1",
      games: props.games,
      filteredGames: null,
      isLoading: true,
    };

    this.handleGameChange = this.handleGameChange.bind(this);
  }

  componentWillReceiveProps({ games, selectedTeam, isLoading }) {
    let currentTime = new Date().getTime();
    if (this.state.selectedTeam !== selectedTeam) {
      const filteredGames = this.state.games.filter(
        (game) =>
          game.homeTeamSlug === selectedTeam &&
          (moment.tz(game.date, game.timezone).add(120, "minutes").valueOf() >=
            moment.tz(currentTime, game.timezone).valueOf() ||
            game.isTbd)
      );

      if (!filteredGames[0]) {
        alert("No games available");
      }

      const selectedGame = filteredGames.length > 0 ? filteredGames[0].id : "1";
      this.props.onGameSelection(selectedGame);

      this.setState({
        selectedTeam: selectedTeam,
        selectedGame,
        filteredGames,
      });
    }

    if (this.state.games !== games) {
      this.setState({
        games: games,
      });
    }

    if (this.state.isLoading !== isLoading) {
      this.setState({
        isLoading,
      });
    }
  }

  handleGameChange(e) {
    const selectedGame = e.target.value;

    this.props.onGameSelection(selectedGame);
    this.setState({
      selectedGame,
    });
  }

  renderMenuItems() {
    const { filteredGames, selectedGame, selectedTeam } = this.state;

    if (!selectedTeam) {
      return (
        <MenuItem value="1" selected={true}>
          Please select a team first...
        </MenuItem>
      );
    }

    return filteredGames.map((game) => {
      const { id, date, isTbd } = game;

      const regularGameDate = `${moment
        .tz(date, game.timezone)
        .format("MMM DD h:mm A")}`;
      const gameDate = isTbd ? "TBD" : regularGameDate;

      return (
        <MenuItem value={id} key={id} selected={id === selectedGame}>
          {game.shortName} - {gameDate}
        </MenuItem>
      );
    });
  }

  render() {
    const { selectedGame, selectedTeam } = this.state;

    return (
      <Select
        value={selectedGame}
        autoWidth
        onChange={this.handleGameChange}
        name="selectedGame"
        disabled={!selectedTeam}
      >
        <MenuItem value="1" disabled>
          Select Game
        </MenuItem>
        {this.renderMenuItems()}
      </Select>
    );
  }
}

GameSelector.propsType = {
  selectedTeam: PropTypes.string.isRequired,
  selectedGame: PropTypes.string.isRequired,
};

export default withStyles(styles)(GameSelector);
