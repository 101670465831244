const zones = [
    {
        label: "Lower Zone 104",
    },
    {
        label: "Side Seats",
    },
    {
        label: "Upper Zone 321A",
    },
    {
        label: "Upper Zone 325A",
    },
    {
        label: "Upper Zone 227",
    },
    {
        label: "Upper Zone 226",
    },
    {
        label: "Upper Zone 225",
    },
    {
        label: "Upper Zone 224",
    },
    {
        label: "Upper Zone 223",
    },
    {
        label: "Upper Zone 222",
    },
    {
        label: "Upper Zone 221",
    },
    {
        label: "Upper Zone 220",
    },
    {
        label: "Upper Zone 219",
    },
    {
        label: "Upper Zone 218A",
    },
    {
        label: "Upper Zone 217A",
    },
    {
        label: "Upper Zone 216A",
    },
    {
        label: "Upper Zone 215A",
    },
    {
        label: "Upper Zone 214A",
    },
    {
        label: "Upper Zone 213A",
    },
    {
        label: "Upper Zone 212A",
    },
    {
        label: "Upper Zone 211",
    },
    {
        label: "Upper Zone 210",
    },
    {
        label: "Upper Zone 209",
    },
    {
        label: "Upper Zone 208",
    },
    {
        label: "Upper Zone 207",
    },
    {
        label: "Upper Zone 206",
    },
    {
        label: "Upper Zone 205",
    },
    {
        label: "Upper Zone 204",
    },
    {
        label: "Upper Zone 203",
    },
    {
        label: "Lower Zone 105A",
    },
    {
        label: "Lower Zone 105",
    },
    {
        label: "Lower Zone 106A",
    },
    {
        label: "Lower Zone 106",
    },
    {
        label: "Lower Zone 107",
    },
    {
        label: "Lower Zone 108",
    },
    {
        label: "Lower Zone 109A",
    },
    {
        label: "Lower Zone 109",
    },
    {
        label: "Lower Zone 110A",
    },
    {
        label: "Lower Zone 110",
    },
    {
        label: "Lower Zone 111",
    },
    {
        label: "Lower Zone 112",
    },
    {
        label: "Lower Zone 113",
    },
    {
        label: "Lower Zone 114",
    },
    {
        label: "Lower Zone 115",
    },
    {
        label: "Lower Zone 116",
    },
    {
        label: "Lower Zone 117",
    },
    {
        label: "Lower Zone 118",
    },
    {
        label: "Lower Zone 119",
    },
    {
        label: "Lower Zone 120",
    },
    {
        label: "Lower Zone 121",
    },
    {
        label: "Lower Zone 122",
    },
    {
        label: "Lower Zone 123",
    },
    {
        label: "Lower Zone 124",
    },
    {
        label: "Lower Zone 125",
    },
    {
        label: "Lower Zone 126",
    },
    {
        label: "Lower Zone 127",
    },
    {
        label: "Lower Zone 101",
    },
    {
        label: "Lower Zone 102",
    },
    {
        label: "Lower Zone 103",
    },
    {
        label: "Lower Zone 128",
    },
    {
        label: "Lower Zone 129",
    },
    {
        label: "Lower Zone 130",
    },
];
 
export default Object.freeze({
    ZONES: zones,
  });
  