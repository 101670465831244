import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MOOSE_ROWS from "../constants/MooseSeatRows";

class MooseRowSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  getRows() {
    const { section } = this.props;

    if (section.includes("PREMIUM SUITE LOUNGE")) {
      return MOOSE_ROWS.PREMIUM_ROWS;
    } else if (section.includes("Loge")) {
      return MOOSE_ROWS.LOGE_ROWS;
    } else {
      return MOOSE_ROWS.NON_LOGE_ROWS;
    }
  };

  handleSelection = (event) => {
    this.setState({
      value: event.target.value,
    });

    this.props.onRowSelection(event.target.value);
  };  

  render() {
    return (
      <FormControl>
        <InputLabel>Row</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: "row",
            id: "row",
          }}
          placeholder="Seat Row"
          fullWidth
        >
          {this.props.section && this.getRows().map((row, index) => (
            <MenuItem selected={row.value === this.props.value} value={row.value} key={index}>
              {row.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

MooseRowSelect.propsType = {
  classes: PropTypes.object.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  row: PropTypes.string.isRequired,
};

export default MooseRowSelect;
