import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, List } from "@material-ui/core";
import ThreeDSStatus from "../components/Setting/ThreeDSStatus";

const styles = {
  root: {
    flexGrow: 1,
  },
};

class Setting extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root} id="Seats">
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{ marginTop: "1.5rem", marginBottom: "2rem" }}
              variant="display2"
            >
              Setting
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <List className={classes.root}>
              <ThreeDSStatus currUser={this.props.currUser} />
            </List>
          </Grid>
        </Grid>        
      </div>
    );
  }
}

export default withStyles(styles)(Setting);
