import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TEAMS from "../constants/Teams";

const styles = (theme) => ({
  teamsList: {
    marginBottom: '1.5rem',
  },
  teamItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  teamLogoWrapper: {
    marginBottom: '1.5rem',
    cursor: 'pointer',
  },
  teamLogo: {
    height: 50,
    width: 50,
  },
});

class TeamSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTeam: props.selectedTeam,
      isLoading: props.isLoading,
    };
  }

  componentWillReceiveProps({ isLoading }) {
    if (this.state.isLoading !== isLoading) {
      this.setState({
        isLoading: isLoading,
      });
    }
  }

  onTeamSelection(slug) {
    this.setState({
      selectedTeam: slug,
    });

    this.props.onTeamSelection(slug);
  }

  renderLogo(selectedTeam, slug, name, img) {
    const { classes } = this.props;

    return (
      <Grid
        item
        xs={6}
        md={2}
        className={classes.teamItemWrapper}
        onClick={this.state.isLoading ? () => { } : () => this.onTeamSelection(slug)}
        style={
          (selectedTeam && selectedTeam !== slug) || this.state.isLoading
            ? { opacity: 0.5, transition: '.25s all ease-in-out' }
            : { opacity: 1, transition: '.25s all ease-in-out' }
        }
      >
        <div className={classes.teamLogoWrapper}>
          <img className={classes.teamLogo} src={img} alt={name} />
        </div>
        <Typography
          variant='body2'
          style={{
            cursor: 'pointer',
          }}
        >
          {name}
        </Typography>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    const { selectedTeam } = this.state;

    return (
      <React.Fragment>
        <Typography
          variant='body2'
          color='textPrimary'
          style={{
            fontWeight: 'bold',
            marginBottom: '1rem',
          }}
        >
          {this.state.isLoading ? 'Loading...' : 'Select Team'}
        </Typography>
        <Grid container spacing={32} className={classes.teamsList}>
          {TEAMS.map(team => (
            <React.Fragment key={team.homeTeamSlug}>
              {this.renderLogo(
                selectedTeam,
                team.homeTeamSlug,
                team.fullName,
                team.logo,
              )}
            </React.Fragment>
          ))}
        </Grid>
      </React.Fragment>
    );
  }
}

TeamSelector.propsType = {
  onTeamSelection: PropTypes.func.isRequired,
};

export default withStyles(styles)(TeamSelector);
