const prod = {
  apiGateway: {
    REGION: "ca-central-1",
    URL: "https://api.fansfirst.ca",
  },
};

let config = {};

switch (process.env.REACT_APP_STAGE) {
  case "production": {
    config = prod;
    break;
  }

  default: {
    config = prod;
  }
}

export default {
  cognito: {
    REGION: "ca-central-1",
    USER_POOL_ID: "ca-central-1_jN0jxy8wW",
    APP_CLIENT_ID: "2qpri0afilhunbsuvdo6faa9c2",
    IDENTITY_POOL_ID: "ca-central-1:30cf9e65-33b3-4a6d-9e92-eb4343138bbc",
  },
  ...config,
};
