const zones = [
    {
      label: "Lower Zone 106"
    },
    {
      label: "Lower Zone 107"
    },
    {
      label: "Lower Zone 108"
    },
    {
      label: "Lower Zone 109"
    },
    {
      label: "Lower Zone 110"
    },
    {
      label: "Lower Zone 111"
    },
    {
      label: "Lower Zone 112"
    },
    {
      label: "Lower Zone 113"
    },
    {
      label: "Lower Zone 114"
    },
    {
      label: "Lower Zone 115"
    },
    {
      label: "Lower Zone 116"
    },
    {
      label: "Lower Zone 117"
    },
    {
      label: "Lower Zone 118"
    },
    {
      label: "Lower Zone 119"
    },
    {
      label: "Lower Zone 120"
    },
    {
      label: "Lower Zone 121"
    },
    {
      label: "Lower Zone 122"
    },
    {
      label: "Lower Zone 123"
    },
    {
      label: "Lower Zone 124"
    },
    {
      label: "Lower Zone 125"
    },
    {
      label: "Lower Zone 126"
    },
    {
      label: "Lower Zone 127"
    },
    {
      label: "Lower Zone 128"
    },
    {
      label: "Lower Zone 129"
    },
    {
      label: "Lower Zone 130"
    },
    {
      label: "Lower Zone 131"
    },
    {
      label: "Lower Zone 132"
    },
    {
      label: "Lower Zone 133"
    },
    {
      label: "Lower Zone 134"
    },
    {
      label: "Lower Zone 135"
    },
    {
      label: "Lower Zone 136"
    },
    {
      label: "Lower Zone 137"
    },
    {
      label: "Lower Zone 138"
    },
    {
      label: "Lower Zone 139"
    },
    {
      label: "Lower Zone 140"
    },
    {
      label: "Lower Zone 141"
    },
    {
      label: "Lower Zone 142"
    },
    {
      label: "Lower Zone 143"
    },
    {
      label: "Lower Zone 144"
    },
    {
      label: "Lower Zone 145"
    },
    {
      label: "Lower Zone 146"
    },
    {
      label: "Lower Zone 147"
    },
    {
      label: "Middle Zone 224"
    },
    {
      label: "Middle Zone 225"
    },
    {
      label: "Middle Zone 226"
    },
    {
      label: "Middle Zone 227"
    },
    {
      label: "Middle Zone 228"
    },
    {
      label: "Middle Zone 235"
    },
    {
      label: "Middle Zone 236"
    },
    {
      label: "Middle Zone 237"
    },
    {
      label: "Middle Zone 238"
    },
    {
      label: "Middle Zone 239"
    },
    {
      label: "Middle Zone 240"
    },
    {
      label: "Middle Zone 241"
    },
    {
      label: "Middle Zone 242"
    },
    {
      label: "Middle Zone 243"
    },
    {
      label: "Middle Zone 308"
    },
    {
      label: "Middle Zone 309"
    },
    {
      label: "Middle Zone 310"
    },
    {
      label: "Middle Zone 311"
    },
    {
      label: "Middle Zone 312"
    },
    {
      label: "Middle Zone 313"
    },
    {
      label: "Middle Zone 313A"
    },
    {
      label: "Middle Zone 314"
    },
    {
      label: "Middle Zone 314A"
    },
    {
      label: "Middle Zone 315"
    },
    {
      label: "Middle Zone 315A"
    },
    {
      label: "Middle Zone 316"
    },
    {
      label: "Middle Zone 316A"
    },
    {
      label: "Middle Zone 317"
    },
    {
      label: "Middle Zone 318"
    },
    {
      label: "Middle Zone 319"
    },
    {
      label: "Middle Zone 320"
    },
    {
      label: "Upper Zone 510"
    },
    {
      label: "Upper Zone 511"
    },
    {
      label: "Upper Zone 512"
    },
    {
      label: "Upper Zone 513"
    },
    {
      label: "Upper Zone 514"
    },
    {
      label: "Upper Zone 515"
    },
    {
      label: "Upper Zone 516"
    },
    {
      label: "Upper Zone 517"
    },
    {
      label: "Upper Zone 518"
    },
    {
      label: "Upper Zone 519"
    },
    {
      label: "Upper Zone 520"
    },
    {
      label: "Upper Zone 521"
    },
    {
      label: "Upper Zone 522"
    },
    {
      label: "Upper Zone 523"
    },
    {
      label: "Upper Zone 530"
    },
    {
      label: "Upper Zone 531"
    },
    {
      label: "Upper Zone 532"
    },
    {
      label: "Upper Zone 533"
    },
    {
      label: "Upper Zone 534"
    },
    {
      label: "Upper Zone 535"
    },
    {
      label: "Upper Zone 536"
    },
    {
      label: "Upper Zone 537"
    },
    {
      label: "Upper Zone 538"
    },
    {
      label: "Upper Zone 539"
    },
    {
      label: "Upper Zone 540"
    },
    {
      label: "Upper Zone 541"
    },
    {
      label: "Upper Zone 542"
    },
    {
      label: "Upper Zone 543"
    },
    {
      label: "Upper Zone 610"
    },
    {
      label: "Upper Zone 611"
    },
    {
      label: "Upper Zone 612"
    },
    {
      label: "Upper Zone 613"
    },
    {
      label: "Upper Zone 614"
    },
    {
      label: "Upper Zone 615"
    },
    {
      label: "Upper Zone 616"
    },
    {
      label: "Upper Zone 617"
    },
    {
      label: "Upper Zone 618"
    },
    {
      label: "Upper Zone 619"
    },
    {
      label: "Upper Zone 634"
    },
    {
      label: "Upper Zone 635"
    },
    {
      label: "Upper Zone 636"
    },
    {
      label: "Upper Zone 637"
    },
    {
      label: "Upper Zone 638"
    },
    {
      label: "Upper Zone 639"
    },
    {
      label: "Upper Zone 640"
    },
    {
      label: "Upper Zone 641"
    },
    {
      label: "Upper Zone 642"
    },
    {
      label: "Upper Zone 643"
    },
    {
      label: "PIL COUNTRY - STANDING ROOM ONLY"
    }
];

export default Object.freeze({
  ZONES: zones,
});
