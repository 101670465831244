import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TIGERCATS_ROWS from "../constants/TigerCatsSeatRows.js";

class TigercatsRowSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  getRows() {
    const { section } = this.props;
    const sectionNo = section.split(" ").pop();
    if (["The Striply", "Stelco Northend"].includes(section)) {
      return TIGERCATS_ROWS.OTHER_ROWS;
    } else if (section.includes("Touchdown Lounge")) {
      return TIGERCATS_ROWS.TOUCHDOWN_LOUNGE_ROWS;
    } else if (["Lower Zone 111", "Lower Zone 119"].includes(section)) {
      // Lower Zone 111 and 119
      return TIGERCATS_ROWS.LOWER_ZONE_OTHER_ROWS;
    } else if (section.includes("Lower Zone")) {
      // Lower Zone 101 - 109
      // Lower Zone 112 - 118
      return TIGERCATS_ROWS.LOWER_ZONE_ROWS;
    } else if (["C1", "C11"].includes(sectionNo)) {
      // Middle Zone C1 and C11
      return TIGERCATS_ROWS.PREMIUM_ZONE_ROWS1;
    } else if (["C2", "C3", "C4", "C5", "C6", "C7", "C8", "C9", "C10"].includes(sectionNo)) {
      // Middle Zone C2 - C10
      return TIGERCATS_ROWS.PREMIUM_ZONE_ROWS2;
    } else if (["202", "203", "204", "205", "206", "207", "208"].includes(sectionNo)) {
      // Upper Zone 202 - 208
      return TIGERCATS_ROWS.UPPER_ZONE_ROWS1;
    } else if (["212", "213", "214", "215", "216", "217", "218"].includes(sectionNo)) {
      // Upper Zone 212 - 218
      return TIGERCATS_ROWS.UPPER_ZONE_ROWS2;
    } else {
      return TIGERCATS_ROWS.DEFAULT_ROWS;
    }
  }

  handleSelection = (event) => {
    this.setState({
      value: event.target.value,
    });

    this.props.onRowSelection(event.target.value);
  };

  render() {
    return (
      <FormControl>
        <InputLabel>Row</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: "row",
            id: "row",
          }}
          placeholder="Seat Row"
          fullWidth
        >
          {this.props.section &&
            this.getRows().map((row, index) => (
              <MenuItem selected={row.value === this.props.value} value={row.value} key={index}>
                {row.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
}

TigercatsRowSelect.propsType = {
  classes: PropTypes.object.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  row: PropTypes.string.isRequired,
};

export default TigercatsRowSelect;
