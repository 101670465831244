import React, { Component } from "react";
import querySearch from "stringquery";

import { withStyles } from "@material-ui/core/styles";

import SelectGameStep from "../components/SelectGameStep";
import SelectSeatStep from "../components/SelectSeatStep";
import FinalizeOrder from "../components/FinalizeOrder";

const styles = (theme) => ({
  [theme.breakpoints.up("md")]: {
    buttons: {
      marginTop: "3rem",
      position: "sticky",
      bottom: "0rem",
      backgroundColor: "#fff",
      width: "100%",
      padding: "1rem 0",
    },
    button: {
      width: "calc(50% - 1rem)",
      margin: "0 .5rem",
    },
  },
  [theme.breakpoints.down("sm")]: {
    buttons: {
      marginTop: "3rem",
      position: "sticky",
      bottom: "0rem",
      backgroundColor: "#fff",
      width: "100%",
      padding: "1rem 0",
    },
    button: {
      width: "calc(50% - 1rem)",
      margin: "0 .5rem",
    },
  },
  root: {
    flexGrow: 1,
  },
  seats: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  seatItem: {
    padding: "1rem",
    borderBottom: "1px solid #f8f8f8",
    cursor: "pointer",
    transition: ".25s all ease-in-out",
    "&:hover": {
      backgroundColor: "#eaeaea",
      transition: ".25s all ease-in-out",
    },
  },
  selectedSeatItem: {
    backgroundColor: "#eaeaea",
    padding: "1rem",
    borderBottom: "1px solid #f8f8f8",
    cursor: "pointer",
    transition: ".25s all ease-in-out",
  },
  seatItemPrice: {
    color: "#4caf50 !important",
  },
  seatDetails: {
    marginTop: "1rem",
  },
  form: {
    marginTop: "1rem",
  },
});

class NewOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: "select-game",
      game: null,
    };
  }

  componentWillMount() {
    const { step, game, seat, qty } = querySearch(this.props.location.search);

    this.setState({
      step,
      game,
      seat,
      qty,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { step } = querySearch(nextProps.location.search);

    if (this.state.step !== step) {
      this.setState({
        step,
      });
    }
  }

  renderStep() {
    const { step } = this.state;

    switch (step) {
      case "select-game": {
        return (
          <SelectGameStep
            onGameSelection={this.onGameSelection}
            classes={this.props.classes}
          />
        );
      }

      case "select-seat": {
        return (
          <SelectSeatStep
            selectedGame={this.state.game}
            selectedSeat={this.state.seat}
            qty={this.state.qty}
            classes={this.props.classes}
          />
        );
      }

      case "finalize-order": {
        return (
          <FinalizeOrder
            selectedGame={this.state.game}
            selectedSeat={this.state.seat}
            qty={this.state.qty}
            classes={this.props.classes}
            history={this.props.history}
          />
        );
      }

      default:
        return null;
    }
  }

  render() {
    // const { classes } = this.props;

    return this.renderStep();
  }
}

export default withStyles(styles)(NewOrder);
