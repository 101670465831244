import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { generateRows, WCRow } from "../constants/AbbotsfordCanucksSeatRows";

class AbbotsfordCanucksRowSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  getRows() {
    const { section } = this.props;
    const sectionNo = section.split(" ").pop();

    if (["100A"].includes(sectionNo)) {
      return [...generateRows(1, 10), ...generateRows(17, 21)];
    } else if (["100", "104", "105", "117"].includes(sectionNo)) {
      return generateRows(1, 21);
    } else if (["100B"].includes(sectionNo)) {
      return [...generateRows(7, 10), ...generateRows(17, 21)];
    } else if (["ADA"].includes(sectionNo)) {
      return WCRow;
    } else if (["101", "102", "107", "108", "110", "111"].includes(sectionNo)) {
      return generateRows(1, 20);
    } else if (["103", "106"].includes(sectionNo)) {
      return [...generateRows(1, 21), ...WCRow];
    } else if (["109A"].includes(sectionNo)) {
      return [...generateRows(7, 10), ...generateRows(17, 19)];
    } else if (["109"].includes(sectionNo)) {
      return generateRows(3, 20);
    } else if (["109B"].includes(sectionNo)) {
      return [...generateRows(7, 10), ...generateRows(17, 20)];
    } else if (["112"].includes(sectionNo)) {
      return [...generateRows(1, 23), ...WCRow];
    } else if (["113"].includes(sectionNo)) {
      return generateRows(1, 14);
    } else if (["114", "115"].includes(sectionNo)) {
      return [...generateRows(1, 14), ...WCRow];
    } else if (["116"].includes(sectionNo)) {
      return generateRows(1, 22);
    } else {
      return [];
    }
  };

  handleSelection = (event) => {
    this.setState({
      value: event.target.value,
    });

    this.props.onRowSelection(event.target.value);
  };

  render() {
    return (
      <FormControl>
        <InputLabel>Row</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: "row",
            id: "row",
          }}
          placeholder="Seat Row"
          fullWidth
        >
          {this.props.section && this.getRows().map((row, index) => (
            <MenuItem selected={row.value === this.props.value} value={row.value} key={index}>
              {row.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

AbbotsfordCanucksRowSelect.propsType = {
  classes: PropTypes.object.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  row: PropTypes.string.isRequired,
};

export default AbbotsfordCanucksRowSelect;
