import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import SeatDetailsForm from "../components/SeatDetailsForm";
import SelectGamesForm from "../components/SelectGamesForm";
import AddSeatPricesForm from "../components/AddSeatPricesForm";

import SeatsProvider from "../components/SeatsProvider";
import { Button, Grid } from "@material-ui/core";
import Context from "../context";

const styles = (theme) => ({
  FormControl: {
    marginBotton: "2rem",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

function getStepContent(step) {
  switch (step) {
    case 0:
      return <SelectGamesForm />;
    case 1:
      return <SeatDetailsForm />;
    case 2:
      return <AddSeatPricesForm />;
    default:
      throw new Error("Unknown step");
  }
}

class AddSeats extends Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  isValidStep = (context) => {
    const { activeStep } = this.state;
    if (activeStep === 0) {
      return context.state.selectedGames.length > 0;
    }
    return true;
  }

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;
    const currUserEmail = this.props.currUser.email;

    return (
      <SeatsProvider currUserEmail={currUserEmail}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <div className={classes.buttons}>
              {activeStep !== 0 && (
                <Button onClick={this.handleBack} className={classes.button}>
                  Back
                </Button>
              )}
              {activeStep !== 2 && (
                <Context.Consumer>
                  {(context) => (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                      disabled={!this.isValidStep(context)}
                    >
                      Next
                    </Button>
                  )}
                </Context.Consumer>
              )}
            </div>
            {getStepContent(activeStep)}
            <div className={classes.buttons}>
              {activeStep !== 0 && (
                <Button onClick={this.handleBack} className={classes.button}>
                  Back
                </Button>
              )}
              {activeStep !== 2 && (
                <Context.Consumer>
                  {(context) => (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                      disabled={!this.isValidStep(context)}
                    >
                      Next
                    </Button>
                  )}
                </Context.Consumer>
              )}
            </div>
          </Grid>
        </Grid>
      </SeatsProvider>
    );
  }
}

export default withStyles(styles)(AddSeats);
