import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import Inventory from "../components/TicketEvoInventory"
import Orders from "../components/TicketEvoOrders"

import {
  Button,
  Typography
} from "@material-ui/core";


const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 3,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2rem",
  },
  creditCardConatiner: {
    padding: "0 24px 24px 24px",
  },
  textField: {
    padding: ".75rem .75rem .75rem .75rem",
    marginBottom: 0,
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
  },
  textFieldError: {
    padding: ".75rem",
    marginBottom: 0,
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
  },
  input: {
    padding: 0,
    margin: 0,
    fontSize: "0.875rem",
  },
  cardFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginTop: "1rem",
    marginBottom: ".25rem",
  },
  cardField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginTop: "1rem",
    marginBottom: ".25rem",
  },
  cardInformationContainer: {
    padding: "0px 24px 24px 24px",
  }
});

class TicketEvo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "Ticket Evolution Inventory"
    };
    
  }

  handleAlignment = (event, newPage) => {
    this.setState({page: newPage});
  };

  render() {
    const { classes } = this.props;
    const { page } = this.state;

    if (page === "Ticket Evolution Inventory"){
      return (
        <div className={classes.root}>
          <div className={classes.header}>
            <Typography variant="display2">Ticket Evolution Inventory</Typography>
            <Button onClick={() => {this.setState({page:"Ticket Evolution Orders"})}}>View Orders</Button>
          </div>
          <Inventory></Inventory>
        </div>
      );
    } else if (page === "Ticket Evolution Orders") {
      return (
        <div className={classes.root}>
          <div className={classes.header}>
            <Typography variant="display2">Ticket Evolution Orders</Typography>
            <Button onClick={() => {this.setState({page:"Ticket Evolution Inventory"})}}>View Inventory</Button>
          </div>
          <Orders></Orders>
        </div>
      );
    }
    
  }
}

export default withStyles(styles)(TicketEvo);
