const zones = [
  {
    label: "Lower Zone 101",
  },
  {
    label: "Lower Zone 102",
  },
  {
    label: "Lower Zone 103",
  },
  {
    label: "Lower Zone 104",
  },
  {
    label: "Lower Zone 105",
  },
  {
    label: "Lower Zone 106",
  },
  {
    label: "Lower Zone 107",
  },
  {
    label: "Lower Zone 108",
  },
  {
    label: "Lower Zone 109",
  },
  {
    label: "Lower Zone 110",
  },
  {
    label: "Lower Zone 111",
  },
  {
    label: "Lower Zone 112",
  },
  {
    label: "Lower Zone 113",
  },
  {
    label: "Lower Zone 114",
  },
  {
    label: "Lower Zone 115",
  },
  {
    label: "Lower Zone 116",
  },
  {
    label: "Lower Zone 117",
  },
  {
    label: "Lower Zone 118",
  },
  {
    label: "Lower Zone 119",
  },
  {
    label: "Lower Zone 120",
  },
  {
    label: "Lower Zone 121",
  },
  {
    label: "Lower Zone 122",
  },
  {
    label: "Upper Zone 301",
  },
  {
    label: "Upper Zone 302",
  },
  {
    label: "Upper Zone 303",
  },
  {
    label: "Upper Zone 304",
  },
  {
    label: "Upper Zone 305",
  },
  {
    label: "Upper Zone 306",
  },
  {
    label: "Upper Zone 307",
  },
  {
    label: "Upper Zone 308",
  },
  {
    label: "Upper Zone 309",
  },
  {
    label: "Upper Zone 310",
  },
  {
    label: "Upper Zone 311",
  },
  {
    label: "Upper Zone 312",
  },
  {
    label: "Upper Zone 313",
  },
  {
    label: "Upper Zone 314",
  },
  {
    label: "Upper Zone 315",
  },
  {
    label: "Upper Zone 316",
  },
  {
    label: "Upper Zone 317",
  },
  {
    label: "Upper Zone 318",
  },
  {
    label: "Upper Zone 319",
  },
  {
    label: "Upper Zone 320",
  },
  {
    label: "Upper Zone 321",
  },
  {
    label: "Upper Zone 322",
  },
  {
    label: "Upper Zone 323",
  },
  {
    label: "Upper Zone 324",
  },
  {
    label: "Suite 200",
  },
  {
    label: "Suite 201",
  },
  {
    label: "Suite 202",
  },
  {
    label: "Suite 203",
  },
  {
    label: "Suite 204",
  },
  {
    label: "Suite 205",
  },
  {
    label: "Suite 206",
  },
  {
    label: "Suite 207",
  },
  {
    label: "Suite 208",
  },
  {
    label: "Suite 209",
  },
  {
    label: "Suite 210",
  },
  {
    label: "Suite 211",
  },
  {
    label: "Suite 212",
  },
  {
    label: "Suite 213",
  },
  {
    label: "Suite 214",
  },
  {
    label: "Suite 215",
  },
  {
    label: "Suite 216",
  },
  {
    label: "Suite 217",
  },
  {
    label: "Suite 218",
  },
  {
    label: "Suite 219",
  },
  {
    label: "Suite 220",
  },
  {
    label: "Suite 221",
  },
  {
    label: "Suite 222",
  },
  {
    label: "Suite 223",
  },
  {
    label: "Suite 224",
  },
  {
    label: "Suite 225",
  },
  {
    label: "Suite 226",
  },
  {
    label: "Suite 227",
  },
  {
    label: "Suite 228",
  },
  {
    label: "Suite 229",
  },
  {
    label: "Suite 230",
  },
  {
    label: "Suite 231",
  },
  {
    label: "Suite 232",
  },
  {
    label: "Suite 233",
  },
  {
    label: "Suite 234",
  },
  {
    label: "Suite 235",
  },
  {
    label: "Suite 236",
  },
  {
    label: "Suite 237",
  },
  {
    label: "Suite 238",
  },
  {
    label: "Suite 239",
  },
  {
    label: "Suite 240",
  },
  {
    label: "Suite 241",
  },
  {
    label: "Suite 242",
  },
  {
    label: "Suite 243",
  },
  {
    label: "Suite 244",
  },
  {
    label: "Suite 245",
  },
  {
    label: "Suite 246",
  },
  {
    label: "Suite 247",
  },
  {
    label: "Suite 248",
  },
  {
    label: "Suite 249",
  },
  {
    label: "Suite 250",
  },
  {
    label: "Suite 251",
  },
  {
    label: "Suite 252",
  },
  {
    label: "Suite 253",
  },
  {
    label: "Suite 254",
  },
  {
    label: "Suite 255",
  },
  {
    label: "Suite 256",
  },
  {
    label: "Suite 257",
  },
  {
    label: "Suite 258",
  },
  {
    label: "Suite 259",
  },
  {
    label: "Suite 260",
  },
  {
    label: "Suite 261",
  },
  {
    label: "Suite 262",
  },
  {
    label: "Suite 263",
  },
  {
    label: "Suite 264",
  },
  {
    label: "Suite 401",
  },
  {
    label: "Suite 402",
  },
  {
    label: "Suite 403",
  },
  {
    label: "Suite 404",
  },
  {
    label: "Suite 405",
  },
  {
    label: "Suite 406",
  },
  {
    label: "Suite 407",
  },
  {
    label: "Suite 408",
  },
  {
    label: "Suite 501",
  },
  {
    label: "Suite 502",
  },
  {
    label: "Suite 503",
  },
  {
    label: "Suite 504",
  },
  {
    label: "Suite 505",
  },
  {
    label: "Suite 506",
  },
  {
    label: "Suite 507",
  },
  {
    label: "Suite 508",
  },
  {
    label: "Suite 509",
  },
  {
    label: "Suite 510",
  },
  {
    label: "Suite 511",
  },
  {
    label: "Suite 512",
  },
  {
    label: "Suite 513",
  },
  {
    label: "Suite 514",
  },
  {
    label: "Suite 515",
  },
  {
    label: "Suite 516",
  },
  {
    label: "Suite 517",
  },
  {
    label: "Suite 518",
  },
  {
    label: "Suite 519",
  },
  {
    label: "Suite 601",
  },
  {
    label: "Suite 601A",
  },
  {
    label: "Suite 602",
  },
  {
    label: "Suite 603",
  },
  {
    label: "Suite 604",
  },
  {
    label: "Suite 605",
  },
  {
    label: "Suite 606",
  },
  {
    label: "Suite 607",
  },
  {
    label: "Suite 608",
  },
  {
    label: "Suite 609",
  },
  {
    label: "Suite 610",
  },
  {
    label: "Suite 611",
  },
  {
    label: "Suite 612",
  },
  {
    label: "Suite 613",
  },
  {
    label: "Suite 613A",
  },
  {
    label: "Suite 614",
  },
  {
    label: "Suite 615",
  },
  {
    label: "Suite 616",
  },
  {
    label: "Suite 617",
  },
  {
    label: "Suite 618",
  },
  {
    label: "Suite 619",
  },
  {
    label: "Suite 620",
  },
  {
    label: "Suite 621",
  },
  {
    label: "GBOX 1",
  },
  {
    label: "GBOX 2",
  },
  {
    label: "GBOX 3",
  },
  {
    label: "GBOX 4",
  },
  {
    label: "GBOX 5",
  },
  {
    label: "GBOX 6",
  },
  {
    label: "Molson",
  },
];

export default Object.freeze({
  ZONES: zones,
});
