import { range, flatten } from "lodash";

const zoneGroup = [
  {
    label: "Lower Zone",
    numbers: [
      ...["1", "2", "3", "4", "5"],
      ...range(16, 33), // generate 16 - 32
      ...["A 101", "B 101", "A 102", "B 102", "A 103", "B 103", "B 104"],
      ...["W111"],
      ...range(108, 144), // generate 108 - 143
      ...["A 144", "B 144", "A 145", "B 145", "A 146", "B 146", "A 147", "B 147", "A 148", "B 148"]
    ],
  },
  {
    label: "Middle Zone",
    numbers: [
      ...range(204, 208),
      ...["W 207"],
      ...range(210, 241), // generate 210 - 240
      ...["W 240"],
      ...range(241, 245), // generate 241 - 244
    ],
  },
  {
    label: "Upper Zone",
    numbers: range(508, 541),
  },
];

const otherZones = [
  {
    label: "TM LOUNGE",
  },
  {
    label: "WESTJET FLIGHT DECK",
  },
  {
    label: "CORONA ROOFTOP PATIO",
  },
  {
    label: "HOME RUN ZONE",
  },
  {
    label: "LEFT FIELD BALCONY",
  },
  {
    label: "RIGHT FIELD BLEACHERS",
  },
  {
    label: "ROGERS LANDING",
  },
  {
    label: "SCHNEIDERS PORCH",
  },
  {
    label: "TD PARK SOCIAL",
  },
  {
    label: "THE CATCH BAR",
  },
  {
    label: "THE STOP",
  },
];

const zones = zoneGroup.map((zone) => {
  return zone.numbers.map((number) => ({ label: `${zone.label} ${number}` }));
});

export default Object.freeze({
  ZONES: flatten([...zones, ...otherZones]),
});
