import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import _ from "lodash";
import { API } from "aws-amplify";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

class SelectStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGame: props.selectedGame,
      game: [],
      qty: props.qty ? parseInt(props.qty) : 0,
      selectedSeat: props.selectedSeat,
      seatDetails: null,
      seats: null,
      filteredSeats: null,
      isLoading: true,
    };

    this.handleQtyChange = this.handleQtyChange.bind(this);
  }

  async componentDidMount() {
    const game = await this.game(this.props.selectedGame);

    const seats = await this.seats(this.props.selectedGame);
    const filteredSeats = _.chain(seats)
      .filter(
        (seat) =>
          seat.noOfSeats - seat.seatsSold > 0 &&
          !seat.isArchived &&
          !seat.isSold
      )
      .map((seat) => {
        return {
          ...seat,
          availableQty: seat.noOfSeats - seat.seatsSold,
        };
      })
      .sortBy(["zoneNo", "zone", "row"])
      .value();

    this.setState({
      filteredSeats,
      seats: filteredSeats,
      seatDetails: this.props.selectedSeat
        ? seats.find((seat) => seat.seatId === this.props.selectedSeat)
        : null,
      isLoading: false,
      game,
    });
  }

  game(id) {
    return API.get("v2", `marketplace/events/by/eventId/${id}`);
  }

  seats(game) {
    return API.get(
      "v2",
      `marketplace/listings/by/eventId/${game}?include_seller_details=true`
    );
  }

  selectSeat(selectedSeat) {
    this.setState({
      selectedSeat,
      seatDetails: this.state.seats.find(
        (seat) => seat.seatId === selectedSeat
      ),
    });
  }

  handleQtyChange(e) {
    const qty = parseInt(e.target.value);
    const { seats } = this.state;

    this.setState({
      qty,
      filteredSeats: seats.filter(
        (seat) =>
          seat.availableQty >= qty &&
          (seat.availableQty - qty > 1 || seat.availableQty - qty === 0)
      ),
    });
  }

  renderGameDetails() {
    const { classes } = this.props;
    const { longName, date, timezone } = this.state.game;

    return (
      <div className={classes.gameDetails}>
        <Typography variant="body2">{longName}</Typography>
        <Typography variant="body1">
          {moment.tz(date, timezone).format("ddd, MMM DD YYYY")} at{" "}
          {moment.tz(date, timezone).format("h:mm A")}
        </Typography>
      </div>
    );
  }

  renderNoOfSeats() {
    const { qty } = this.state;

    return (
      <Select
        value={qty}
        autoWidth
        onChange={this.handleQtyChange}
        name="qty"
        style={{ marginTop: "2rem" }}
      >
        <MenuItem value={0}>How many seats?</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={6}>6</MenuItem>
        <MenuItem value={7}>7</MenuItem>
        <MenuItem value={8}>8</MenuItem>
        <MenuItem value={9}>9</MenuItem>
        <MenuItem value={10}>10</MenuItem>
      </Select>
    );
  }

  renderSeats() {
    const { classes } = this.props;
    const { filteredSeats, isLoading, selectedSeat } = this.state;

    if (isLoading) {
      return (
        <Typography variant="body2">Loading seats... Please wait...</Typography>
      );
    }

    if (filteredSeats) {
      return (
        <React.Fragment>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ textTransform: "uppercase", marginBottom: "1rem" }}
          >
            Available Seats
          </Typography>
          <Grid container>
            {filteredSeats.map((seat) => (
              <Grid
                key={seat.seatId}
                item
                xs={12}
                className={
                  selectedSeat === seat.seatId
                    ? classes.selectedSeatItem
                    : classes.seatItem
                }
                onClick={() => this.selectSeat(seat.seatId)}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {seat.zone} {seat.zoneNo} Row {seat.row}
                    </Typography>
                    <Typography variant="caption">
                      {seat.noOfSeats - seat.seatsSold} Available
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      align="right"
                      className={classes.seatItemPrice}
                    >
                      $ {seat.price.toFixed(2)}
                    </Typography>
                    <Typography
                      variant="caption"
                      align="right"
                      title={`${seat.sellerFullName} - ${seat.sellerEmail}`}
                    >
                      {seat.sellerFullName.substr(0, 5)}... -{" "}
                      {seat.sellerEmail.substr(0, 8)}...
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </React.Fragment>
      );
    }
  }

  renderSelectedSeat() {
    if (!this.state.seatDetails) {
      return null;
    }

    const { zone, zoneNo, row, sellerEmail, sellerFullName, price } =
      this.state.seatDetails;

    return (
      <React.Fragment>
        <Typography variant="body2">
          {zone} {zoneNo} Row {row}
        </Typography>
        <Typography variant="body1">
          {sellerFullName} - {sellerEmail}
        </Typography>

        <Typography
          variant="body2"
          style={{ marginTop: "1rem", color: "#4caf50" }}
        >
          ${price.toFixed(2)} / seat
        </Typography>
        <Typography variant="body2">
          ${(price.toFixed(2) * this.state.qty).toFixed(2)} Total
        </Typography>
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="display2" style={{ marginBottom: "2rem" }}>
            2. Select Seat
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {this.renderGameDetails()}
        </Grid>
        <Grid item xs={12}>
          {this.renderNoOfSeats()}
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.seats}>{this.renderSeats()}</div>
          <div className={classes.buttons}>
            <Button
              className={classes.button}
              color="default"
              variant="contained"
              href={`/orders/create/?step=select-game`}
            >
              Back to Games
            </Button>
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              disabled={!this.state.selectedSeat || this.state.qty === 0}
              href={`/orders/create/?step=finalize-order&game=${this.state.selectedGame}&seat=${this.state.selectedSeat}&qty=${this.state.qty}`}
            >
              Finalize Order
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <div
            style={{
              paddingLeft: "5rem",
              paddingTop: "2rem",
              position: "sticky",
              top: 0,
            }}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ textTransform: "uppercase", marginBottom: "2rem" }}
            >
              Selected Seat
            </Typography>

            <div className={classes.seatDetails}>
              {this.renderSelectedSeat()}
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

SelectStep.propsType = {
  selectedGame: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default SelectStep;
