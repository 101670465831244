import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { API } from "aws-amplify";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 3,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2rem",
  },
  creditCardConatiner: {
    padding: "0 24px 24px 24px",
  },
  textField: {
    padding: ".75rem .75rem .75rem .75rem",
    marginBottom: 0,
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
  },
  textFieldError: {
    padding: ".75rem",
    marginBottom: 0,
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
  },
  input: {
    padding: 0,
    margin: 0,
    fontSize: "0.875rem",
  },
  cardFieldError: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 23, 68, .05)",
    border: "solid 1px rgba(255, 23, 68, .5)",
    marginTop: "1rem",
    marginBottom: ".25rem",
  },
  cardField: {
    padding: ".75rem",
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    border: "solid 1px #e5e5ea",
    marginTop: "1rem",
    marginBottom: ".25rem",
  },
  cardInformationContainer: {
    padding: "0px 24px 24px 24px",
  }
});

class TicketEvoOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: []
    };
  }

  async componentDidMount() {
    const { orders } = await API.get(
      "v2",
      `marketplace/ticketEvo/admin/orders/get`
    );
    console.log(orders)
    this.setState({orders})
  }

  getTicketType(format) {
    switch (format) {
      case "TM_mobile":
        return "TMMobile"
    
      default:
        return format
    }
  }

  renderOrders() {
    const { orders } = this.state;
    return (
      <React.Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Game</TableCell>
              <TableCell>Order TicketEvo ID</TableCell>
              <TableCell>Game Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Row</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => this.renderRow(order))}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  renderRow(order) {
    console.log(order);

    return (
      <TableRow>
        <TableCell>{order.items[0].ticket_group.event.name}</TableCell>
        <TableCell>{order.oid}</TableCell>
        <TableCell>{order.items[0].ticket_group.event.occurs_at.split(" ")[0]}</TableCell>
        <TableCell>{this.getTicketType(order.items[0].ticket_group.format)}</TableCell>
        <TableCell>{order.items[0].ticket_group.section}</TableCell>
        <TableCell>{order.items[0].ticket_group.row}</TableCell>
        <TableCell>{order.items[0].ticket_group.quantity}</TableCell>
        <TableCell>${order.total}</TableCell>
        <TableCell>{order.seller_state}</TableCell>
      </TableRow>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderOrders()}
      </div>
    );
  }
}

export default withStyles(styles)(TicketEvoOrders);
