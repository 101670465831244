import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, Typography } from "@material-ui/core";
import { isEmpty, isNil } from "lodash";

class DialogConfirmInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      fieldValue: props.value
    }
  }

  onConfirm() {
    this.setState({ open: false });
    this.props.destroy();
    this.props.onConfirm(this.state.fieldValue);
  } 
  
  onCancel() {
    this.setState({ open: false });
    this.props.destroy();
    this.props.onCancel();
  }

  checkError() {
    if (this.props.inputRequired) {
      if (isNil(this.state.fieldValue) || isEmpty(this.state.fieldValue)) {
        return "This field is required";
      }
    }
    
    if (this.props.inputType === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.fieldValue)) {
      return "Email is not valid";
    }

    return "";
  }

  render() {
    const { title, description, cancelLabel, confirmLabel, inputLabel, inputType, inputRequired } = this.props

    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.onCancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
          <TextField
              autoFocus
              margin="dense"
              id="dialog-input-value"
              label={inputLabel}
              type={inputType}
              value={this.state.fieldValue}
              onChange={e => this.setState({ fieldValue: e.target.value })}
              fullWidth
              error={!isEmpty(this.checkError())}
              required={inputRequired}
              autoComplete="off"
            />
            {this.checkError() && <Typography variant="caption" color="error" gutterBottom>{this.checkError()}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.onCancel()} color="primary">
            {cancelLabel}
          </Button>
          <Button onClick={() => this.onConfirm()} color="primary" disabled={!isEmpty(this.checkError())} autoFocus>
            {confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogConfirmInput.defaultProps = {
  title: "Confirm",
  description: "",
  value: "",
  inputRequired: false,
  inputLabel: "",
  inputType: "text",
  cancelLabel: "Cancel",
  confirmLabel: "Ok",
}

export default DialogConfirmInput;