const singleRow = [
  {
    value: 1,
    label: 'Row 1',
  },
];

const GARow = [
  {
    value: "GA",
    label: 'Row GA',
  },
];

const FRow = [
  {
    value: "F",
    label: 'Row F',
  },
];

const defaultRows = () => {
  const arr = [];

  for (let i = 1; i <= 40; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const lowerZoneRows = () => {
  const arr = [];
  const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H"];

  for (let i = 1; i <= 40; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  alphabet.map(item => {
    arr.push({
      value: item,
      label: `Row ${item}`,
    });
  })

  return arr;
};

const westjetFlightDeckRow = () => {
  const arr = [];

  for (let i = 1; i <= 40; i++) {
    arr.push({
      value: i,
      label: `Row GA${i}`,
    });
  }

  return arr;
};

const generateRows = (start, end, char=null) => {
  const arr = [];

  for (let i = start; i <= end; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  if (char) {
    arr.push({
      value: char,
      label: `Row ${char}`,
    });
  }  

  return arr;
}

export default {
  SINGLE_ROW: singleRow,
  WESTJETFLIGHTDECK_ROW: westjetFlightDeckRow(),
  LOWER_ZONE_ROWS: lowerZoneRows(),
  DEFAULT_ROWS: defaultRows(),
  generateRows,
  GA_ROW: GARow,
  F_ROW: FRow
};
