const zones = [
    {
      label: "Lower Zone A",
    },
    {
      label: "Lower Zone B",
    },
    {
      label: "Lower Zone C",
    },
    {
      label: "Lower Zone D",
    },
    {
      label: "Lower Zone E",
    },
    {
      label: "Lower Zone F",
    },
    {
      label: "Lower Zone G",
    },
    {
      label: "Lower Zone H",
    },
    {
      label: "Lower Zone I",
    },
    {
      label: "Lower Zone J",
    },
    {
      label: "Lower Zone K",
    },
    {
      label: "Lower Zone L",
    },
    {
      label: "Lower Zone M",
    },
    {
      label: "Lower Zone N",
    },
    {
      label: "Lower Zone O",
    },
    {
      label: "Lower Zone P",
    },
    {
      label: "Lower Zone Q",
    },
    {
      label: "Lower Zone R",
    },
    {
      label: "Lower Zone S",
    },
    {
      label: "Lower Zone T",
    },
    {
      label: "Lower Zone U",
    },
    {
      label: "Lower Zone V",
    },
    {
      label: "Lower Zone W",
    },
    {
      label: "Lower Zone X",
    },
    {
      label: "CLUBHOUSE",
    },
  ];
  
  export default Object.freeze({
    ZONES: zones,
  });
  