import React, { Component } from "react";
import { API } from "aws-amplify";
import querySearch from "stringquery";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { getSeatLabel, renderZoneName, renderRowName } from "../libs/shared/helpers";

const styles = {
  root: {
    flexGrow: 1,
  },
  FormControl: {
    marginTop: "1rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
};

class UpdateSeat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seat: null,
      isSubmitting: false,
      price: 1,
      returnUrl: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentWillMount() {
    const seat = await this.seat();
    const { team, return_url } = querySearch(this.props.location.search);

    this.setState({
      seat,
      price: seat.price,
      seatNumberStart: Number(seat.seatNumberStart) > 0 ? seat.seatNumberStart : "",
      seatNumberEnd: Number(seat.seatNumberEnd) > 0 ? seat.seatNumberEnd : "",
      noOfSeats: seat.noOfSeats - seat.seatsSold,
      sellerEmail: seat.sellerEmail ? seat.sellerEmail : "",
      team,
      returnUrl: return_url ? decodeURIComponent(return_url) : null,
      sellOption: seat.sellOption,
      isAisleSeat: seat.isAisleSeat,
      isWheelchair: seat.isWheelchair,
      isObstructedView: seat.isObstructedView,
      isInstantDelivery: seat.isInstantDelivery,
    });
  }

  seat() {
    const { id } = this.props.match.params;
    const { game } = querySearch(this.props.location.search);
    return API.get(
      "v2",
      `marketplace/listings/by/eventId/${game}?seatId=${id}&include_seller_details=true`
    );
  }

  handleChange(e) {
    const {seat} = this.state;
    const isSeatNumber = e.currentTarget.name === "seatNumberStart";
    const isQtySeat = e.currentTarget.name === "noOfSeats";
    if (isQtySeat) {
      const seatStart = this.state.seatNumberStart;
      const newNoOfSeats = Number(e.target.value);
      const seatNumberEnd = seatStart > 0 ? seatStart + newNoOfSeats - 1 : "";
      this.setState({
        [e.currentTarget.name]: e.target.value,
        seatNumberEnd: seatNumberEnd,
        noOfSeats: newNoOfSeats
      })
    } else if (isSeatNumber) {
      const seatStart = Number(e.target.value);
      const seatNumberEnd = seatStart > 0 ? seatStart + Number(this.state.noOfSeats) - 1 : "";
      this.setState({
        [e.currentTarget.name]: e.target.value,
        seatNumberEnd: seatNumberEnd,
        seatNumberStart: seatStart,
      });
    }
  }

  async submit() {
    this.setState({ isSubmitting: true });
    const { currUser } = this.props;

    const { id } = this.props.match.params;
    const { game } = querySearch(this.props.location.search);
    const { team } = this.state;

    try {
      var requestUrl = `marketplace/listings/update?adminName=${currUser.name}&eventId=${game}&listingId=${id}`;
      requestUrl += `&price=${this.state.price}`;
      requestUrl += `&quantity=${
        parseInt(this.state.noOfSeats) + this.state.seat.seatsSold
      }`;
      requestUrl += `&sellOption=${this.state.sellOption}`;
      
      requestUrl += `&seatNumberStart=${Number(this.state.seatNumberStart)}`;
      requestUrl += `&seatNumberEnd=${Number(this.state.seatNumberEnd)}`;

      requestUrl += `&isAisleSeat=${this.state.isAisleSeat}`;
      requestUrl += `&isObstructedView=${this.state.isObstructedView}`;
      requestUrl += `&isInstantDelivery=${this.state.isInstantDelivery}`;
      requestUrl += `&isWheelchair=${this.state.isWheelchair}`;

      await API.post("v2", requestUrl, { headers: { email: currUser.email } });

      if (this.state.returnUrl) {
        this.props.history.push(this.state.returnUrl);
      } else {
        this.props.history.push(`/seats?game=${game}&team=${team}`);
      }
    } catch (e) {
      this.setState({ isSubmitting: false });
      alert(e);
    }
  }

  render() {
    const { classes } = this.props;
    const { seat, price, noOfSeats, sellerEmail, seatNumberEnd, seatNumberStart } = this.state;

    return (
      seat && (
        <div className={classes.root} id="UpdateSeat">
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="display2">Update Seat Price</Typography>
              <div
                style={{
                  marginTop: "1rem",
                }}
              >
                <Typography variant="caption">Game</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.gameDetails.isTbd
                    ? seat.gameName.split(" - ")[0] + " - TBD"
                    : seat.gameName}
                </Typography>
                <Typography variant="caption">Section</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {renderZoneName(seat.zone, seat.zoneNo, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}
                </Typography>
                <Typography variant="caption">Row</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {renderRowName(seat.zone, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}
                </Typography>
                <Typography variant="caption">Price</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.price} ea
                </Typography>
                <Typography variant="caption">Seat</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {getSeatLabel(seat)}
                </Typography>
                <Typography variant="caption">Total Qty</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.noOfSeats}
                </Typography>
                <Typography variant="caption">Sold Qty</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.seatsSold}
                </Typography>
                <Typography variant="caption">Available Qty</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {seat.noOfSeats - seat.seatsSold}
                </Typography>
                <Typography variant="caption">Seller Email</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {sellerEmail}
                </Typography>
              </div>

              <form className={classes.form}>
                <FormControl className={classes.FormControl}>
                  <TextField
                    name="price"
                    value={price}
                    type="number"
                    label="New Price"
                    placeholder="New Price"
                    min="1"
                    max="999"
                    onChange={(e) =>
                      this.setState({ price: Number(e.target.value) })
                    }
                  />
                </FormControl>
                <FormControl className={classes.FormControl}>
                  <TextField
                    name="seatNumberStart"
                    value={seatNumberStart}
                    type="number"
                    label="New Seat Number Start"
                    placeholder="New Seat Number Start"
                    min="0"
                    max="999"
                    onChange={this.handleChange}
                  />
                  <TextField
                    name="seatNumberEnd"
                    value={seatNumberEnd}
                    type="number"
                    label="New Seat Number End"
                    placeholder="New Seat Number End"
                    min="0"
                    max="999"
                    disabled={true}
                  />
                </FormControl>
                <FormControl className={classes.FormControl}>
                  <TextField
                    name="noOfSeats"
                    value={noOfSeats}
                    type="number"
                    label={`Total Qty (${seat.seatsSold} Sold / ${
                      parseInt(noOfSeats) + seat.seatsSold
                    } Total)`}
                    placeholder="Total Available Seat"
                    min="1"
                    onChange={this.handleChange}
                  />
                </FormControl>
                <FormControl className={classes.FormControl}>
                  <Select
                    value={
                      this.state.sellOption
                        ? this.state.sellOption
                        : seat.sellOption
                    }
                    name="sellOption"
                    id="sellOption"
                    onChange={(e) =>
                      this.setState({ sellOption: Number(e.target.value) })
                    }
                  >
                    <MenuItem value={2}>
                      Sell any, but don't leave a single ticket
                    </MenuItem>
                    <MenuItem value={3}>
                      Sell all (keep my tickets together as a set)
                    </MenuItem>
                    <MenuItem value={1}>
                      Sell any, even if it leaves a single remaining
                    </MenuItem>
                    <MenuItem value={4}>Sell in pairs</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel
                  style={{ height: "40px", marginTop: "10px" }}
                  control={
                    <Checkbox
                      onChange={(e) =>
                        this.setState({ isAisleSeat: e.target.checked })
                      }
                      defaultChecked={this.state.isAisleSeat}
                      color="default"
                      height="40px"
                    ></Checkbox>
                  }
                  label={
                    <Typography
                      style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        margin: "0px",
                        fontSize: "1rem",
                      }}
                      className={classes.FormControl}
                    >
                      Aisle Seats
                    </Typography>
                  }
                />
                <FormControlLabel
                  style={{ height: "40px", marginTop: "10px" }}
                  control={
                    <Checkbox
                      onChange={(e) =>
                        this.setState({ isObstructedView: e.target.checked })
                      }
                      defaultChecked={this.state.isObstructedView}
                      color="default"
                      height="40px"
                    ></Checkbox>
                  }
                  label={
                    <Typography
                      style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        margin: "0px",
                        fontSize: "1rem",
                      }}
                      className={classes.FormControl}
                    >
                      Obstructed View
                    </Typography>
                  }
                />
                <FormControlLabel
                  style={{ height: "40px", marginTop: "10px" }}
                  control={
                    <Checkbox
                      onChange={(e) =>
                        this.setState({ isInstantDelivery: e.target.checked })
                      }
                      defaultChecked={this.state.isInstantDelivery}
                      color="default"
                      height="40px"
                    ></Checkbox>
                  }
                  label={
                    <Typography
                      style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        margin: "0px",
                        fontSize: "1rem",
                      }}
                      className={classes.FormControl}
                    >
                      Instant Delivery
                    </Typography>
                  }
                />
                <FormControlLabel
                  style={{ height: "40px", marginTop: "10px" }}
                  control={
                    <Checkbox
                      onChange={(e) =>
                        this.setState({ isWheelchair: e.target.checked })
                      }
                      defaultChecked={this.state.isWheelchair}
                      color="default"
                      height="40px"
                    ></Checkbox>
                  }
                  label={
                    <Typography
                      style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        margin: "0px",
                        fontSize: "1rem",
                      }}
                      className={classes.FormControl}
                    >
                      Wheelchair
                    </Typography>
                  }
                />
                <FormControl className={classes.FormControl}>
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={this.state.isSubmitting}
                    onClick={this.submit}
                  >
                    Update Seat
                  </Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </div>
      )
    );
  }
}

export default withStyles(styles)(UpdateSeat);
