import React from "react";
import { Route, Redirect } from "react-router-dom";

import { hasAccess } from "../libs/rbac";

export default ({ component: C, props: cProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { pathname, search } = props.location;
      const { currUser, isAuthenticated } = cProps;
      if (!isAuthenticated) {
        return <Redirect to={`/login?redirect=${pathname}${search}`} />;
      }

      if (!currUser) {
        return <Redirect to={`/`} />;
      }

      if (
        hasAccess(pathname, currUser.userGroup) &&
        currUser.email != "orders@fansfirst.ca"
      ) {
        return <C {...props} {...cProps} />;
      } else {
        alert("You have no access to this page");

        return <Redirect to={`/`} />;
      }
    }}
  />
);
