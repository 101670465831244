import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlamesSectionSelect from "./FlamesSectionSelect";
import WranglersSectionSelect from "./WranglersSectionSelect";
import MooseSectionSelect from "./MooseSectionSelect";
import JetsSectionSelect from "./JetsSectionSelect";
import OilersSectionSelect from "./OilersSectionSelect";
import CanucksSectionSelect from "./CanucksSectionSelect";
import MapleLeafsSectionSelect from "./MapleLeafsSectionSelect";
import RaptorsSectionSelect from "./RaptorsSectionSelect";
import BlueJaysSectionSelect from "./BlueJaysSectionSelect";
import SenatorsSectionSelect from "./SenatorsSectionSelect";
import ElksSectionSelect from "./ElksSectionSelect";
import ArgonautsSectionSelect from "./ArgonautsSectionSelect";
import TorontoFcsSectionSelect from "./TorontoFcsSectionSelect";
import BlueBombersSectionSelect from "./BlueBombersSectionSelect";
import StampedersSectionSelect from "./StampedersSectionSelect";
import RedblacksSectionSelect from "./RedblacksSectionSelect";
import LionsSectionSelect from "./LionsSectionSelect";
import WhitecapsSectionSelect from "./WhitecapsSectionSelect";
import RoughridersSectionSelect from "./RoughridersSectionSelect";
import TigercatsSectionSelect from "./TigercatsSectionSelect";
import AbbotsfordCanucksSectionSelect from './AbbotsfordCanucksSectionSelect';
import BellevilleSenatorsSectionSelect from './BellevilleSenatorsSectionSelect';
import TorontoMarliesSectionSelect from './TorontoMarliesSectionSelect';

class SectionSelect extends Component {
  render() {
    const { selectedTeam, onSectionSelection, section } = this.props;
    if (selectedTeam === "calgary-flames") {
      return (
        <FlamesSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "winnipeg-jets") {
      return (
        <JetsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "edmonton-oilers") {
      return (
        <OilersSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "vancouver-canucks") {
      return (
        <CanucksSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "toronto-maple-leafs") {
      return (
        <MapleLeafsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "toronto-raptors") {
      return (
        <RaptorsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "toronto-blue-jays") {
      return (
        <BlueJaysSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "ottawa-senators") {
      return (
        <SenatorsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "edmonton-elks") {
      return (
        <ElksSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "toronto-argonauts") {
      return (
        <ArgonautsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "winnipeg-blue-bombers") {
      return (
        <BlueBombersSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "calgary-stampeders") {
      return (
        <StampedersSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "ottawa-redblacks") {
      return (
        <RedblacksSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "bc-lions") {
      return (
        <LionsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "saskatchewan-roughriders") {
      return (
        <RoughridersSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "hamilton-tigercats") {
      return (
        <TigercatsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "grey-cup") {
      return (
        <LionsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "calgary-wranglers") {
      return (
        <WranglersSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "manitoba-moose") {
      return (
        <MooseSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "abbotsford-canucks") {
      return (
        <AbbotsfordCanucksSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "vancouver-whitecaps-fc") {
      return (
        <WhitecapsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "toronto-fc") {
      return (
        <TorontoFcsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "belleville-senators") {
      return (
        <BellevilleSenatorsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else if (selectedTeam === "toronto-marlies") {
      return (
        <TorontoMarliesSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    } else {
      return (
        <JetsSectionSelect
          onSectionSelection={onSectionSelection}
          section={section}
        />
      );
    }
  }
}

SectionSelect.propsType = {
  selectedTeam: PropTypes.string.isRequired,
  onSectionSelection: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired
};

export default SectionSelect;
