import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button, Grid, Typography } from '@material-ui/core';
import { API } from "aws-amplify";
import CDialog from '../components/CDialog';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import validator from "validator";
import { get, has, isEmpty, isNil, kebabCase } from 'lodash';

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 3,
  },
  pageForm: {
    marginTop: "2rem"
  },
  pageResultContainer: {
    padding: "1rem"
  },
});

class SellerSpecific extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: undefined,
      searchEmail: "",
      isSaving: false,
      isUpdating: false,
      isSearching: false,
      isSearched: false,
      isSubmitted: false,
      isUpdated: false,
      openEditDialog: false,
      newPage: {
        email: "",
        pageName: "",
        slug: ""
      },
      editPage: {
        email: "",
        pageName: "",
        slug: ""
      },
    };

    this.getUser = this.getUser.bind(this);
    this.handleNewPageName = this.handleNewPageName.bind(this);
    this.handleEditPageName = this.handleEditPageName.bind(this);
  }

  validateInput(input) {
    const { pageName, email } = input;
    return {
      ...(validator.isEmpty(pageName) && { pageName: "Page name is required" }),
      ...(validator.isEmpty(email) && { email: "Email is required" }),
      ...(!validator.isEmpty(email) && !validator.isEmail(email) && { email: "Please enter a valid email address" })
    };
  }

  validateSearch(searchEmail) {
    return {
      ...(validator.isEmpty(searchEmail) && { searchEmail: "Email is required" }),
      ...(!validator.isEmpty(searchEmail) && !validator.isEmail(searchEmail) && { searchEmail: "Please enter a valid email address" })
    };
  }

  handleNewPageName(e) {
    this.setState({
      newPage: {
        ...this.state.newPage,
        pageName: e.target.value,
        slug: kebabCase(e.target.value)
      }
    });
  }

  handleEditPageName(e) {
    this.setState({
      editPage: {
        ...this.state.editPage,
        pageName: e.target.value,
        slug: kebabCase(e.target.value)
      }
    });
  }

  getUser = (e) => {
    e.preventDefault();
    const { searchEmail } = this.state;

    this.setState({ isSearched: true });
    if (!isEmpty(this.validateSearch(searchEmail))) {
      return;
    }

    this.setState({ isSearching: true });
    API.get("v2", `marketplace/users/by/email/${searchEmail}`)
      .then(response => {
        if (response.user && response.user.sellerSlug) {
          this.setState({ user: response.user });
        } else {
          CDialog.warning("Page not found", `There is no seller specific page associated with email ${searchEmail}`)
        }
        this.setState({
          isSearching: false,
          isSearched: false
        });
      }).catch(error => {
        CDialog.error("Error", "Internal Server Error");
        this.setState({ isSearching: false });
      })
  }

  updatePage = () => {
    const { editPage } = this.state;
    const { currUser } = this.props;

    this.setState({ isUpdated: true });
    if (!isEmpty(this.validateInput(editPage))) {
      return;
    }
    this.setState({ isUpdating: true });

    API.post("v2", "marketplace/users/update-page", {
      headers: { email: currUser.email },
      body: editPage
    })
      .then(response => {
        this.setState({
          isUpdating: false,
          isUpdated: false,
          user: response.user
        });
        this.closeEditDialog();
        CDialog.success("Success", "Seller page has been updated");
      }).catch(error => {
        this.setState({ isUpdating: false });
        if (error.response) {
          const { data } = error.response;
          CDialog.error("Error", data.message);
        } else {
          CDialog.error("Error", "Internal Server Error");
        }
      });
  }

  createPage = (e) => {
    e.preventDefault();
    const { newPage } = this.state;
    const { currUser } = this.props;

    this.setState({ isSubmitted: true });
    if (!isEmpty(this.validateInput(newPage))) {
      return;
    }
    this.setState({ isSaving: true });

    API.post("v2", "marketplace/users/create-page", {
      headers: { email: currUser.email },
      body: newPage
    })
      .then(response => {
        this.setState({
          isSaving: false,
          isSubmitted: false,
          user: response.user,
          newPage: {
            email: "",
            pageName: "",
            slug: ""
          }
        });
        CDialog.success("Success", "Seller specific page has been created");
      }).catch(error => {
        this.setState({ isSaving: false });
        if (error.response) {
          const { data } = error.response;
          CDialog.warning("Warning", data.message);
          this.setState({ user: data.user });
        } else {
          CDialog.error("Error", "Internal Server Error");
        }
      });
  }

  openEditDialog(user) {
    this.setState({
      editPage: {
        pageName: get(user, "sellerPageName", ""),
        slug: get(user, "sellerSlug", ""),
        email: user.email,
      },
      openEditDialog: true
    });
  }

  closeEditDialog() {
    this.setState({
      editPage: {
        pageName: "",
        slug: "",
        email: "",
      },
      openEditDialog: false,
      isSubmitted: false,
    });
  }

  renderEditDialog() {
    const { openEditDialog, editPage, isUpdating, isUpdated } = this.state;
    const validated = this.validateInput(editPage);
    return (
      <Dialog open={openEditDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Edit Seller Page</DialogTitle>
        <DialogContent>
          <Typography variant="caption">Email Address</Typography>
          <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
            {editPage.email}
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Page Name"
            type="text"
            value={editPage.pageName}
            onChange={this.handleEditPageName}
            error={isUpdated && has(validated, 'pageName')}
            helperText={isUpdated && get(validated, 'pageName')}
            fullWidth
          />
          {editPage.slug.length > 0 && (
            <Typography variant="body1">
              https://www.fansfirst.ca/{editPage.slug}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.closeEditDialog()} color="primary" disabled={isUpdating}>
            Cancel
          </Button>
          <Button onClick={() => this.updatePage()} color="primary" variant="contained" disabled={isUpdating}>
            {isUpdating ? 'Saving' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  render() {
    const { classes } = this.props;
    const { user, searchEmail, isSearching, isSearched, isSaving, newPage, isSubmitted } = this.state;
    const validated = this.validateInput(newPage);
    const searchValidated = this.validateSearch(searchEmail);
    return (
      <React.Fragment>
        <Typography variant="display2">Seller Specific</Typography>
        <Grid container spacing={40}>
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <form onSubmit={this.createPage} className={classes.pageForm}>
              <Grid container spacing={8} justify="center">
                <Grid item xs={4} md={3}>
                  <Typography variant="body1">Create New Page</Typography>
                </Grid>
                <Grid item xs={6} md={5}>
                  <TextField
                    fullWidth={true}
                    name="email"
                    label="Seller Email *"
                    onChange={e => this.setState({ newPage: { ...newPage, email: e.target.value.toLowerCase() } })}
                    value={newPage.email}
                    disabled={isSaving}
                    error={isSubmitted && has(validated, 'email')}
                    helperText={isSubmitted && get(validated, 'email')}
                  ></TextField>
                  <TextField
                    fullWidth={true}
                    name="pageName"
                    label="Page Name *"
                    onChange={this.handleNewPageName}
                    value={newPage.pageName}
                    disabled={isSaving}
                    error={isSubmitted && has(validated, 'pageName')}
                    helperText={isSubmitted && get(validated, 'pageName')}
                    margin="normal"
                  ></TextField>
                  {newPage.slug.length > 0 && (
                    <Typography variant="body1">
                      https://www.fansfirst.ca/{newPage.slug}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={2} style={{ alignContent: 'flex-end', paddingBottom: '.7rem' }}>
                  <Button type="submit" color="primary" variant="contained" disabled={isSaving || isSearching}>
                    {isSaving ? 'Saving' : 'Enter'}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <form onSubmit={this.getUser} className={classes.pageForm}>
              <Grid container spacing={8} justify="center">
                <Grid item xs={4} md={3}>
                  <Typography variant="body1">Edit Existing Page</Typography>
                </Grid>
                <Grid item xs={6} md={5}>
                  <TextField
                    id="email"
                    fullWidth={true}
                    name="email"
                    label="Seller Email *"
                    onChange={e => this.setState({ searchEmail: e.target.value })}
                    value={searchEmail}
                    disabled={isSearching}
                    error={isSearched && has(searchValidated, 'searchEmail')}
                    helperText={isSearched && get(searchValidated, 'searchEmail')}
                  ></TextField>
                </Grid>
                <Grid item xs={2} style={{ alignContent: 'flex-end' }}>
                  <Button type="submit" color="primary" variant="contained" disabled={isSearching || isSaving}>
                    {isSearching ? 'Searching' : 'Search'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} sm={10} md={4}>
            {!isNil(user) && (
              <Paper className={classes.pageResultContainer} elevation={1}>
                <Typography variant="caption">Name</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {user.name ? user.name : '-'}
                </Typography>
                <Typography variant="caption">Email Address</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {user.email}
                </Typography>
                <Typography variant="caption">Mobile Number</Typography>
                <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                  {user.phone_number ? user.phone_number : '-'}
                </Typography>
                <Typography variant="caption">Web Page Name</Typography>
                {user.sellerSlug ? (
                  <>
                    <Typography>{user.sellerPageName ? user.sellerPageName : '-'}</Typography>
                    <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
                      https://www.fansfirst.ca/{user.sellerSlug}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body1" style={{ marginBottom: ".5rem" }}>-</Typography>
                )}
                <div style={{ marginTop: '5px' }}>
                  <Button variant="contained" color="primary" onClick={() => this.openEditDialog(user)}>
                    Edit Profile
                  </Button>
                </div>
              </Paper>
            )}
          </Grid>
        </Grid>
        {this.renderEditDialog()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SellerSpecific);
