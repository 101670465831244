import React, { Component } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { CircularProgress } from "@material-ui/core";

class DialogLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
  }

  componentWillUnmount() {
    this.setState({ open: false });
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogContent>
          <CircularProgress />
          <DialogContentText>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
  }
}

export default DialogLoading;