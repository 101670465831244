import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { API } from "aws-amplify";
import CDialog from '../components/CDialog';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { find, isEmpty, isEqual, isNil, omitBy, startCase } from 'lodash';
import NumberFormat from 'react-number-format';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  loadMoreBtns: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    bottom: 0,
  },
  loadMoreBtn: {
    margin: "1rem",
    marginLeft: 0,
  }
});

function MobileNoCustomInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            name: "value",
          },
        });
      }}
      format="(###) ### ####"
      thousandSeparator=""
      prefix=""
    />
  );
}

class BlockedUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blocked_users: [],
      lastEvaluatedKey: null,
      isLoading: false,
      isLoadingMore: false,
      isSubmitting: false,
      isSubmitted: false,
      openDialog: false,
      input: {
        name: "",
        value: ""
      },
      filter: {
        name: "",
        search: ""
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.search = this.search.bind(this);
  }

  option() {
    return ['email', 'mobile_number', 'ip_address'];
  }

  loadData = (params = {}) => {
    const queryString = !isEmpty(params) ? `&${new URLSearchParams(params).toString()}` : "";
    this.setState({ isLoading: true });
    API.get("v2", `marketplace/blockedUsers/all?limit=20${queryString}`)
      .then(response => {
        this.setState({
          blocked_users: response.blocked_users,
          lastEvaluatedKey: response.last_evaluated_key,
          isLoading: false
        });
      }).catch(error => {
        CDialog.error("Error", "Internal Server Error");
        this.setState({ isLoading: false });
      });
  }

  async loadMore() {
    const { blocked_users, lastEvaluatedKey, filter } = this.state;
    const params = omitBy(filter, v => v === "");
    if (lastEvaluatedKey) {
      params["lastEvaluatedKey"] = JSON.stringify(lastEvaluatedKey);
    }
    const queryString = !isEmpty(params) ? `&${new URLSearchParams(params).toString()}` : "";
    this.setState({ isLoadingMore: true });
    API.get("v2", `marketplace/blockedUsers/all?limit=20${queryString}`)
      .then(response => {
        this.setState({
          blocked_users: [...blocked_users, ...response.blocked_users],
          lastEvaluatedKey: response.last_evaluated_key,
          isLoadingMore: false
        });
      }).catch(error => {
        CDialog.error("Error", "Internal Server Error");
        this.setState({ isLoadingMore: false });
      })
  }

  unblock = (blocked) => {
    const { currUser} = this.props;
    CDialog.loading(true);
    API.post("v2", "marketplace/blockedUsers/delete", {
      headers: { email: currUser.email },
      body: blocked
    })
      .then(response => {
        const newBlockedUsers = this.state.blocked_users.filter(item => !isEqual(item, blocked));
        this.setState({
          blocked_users: newBlockedUsers,
        });
        CDialog.loading(false);
        CDialog.success("Success", "Successfully unblocked");
      }).catch(error => {
        CDialog.loading(false);
        CDialog.error("Error", "Internal Server Error");
      });
  }

  confirmUnblock(blocked) {
    CDialog.confirm("Confirm", `Unblock ${blocked.name.replace("_", " ")} ${blocked.value}?`)
      .then(confirm => {
        if (confirm) {
          this.unblock(blocked);
        }
      });
  }

  componentDidMount() {
    this.loadData();
  }

  openDialog() {
    this.setState({ openDialog: true });
  }

  closeDialog() {
    this.setState({
      input: {
        name: "",
        value: ""
      },
      isSubmitted: false,
      openDialog: false
    });
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        [event.target.name]: event.target.value
      },
    });
  }

  handleNameChange(event) {
    this.setState({
      input: {
        name: event.target.value,
        value: ""
      },
    });
  }

  handleValueChange(event) {
    this.setState({
      input: {
        ...this.state.input,
        value: event.target.value
      },
    });
  }

  async search(e) {
    e.preventDefault();
    const params = omitBy(this.state.filter, v => v === "");
    this.loadData(params);
  }

  hasError(name) {
    const { input, isSubmitted } = this.state;
    return isSubmitted && input[name] === "";
  }

  async save() {
    const { input, blocked_users } = this.state;
    const { currUser} = this.props;
    this.setState({ isSubmitted: true });

    if (input.name === "" || input.value === "") {
      return;
    }

    const findExists = find(blocked_users, input);
    if (!isNil(findExists)) {
      CDialog.warning("Warning", `The ${findExists.name.replace("_", " ")} ${findExists.value} is already blocked.`);
      return;
    }

    this.setState({ isSubmitting: true });

    let value = input.value;
    if (input.name === "mobile_number") {
      const mobileNo = input.value.replace(/\D/g, "");
      value = "+1" + mobileNo;
    }

    API.post("v2", "marketplace/blockedUsers/create", {
      headers: { email: currUser.email },
      body: {
        name: input.name,
        value
      }
    })
      .then(response => {
        this.setState({
          isSubmitting: false,
          isSubmitted: false,
          blocked_users: [response, ...blocked_users]
        });
        this.closeDialog();
        CDialog.success("Success", "The user successfully blocked");
      }).catch(error => {
        this.setState({ isSubmitting: false });
        if (!isNil(error.response) && error.response.status === 403) {
          CDialog.warning("Warning", `The ${input.name.replace("_", " ")} ${input.value} is already blocked.`);
        } else {
          CDialog.error("Error", "Internal Server Error.");
        }
      })
  }

  getInputProps() {
    if (this.state.input.name === "mobile_number") {
      return {
        startAdornment: (
          <span style={{ fontSize: ".875rem", marginRight: ".5rem" }}>
            +1
          </span>
        ),
        inputComponent: MobileNoCustomInput
      }
    }
    return undefined;
  }

  renderData() {
    const { blocked_users } = this.state;
    if (blocked_users.length > 0) {
      return (
        blocked_users.map((blocked, index) => (
          <TableRow key={index}>
            <TableCell component="th" scope="row">
              {startCase(blocked.name)}
            </TableCell>
            <TableCell>
              {blocked.value}
            </TableCell>
            <TableCell>
              <Button variant="contained" color="secondary" onClick={() => this.confirmUnblock(blocked)}>
                Unblock
              </Button>
            </TableCell>
          </TableRow>
        ))
      )
    } else {
      return (
        <TableRow>
          <TableCell align="center" colSpan="3">
            <Typography variant="subheading" style={{ 'textAlign': 'center' }}>There is no data</Typography>
          </TableCell>
        </TableRow>
      )
    }
  }

  render() {
    const { classes } = this.props;
    const { input, filter, lastEvaluatedKey, isLoading, isLoadingMore } = this.state;
    return (
      <React.Fragment>
        <Typography variant="display2">Blocked Users</Typography>
        <Grid container>
          <Grid item sm={12} md={10} lg={9}>
            <Grid container justify="space-between" alignItems="center" spacing={24}>
              <Grid item>
                <form autoComplete="off" onSubmit={this.search}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="name-filter">
                          Name
                        </InputLabel>
                        <Select
                          value={filter.name}
                          onChange={this.handleChange}
                          input={<Input name="name" id="name-filter" />}
                          displayEmpty
                          name="name"
                          className={classes.selectEmpty}
                        >
                          <MenuItem value="">All</MenuItem>
                          {this.option().map(item => (
                            <MenuItem key={item} value={item}>{startCase(item)}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <TextField
                        label="Search"
                        name="search"
                        value={filter.search}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit" disabled={isLoading || isLoadingMore}>
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => this.openDialog()} disabled={isLoading || isLoadingMore}>
                  Add
                </Button>
              </Grid>
            </Grid>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Blocked By</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.isLoading ? (
                    <TableRow>
                      <TableCell align="center" colSpan="3">
                        <Typography variant="subheading" style={{ 'textAlign': 'center' }}>Loading...</Typography>
                      </TableCell>
                    </TableRow>
                  ) : this.renderData()}
                </TableBody>
              </Table>
              {lastEvaluatedKey && !isLoading && (
                <div className={classes.loadMoreBtns}>
                  <Button
                    onClick={() => this.loadMore()}
                    disabled={isLoadingMore}
                    variant="contained"
                    color="secondary"
                    className={classes.loadMoreBtn}
                  >
                    {isLoadingMore ? "Loading..." : "Load 20 More"}
                  </Button>
                </div>
              )}
            </Paper>
          </Grid>
        </Grid>

        <Dialog open={this.state.openDialog} onClose={() => { }} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
          <DialogTitle id="form-dialog-title">Add New</DialogTitle>
          <DialogContent>
            <DialogContentText>
            </DialogContentText>
            <FormControl fullWidth error={this.hasError('name')}>
              <InputLabel shrink htmlFor="input-name">Blocked By</InputLabel>
              <Select
                value={input.name}
                onChange={this.handleNameChange}
                inputProps={{
                  id: 'input-name',
                }}
                displayEmpty
                name="name"
              >
                <MenuItem value="">Choose</MenuItem>
                {this.option().map(item => (
                  <MenuItem key={item} value={item}>{startCase(item)}</MenuItem>
                ))}
              </Select>
              {this.hasError('name') && <FormHelperText>This field is required</FormHelperText>}
            </FormControl>
            <TextField
              error={this.hasError('value')}
              margin="dense"
              id="input-value"
              name="value"
              label="Value"
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              value={input.value}
              onChange={this.handleValueChange}
              fullWidth
              helperText={this.hasError('value') ? "This field is required" : ""}
              InputProps={this.getInputProps()}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeDialog()} color="primary" disabled={this.state.isSubmitting}>
              Cancel
            </Button>
            <Button onClick={() => this.save()} color="primary" variant="contained" disabled={this.state.isSubmitting}>
              {this.state.isSubmitting ? 'Loading...' : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(BlockedUsers);
