import React from 'react';
import { Typography } from '@material-ui/core';

const CarrierDetails = ({ mobileNumberDetails }) => {
  if (!mobileNumberDetails) {
    return null;
  } else if (!mobileNumberDetails.constructor === Object) {
    return null;
  } else if (!mobileNumberDetails.current_carrier) {
    return null;
  } else if (!mobileNumberDetails.current_carrier.constructor === Object) {
    return null;
  } else {
    return (
      <div>
        <Typography variant="body2">Carrier Details</Typography>
        <Typography variant="body1">
          {mobileNumberDetails.current_carrier.name}
        </Typography>
      </div>
    );
  }
}

export default CarrierDetails;