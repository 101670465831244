import React, { Component } from "react";
import { FormControl, withStyles } from "@material-ui/core";
import { API } from "aws-amplify";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

const styles = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "1em",
  },
});

class TicketEvoInventoryTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTeamId: "",
      selectedGameId: "",
      selectedGameName: "",
      teamSelected: false,
      gameSelected: false,
      games: null,
      internalGames: [],
      totalListings: 0,
      listings: null,
      filter: false,
      isLoading: false,
      findGameXTE: null,
    };
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.handleGameChange = this.handleGameChange.bind(this);
  }

  getTeamId = (teamSlug) => {
    switch (teamSlug) {
      case "calgary-flames": {
        return 16415;
      }

      case "edmonton-oilers": {
        return 16417;
      }

      case "vancouver-canucks": {
        return 16419;
      }

      case "winnipeg-jets": {
        return 22681;
      }

      case "toronto-maple-leafs": {
        return 16404;
      }

      case "ottawa-senators": {
        return 16403;
      }

      case "toronto-raptors": {
        return 16305;
      }

      case "edmonton-elks": {
        return 78171;
      }
  
      case "winnipeg-blue-bombers": {
        return 16300;
      }
  
      case "toronto-argonauts": {
        return 16295;
      }
  
      case "calgary-stampeders": {
        return 16297;
      }
  
      case "ottawa-redblacks": {
        return 41497;
      }
  
      case "bc-lions": {
        return 30587;
      }

      case "saskatchewan-roughriders": {
        return 30587;
      }

      default: {
        return -1;
      }
    }
  };

  async handleTeamChange(e) {
    const teamId = this.getTeamId(e.target.value);
    this.setState({
      selectedTeamId: e.target.value,
      selectedGameId: "",
      teamSelected: false,
      gameSelected: false,
      isLoading: true,
    });

    if (teamId === "") {
      return;
    }

    API.get(
      "v2",
      `marketplace/ticketEvo/get/game?homeTeamSlug=${e.target.value}&includeMinPrices=true`
    )
      .then((data) => this.setState({ internalGames: data }))
      .catch((e) => alert(e));
    const { events } = await API.get(
      "v2",
      `marketplace/ticketEvo/events/index/by/performerId/${teamId}`
    ).catch(() =>
      this.setState({
        ...this.state,
        isLoading: false,
      })
    );
    this.setState({
      teamSelected: true,
      games: events,
      isLoading: false,
    });
  }

  async handleGameChange(e) {
    const { internalGames } = this.state;
    const game = JSON.parse(e.target.value);
    const gameDate = new Date(game.occurs_at_local).toISOString().split("T")[0];
    const findGameXTE = internalGames.find((gameInternal) => {
      const gameInternalDate = new Date(gameInternal.date)
        .toISOString()
        .split("T")[0];
      return gameDate === gameInternalDate;
    });

    this.setState({
      selectedGameId: e.target.value,
      gameSelected: false,
      isLoading: true,
      findGameXTE,
    });
    if (game.id === "") {
      return;
    }

    const { total_entries, ticket_groups } = await API.get(
      "v2",
      `marketplace/ticketEvo/listings/index/by/eventId/${game.id}`
    ).catch(() => this.setState({ ...this.state, isLoading: false }));
    this.setState({
      listings: ticket_groups.slice(0, 10),
      totalListings: total_entries,
      gameSelected: true,
      isLoading: false,
    });
  }

  getTicketType(format) {
    switch (format) {
      case "TM_mobile":
        return "TMMobile";
      case "Flash_seats":
        return "FlashSeats";
      default:
        return format;
    }
  }

  renderForm() {
    const { selectedTeamId, selectedGameId, teamSelected, games } = this.state;

    return (
      <React.Fragment>
        <form>
          <FormControl
            style={{
              minWidth: "175px",
              marginRight: "1.5rem",
              marginBottom: "1rem",
            }}
          >
            <InputLabel id="team-selector">Team</InputLabel>
            <Select
              id="team-selector"
              value={selectedTeamId}
              label="team"
              onChange={this.handleTeamChange}
              style={{ marginBottom: "1rem", marginRight: "1rem" }}
            >
              <MenuItem value={""}></MenuItem>
              <MenuItem value={"calgary-flames"}>Calgary Flames</MenuItem>
              <MenuItem value={"edmonton-oilers"}>Edmonton Oilers</MenuItem>
              <MenuItem value={"vancouver-canucks"}>Vancouver Canucks</MenuItem>
              <MenuItem value={"winnipeg-jets"}>Winnipeg Jets</MenuItem>
              <MenuItem value={"ottawa-senators"}>Ottawa Senators</MenuItem>
              <MenuItem value={"toronto-maple-leafs"}>Toronto Maple Leafs</MenuItem>
              <MenuItem value={"toronto-raptors"}>Toronto Raptors</MenuItem>
              <MenuItem value={"calgary-stampeders"}>Calgary Stampeders</MenuItem>
              <MenuItem value={"edmonton-elks"}>
                Edmonton Elks
              </MenuItem>
              <MenuItem value={"toronto-blue-bombers"}>
                Toronto Blue Bombers
              </MenuItem>
              <MenuItem value={"bc-lions"}>
                BC Lions
              </MenuItem>
              <MenuItem value={"toronto-argonauts"}>
                Toronto Argonauts
              </MenuItem>
              <MenuItem value={"ottawa-redblacks"}>
                Ottawa Redblacks
              </MenuItem>
              <MenuItem value={"saskatchewan-roughriders"}>
                Saskatchewan Roughriders
              </MenuItem>
              <MenuItem value={"hamilton-tigercats"}>
                Hamilton Tiger-Cats
              </MenuItem>
              <MenuItem value={"grey-cup"}>
                Grey Cup
              </MenuItem>
            </Select>
          </FormControl>
          {teamSelected ? (
            <FormControl
              style={{
                minWidth: "300px",
                marginRight: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              <InputLabel id="game-selector">Game</InputLabel>
              <Select
                id="game-selector"
                value={selectedGameId}
                label="game"
                onChange={this.handleGameChange}
                style={{ marginBottom: "1rem", marginRight: "1rem" }}
              >
                <MenuItem value={""}></MenuItem>
                {games.map((game) => (
                  <MenuItem value={JSON.stringify(game)} key={game.id}>
                    {game.name} - {game.occurs_at.split("T")[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </form>
      </React.Fragment>
    );
  }

  renderListings() {
    const { listings } = this.state;
    return (
      <React.Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Game</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Row</TableCell>
              <TableCell>Quantities Available</TableCell>
              <TableCell>Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listings.length > 0 &&
              listings.map((listing, key) => this.renderRow(listing, key))}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  _mockingData = () => {
    // const { findGameXTE, listings } = this.state;
    // let seatList = [];
    let seatList = [
      {
        game: "794449cc-fe6f-46e5-801c-bdf4a5f354e2",
        gameName:
          "Philadelphia 76ers at Toronto Raptors - Oct 28, 2022 07:30 PM",
        gameDate: 1666999800000,
        gameTimezone: "America/Toronto",
        isAisleSeat: true,
        isArchived: false,
        isPlayoffs: false,
        isSold: false,
        noOfSeats: 2,
        price: 240,
        row: "25",
        seatNumberEnd: 0,
        seatNumberStart: 0,
        seatsSold: 0,
        sellerEmail: "rcholdings@shaw.ca",
        sellerFullName: "RC Holdings",
        sellerId: "6220bfaa-df75-4afb-83a7-44785fb27a54",
        sellerMobileNo: "'+17809512025",
        sellOption: 2,
        zone: "Lower Zone",
        zoneNo: 112,
      },
    ];
    // listings.length > 0 && listings.forEach(seat => {
    //   const seatItem = {
    //     "game": findGameXTE.id,
    //     "gameName": findGameXTE.longName,
    //     "gameDate": findGameXTE.date,
    //     "gameTimezone": findGameXTE.timezone,
    //     "isPlayoffs": findGameXTE.isPlayoffs,
    //     "price": Number(seat.retail_price),
    //     "zone": seat.tevo_section_name,
    //     "zoneNo": Number(seat.section),
    //     "row": Number(seat.row),
    //     "noOfSeats": Number(seat.available_quantity),
    //     "sellerEmail": "te@fansfirst.ca",
    //     "sellOption": 2,
    //     "isAisleSeat": false,
    //     "seatNumberEnd": 0,
    //     "seatNumberStart": 0
    //   };
    //   seatList.push(seatItem);
    // });
    return {
      seats: seatList,
      user: "rcholdings@shaw.ca",
    };
  };

  _handleStoreListings = async () => {
    // const { findGameXTE } = this.state;
    const dataMock = this._mockingData();
    API.post("v2", "marketplace/ticketEvo/create/listings", {
      body: dataMock,
    }).catch((e) => alert(e));
  };

  renderRow(listing, key) {
    const { filter } = this.state;
    if (filter) {
      if (isNaN(listing.row) || isNaN(listing.section)) {
        return null;
      }
    }

    return (
      <TableRow>
        <TableCell>{key + 1}</TableCell>
        <TableCell>{listing.event ? listing.event.name : "-"}</TableCell>
        <TableCell>{listing.public_notes}</TableCell>
        <TableCell>{this.getTicketType(listing.format)}</TableCell>
        <TableCell>{listing.section}</TableCell>
        <TableCell>{listing.row}</TableCell>
        <TableCell>
          {listing.splits.map((quantity, key) => (
            <span key={`${quantity}${key}`}>{quantity} </span>
          ))}
        </TableCell>
        <TableCell>${listing.retail_price}</TableCell>
      </TableRow>
    );
  }

  render() {
    const { classes } = this.props;
    const { gameSelected, isLoading } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.header}>{this.renderForm()}</div>
        {isLoading ? "Loading game data..." : ""}
        <br></br>
        <br></br>
        {gameSelected && this.renderListings()}
      </div>
    );
  }
}

export default withStyles(styles)(TicketEvoInventoryTop);
