import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField
} from '@material-ui/core';
import { Add, Clear } from '@material-ui/icons';
import { isEmpty } from 'lodash';

class EditNoteForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { order } = this.props;
    const prevOrderId = prevProps.order ? prevProps.order.id : null;
    const currentOrderId = order ? order.id : null;
    if (currentOrderId !== prevOrderId) {
      this.setState({ notes: order ? order.notes : [] });
    }
  }

  handleChange(value, index) {
    const newNotes = this.state.notes.map((note, i) => {
      if (i === index) {
        return value;
      }
      return note;
    });
    this.setState({ notes: newNotes });
  }

  deleteNote(index) {
    const newNotes = this.state.notes.filter((note, i) => i !== index);
    this.setState({ notes: newNotes });
  }

  addNote() {
    this.setState({ notes: [...this.state.notes, ""] });
  }

  save() {
    const { order } = this.props;
    const notes = this.state.notes.filter(note => !isEmpty(note));
    this.props.saveNotes(order.id, notes);
  }

  render() {
    const {
      isDialogOpen,
      order,
      isSubmitting,
      closeDialog
    } = this.props;
    const { notes } = this.state;

    return (
      <Dialog
        fullWidth={true}
        open={isDialogOpen}
        onClose={() => closeDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Notes</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "2rem" }}>
            Notes for {order && order.description} by{" "}
            {order && order.name}
          </DialogContentText>
          <InputLabel shrink>Notes:</InputLabel>
          {notes.map((note, index) => (
            <TextField
              key={index}
              type="text"
              onChange={(e) => this.handleChange(e.target.value, index)}
              fullWidth
              value={note}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Delete note"
                      title="Delete note"
                      onClick={() => this.deleteNote(index)}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          ))}
          <Button size="small" onClick={() => this.addNote()}>
            <Add fontSize="small" />
            Add Note
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => closeDialog()}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            variant="contained"
            onClick={() => this.save()}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditNoteForm;
