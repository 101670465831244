import React, { Component } from "react";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import { chain, isEmpty, isNil } from "lodash";

import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import FlamesGames from "../constants/games/flames-games.json";
import JetsGames from "../constants/games/jets-games.json";
import TEAMS from "../constants/Teams";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 3,
  },
  teamsList: {
    marginBottom: "1.5rem",
  },
  teamItemWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  teamLogoWrapper: {
    marginBottom: "1.5rem",
    cursor: "pointer",
  },
  teamLogo: {
    height: 50,
    width: 50,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    marginTop: "1rem"
  },
});

const teamColors = {
  "calgary-flames": "#ce1126",
  "edmonton-oilers": "#fc4c02",
  "winnipeg-jets": "#041e42",
  "vancouver-canucks": "#008852",
  "toronto-maple-leafs": "#001b5a",
  "ottawa-senators": "#b79257",
  "toronto-raptors": "#7637bf",
  "toronto-blue-jays": "#002d5f",
  "calgary-stampeders": "#d60000",
  "edmonton-elks": "#2B5134",
  "winnipeg-blue-bombers": "#1D3D7A",
  "bc-lions": "#F15623",
  "toronto-argonauts": "#5F8FB1",
  "ottawa-redblacks": "#AC1F2D",
  "saskatchewan-roughriders": "#00623f",
  "hamilton-tigercats": "#FFB819",
  "grey-cup": "#526D82",
  "calgary-wranglers": "#C2273D",
  "manitoba-moose": "#004c97",
  "vancouver-whitecaps-fc": "#9DC2EA",
  "toronto-fc": "#B81137"
};

class MinPricePerGame extends Component {
  constructor(props) {
    super(props);

    const firstGame = chain([...FlamesGames, ...JetsGames])
      .sortBy("date")
      .filter((game) => game.date >= moment().utcOffset(-7).valueOf())
      .value()[0];

    this.state = {
      selectedGame: null,
      games: null,
      filteredGames: null,
      selectedTeam: firstGame ? firstGame.homeTeamSlug : null,
      isLoading: false,
      homeTeamSlug: ""
    };

    this.handleTeamChange = this.handleTeamChange.bind(this);
  }

  async getMinPrices(homeTeamSlug) {
    const params = isEmpty(homeTeamSlug) ? "" : `homeTeamSlug=${homeTeamSlug}`;
    const events = await API.get("v2", `marketplace/events/stats?${params}`);
    // sort events array asc by event.date
    // exclude events where the homeTeamSlug is toronto-blue-jays
    const filteredEvents = events.filter(
      (event) => event.homeTeamSlug !== "toronto-blue-jays"
    );
    const regularGames = chain(filteredEvents)
      .filter((event) => !event.isTbd)
      .sortBy("date")
      .value();
    const tbdGames = chain(filteredEvents)
      .filter((event) => event.isTbd)
      .sortBy("playoffSequence");

    return [...regularGames, ...tbdGames];
  }

  async handleTeamChange(e) {
    this.setState({
      isLoading: true,
      homeTeamSlug: e.target.value
    });
    const games = await this.getMinPrices(e.target.value);
    this.setState({
      isLoading: false,
      games,
    });
  }

  renderStats({ stats }) {
    return (
      <React.Fragment>
        <Grid item md={3}>
          <Typography
            variant="body2"
            color={
              stats.tier_3.priceDifference <= -0.1 ? "secondary" : "textPrimary"
            }
          >
            {stats.tier_3.lowestPrice ? (
              <span>
                $ {stats.tier_3.lowestPrice.toFixed(2)}{" "}
                {(stats.tier_3.priceDifference * 100).toFixed(2)}%
              </span>
            ) : (
              "-"
            )}
          </Typography>
          <Typography variant="body1">
            {stats.tier_3.lowestPrice ? stats.tier_3.sellerEmail : "-"}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography
            variant="body2"
            color={
              stats.tier_2.priceDifference <= -0.1 ? "secondary" : "textPrimary"
            }
          >
            {stats.tier_2.lowestPrice ? (
              <span>
                $ {stats.tier_2.lowestPrice.toFixed(2)}{" "}
                {(stats.tier_2.priceDifference * 100).toFixed(2)}%
              </span>
            ) : (
              "-"
            )}
          </Typography>
          <Typography variant="body1">
            {stats.tier_2.lowestPrice ? stats.tier_2.sellerEmail : "-"}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography
            variant="body2"
            color={
              stats.tier_3.priceDifference <= -0.1 ? "secondary" : "textPrimary"
            }
          >
            {stats.tier_1.lowestPrice ? (
              <span>
                $ {stats.tier_1.lowestPrice.toFixed(2)}{" "}
                {(stats.tier_1.priceDifference * 100).toFixed(2)}%
              </span>
            ) : (
              "-"
            )}
          </Typography>
          <Typography variant="body1">
            {stats.tier_1.lowestPrice ? stats.tier_1.sellerEmail : "-"}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }

  renderGames() {
    const { games } = this.state;

    if (isNil(games)) {
      return null;
    } else if (games.length === 0) {
      return (
        <Typography variant="body2">
          No data available
        </Typography>
      )
    }

    return (
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid
          item
          md={3}
          style={{
            paddingBottom: ".5rem",
            marginBottom: ".5rem",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Upper Zone
        </Grid>
        <Grid
          item
          md={3}
          style={{
            paddingBottom: ".5rem",
            marginBottom: ".5rem",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Middle Zone
        </Grid>
        <Grid
          item
          md={3}
          style={{
            paddingBottom: ".5rem",
            marginBottom: ".5rem",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Lower Zone
        </Grid>
        {games.map((game) => (
          <Grid key={game.id} item xs={12}>
            <Grid
              container
              style={{ paddingBottom: ".5rem", marginBottom: ".5rem" }}
            >
              <Grid
                item
                md={3}
                style={{ color: teamColors[game.homeTeamSlug] }}
              >
                {game.longName}
                <br />
                {moment.tz(game.date, game.timezone).format("MMM DD h:mm A")}
              </Grid>
              {this.renderStats(game)}
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    const { games, isLoading, homeTeamSlug } = this.state;

    return (
      <div className={classes.root} id="MinPricePerGame">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="display2">Min Price Per Game</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel>Select Team</InputLabel>
              <Select
                id="homeTeamSlug"
                name="homeTeamSlug"
                value={homeTeamSlug}
                onChange={this.handleTeamChange}
                disabled={isLoading}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {TEAMS.map(team => (
                  <MenuItem key={team.homeTeamSlug} value={team.homeTeamSlug}>
                    {team.fullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "2rem" }}>
            {isLoading ? (
              <Typography variant="body2">
                Loading min prices... Please wait...
              </Typography>
            ) : this.renderGames()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(MinPricePerGame);
