import React from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@material-ui/core';
import { getTeamAbbreviation, getTeamName } from '../../libs/shared/helpers';
import moment from 'moment';
import OrderDetails from './OrderDetails';

const TransferForm = ({
  order,
  isTransferReportOpen,
  numberPrevOrder,
  isLoadingPrevTrx,
  isLoading,
  allowAction,
  classes,
  sendTransfer,
  voidTransfer,
  handleTransferFormOpenClose,
  unverifyBuyerEmail,
  verifyBuyerEmail
}) => {
  if (order) {
    if (!order.seat) {
      return null;
    }
    const { game } = order;
    let gameName = "-";
    let gameDate = "-";

    if (game) {
      gameName = `${game.opponent} at ${getTeamName(
        game.homeTeamSlug
      )}`;

      gameDate = game.isTbd
        ? "TBD"
        : moment
          .tz(order.game.date, order.game.timezone)
          .format("MM/DD/YYYY");
    }
    return (
      <Dialog
        open={isTransferReportOpen}
        onClose={handleTransferFormOpenClose}
        fullWidth={"lg"}
        maxWidth={"lg"}
        classes={{ paper: classes.transferDialog }}
      >
        <DialogTitle style={{ textAlign: "left" }}>
          Transfer Details
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ height: "90%" }}>
            <Grid container style={{ height: "100%" }}>
              <Grid item xs={4}>
                <h3
                  style={{
                    textAlign: "left",
                    paddingLeft: "0px",
                  }}
                >
                  Order Details
                </h3>
                <Typography variant="body1">
                  Team {getTeamAbbreviation(game.homeTeamSlug)}
                </Typography>
                <Typography variant="body1">
                  Date {moment(order.createdAt).format("MMM DD,YYYY")}
                </Typography>
                <Typography variant="body1">
                  Name {order.name}
                </Typography>
                <Typography variant="body1">
                  Email {order.email}
                </Typography>
                <Typography variant="body1">Game {gameName}</Typography>
                <Typography variant="body1">Game Date: {gameDate}</Typography>
                <Typography variant="body1">
                  Seat:{" "}
                  {[
                    "Sportsnet",
                    "Sky Lounge",
                    "Press Level",
                    "Loge",
                    "REST",
                  ].some((x) => order.seat.zone.includes(x))
                    ? `${order.seat.zone} ${order.seat.zoneNo
                      ? order.seat.zoneNo
                      : ""
                    }`
                    : order.seat.zoneNo}{" "}
                  Row {order.seat.row}{" "}
                  {order.isAisleSeat ? "Aisle" : ""}{" "}
                  {order.isInstantDelivery ? "Insta" : ""}
                </Typography>
                <Typography variant="body1">
                  No of Seats: {order.noOfSeats}
                </Typography>
                <Typography variant="body1">
                  Total: CAD${" "}
                  {(
                    order.ticketPrice * order.noOfSeats
                  ).toFixed(2)}
                </Typography>
                <Typography variant="body1">
                  Seller Email:{" "}
                  {order.seat.sellerEmail &&
                    order.seat.sellerEmail !== "-"
                    ? order.seat.sellerEmail
                    : "-"}
                </Typography>
                <h3
                  style={{
                    textAlign: "left",
                    paddingLeft: "0px",
                  }}
                >
                  Buyer Details
                </h3>
                <OrderDetails
                  order={order}
                  numberPrevOrder={numberPrevOrder}
                  isLoadingPrevTrx={isLoadingPrevTrx}
                  isLoading={isLoading}
                  allowAction={allowAction}
                  unverifyBuyerEmail={unverifyBuyerEmail}
                  verifyBuyerEmail={verifyBuyerEmail}
                />
              </Grid>

              <Grid item xs={8}>
                {console.log({ order })}
                <iframe
                  src={
                    order.transfer && order.transfer.id
                      ? `${process.env.REACT_APP_TRANSFERS_URL}/${order.transfer.id}.html`
                      : "https://www.example.com/"
                  }
                  frameborder="0"
                  height="100%"
                  width="100%"
                  style={{ height: "100%" }}
                  title={order.id}
                ></iframe>
              </Grid>
            </Grid>
          </DialogContentText>
          <div className={classes.voidTransferBtnContainer}>
            {order.transfer && order.transfer.sent ? (
              <Button disabled={true} variant="outlined" color="secondary">
                Void
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  console.log({ order });
                  voidTransfer();
                }}
              >
                Void
              </Button>
            )}
            {order.transfer && order.transfer.sent ? (
              <Button disabled={true} variant="contained" color="primary">
                Transfer
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  console.log({ order });
                  sendTransfer();
                }}
              >
                Transfer
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  } else {
    return "";
  }
}

export default TransferForm;