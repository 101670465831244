import React from 'react';
import { Button, Typography } from '@material-ui/core';
import CarrierDetails from './CarrierDetails';
import { green } from '@material-ui/core/colors';

const OrderDetails = ({ order, numberPrevOrder, isLoadingPrevTrx, allowAction, unverifyBuyerEmail, verifyBuyerEmail, isLoading }) => {

  const emailIsDerivationOfName = (email, fullName) => {
    const first = fullName.split(" ")[0];
    const last = fullName.split(" ")[1];

    const includesName = (email, name) => {
      email = email.split("@")[0].toLowerCase();
      name = name.toLowerCase();

      if (name.length <= 2) {
        return email.includes(name);
      }

      for (let i = 0; i < name.length - 2; i++) {
        if (email.includes(name.substring(i, i + 3))) {
          return true;
        }
      }
      return false;
    };

    if (!includesName(email, first) && !includesName(email, last)) {
      return false;
    } else {
      return true;
    }
  }

  const isEmailRedFlag = (email, name) => {
    if (
      email.includes("hotmail") ||
      email.includes("outlook") ||
      email.includes("protonmail") ||
      email.includes("yopmail") ||
      email.includes("yahoo") ||
      email.includes("fastmail") ||
      email.includes("googlemail")
    ) {
      return true;
    }

    if (!emailIsDerivationOfName(email, name)) {
      return true;
    }

    return false;
  }

  const isAvsMatch = (code) => {
    switch (code) {
      case "D":
      case "M":
      case "P":
      case "W":
      case "X":
      case "Y":
      case "Z": {
        return true;
      }

      default:
        return false;
    }
  }

  const { source, card, mobileNumberDetails } = order;

  return (
    <React.Fragment>
      {order.isNewUser ? (
        <Typography
          variant="body2"
          color="secondary"
          style={{ marginBottom: ".5rem" }}
        >
          New User
        </Typography>
      ) : (
        ""
      )}
      {order.ipFlag ? (
        <Typography
          variant="body2"
          color="secondary"
          style={{ marginBottom: ".5rem" }}
        >
          IP address has had a failed transaction before
        </Typography>
      ) : (
        ""
      )}
      {order.failedTrx ? (
        <Typography
          variant="body2"
          color="secondary"
          style={{ marginBottom: ".5rem" }}
        >
          Has {order.failedTrx} failed txns
        </Typography>
      ) : (
        ""
      )}
      <Typography variant="body1">{order.name}</Typography>
      <Typography variant="body1">{order.mobileNo}</Typography>
      <Typography
        variant="body1"
        style={{
          fontWeight: isEmailRedFlag(order.email, order.name)
            ? "bold"
            : "normal",
          color: isEmailRedFlag(order.email, order.name)
            ? "rgb(255, 23, 68)"
            : "rgba(0, 0, 0, 0.87)",
        }}
      >
        {order.email}
      </Typography>
      {order.emailVerified ? (
        <>
          <Typography style={{ color: green[800] }}>
            Verified &#10003;
          </Typography>
          {allowAction && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => unverifyBuyerEmail(order)}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Unverify"}
            </Button>
          )}
        </>
      ) : (
        <>
          <Typography color="secondary">Not Verified &#10005;</Typography>
          {allowAction && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => verifyBuyerEmail(order)}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Verify"}
            </Button>
          )}
        </>
      )}
      <Typography variant="body2" style={{ marginTop: "1rem" }}>
        Number of previous transaction/orders
      </Typography>
      <Typography variant="body1">
        {isLoadingPrevTrx ? "loading..." : numberPrevOrder}
      </Typography>
      {source && (
        <>
          <Typography variant="body2" style={{ marginTop: "1rem" }}>
            Source
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "0rem" }}>
            {source.ip} {source.city ? source.city.replace("-", "") : null}{" "}
            {source.region ? source.region.replace("-", "") : null}{" "}
            {source.country ? source.country.replace("-", "") : null}
          </Typography>{" "}
          <Typography
            variant="body1"
            style={{
              marginBottom: "0rem",
            }}
          >
            ISP: {source.ISP} {source.host}
          </Typography>
          {source.vpn ? (
            <Typography
              variant="body1"
              style={{
                marginBottom: "0rem",
                fontWeight: "bold",
                color: "rgb(255, 23, 68)",
              }}
            >
              VPN
            </Typography>
          ) : null}
          {source.proxy ? (
            <Typography
              variant="body1"
              style={{
                marginBottom: "0rem",
                fontWeight: "bold",
                color: "rgb(255, 23, 68)",
              }}
            >
              Proxy{" "}
            </Typography>
          ) : null}
          {source.bot_status || source.is_crawler ? (
            <Typography
              variant="body1"
              style={{
                marginBottom: "0rem",
                fontWeight: "bold",
                color: "rgb(255, 23, 68)",
              }}
            >
              Potential Bot{" "}
            </Typography>
          ) : null}
          {source.tor ? (
            <Typography
              variant="body1"
              style={{
                marginBottom: "0rem",
                fontWeight: "bold",
                color: "rgb(255, 23, 68)",
              }}
            >
              Tor Browser{" "}
            </Typography>
          ) : null}
          {source.recent_abuse ? (
            <Typography
              variant="body1"
              style={{
                marginBottom: "0rem",
                fontWeight: "bold",
                color: "rgb(255, 23, 68)",
              }}
            >
              Recent IP Abuse{" "}
            </Typography>
          ) : null}
          {source.fraud_score > 0 ? (
            <Typography
              variant="body1"
              style={{
                marginBottom: "0rem",
                fontWeight: "bold",
                color: "rgb(255, 23, 68)",
              }}
            >
              IP Fraud Score: {source.fraud_score}/100
            </Typography>
          ) : null}
          <Typography variant="body2" style={{ marginTop: "1rem" }}>
            Card Details
          </Typography>
          <Typography variant="body1">{card.number}</Typography>
          <Typography variant="body1">
            {card.brand ? card.brand.replace("-", "") : ""}{" "}
            {card.bank ? card.bank.replace("-", "") : ""}{" "}
            {card.city ? card.city.replace("-", "") : ""}{" "}
            {card.country ? card.country.replace("-", "") : ""}
          </Typography>
          <Typography>
            {card.url.replace("-", "")} {card.phone.replace("-", "")}
          </Typography>
          <Typography style={{ marginTop: ".5rem", marginBottom: "1rem" }}>
            Address Verification:{" "}
            <strong
              style={{
                color: isAvsMatch(card.verificationResponse)
                  ? "#4caf50"
                  : "rgb(255, 23, 68)",
              }}
            >
              {isAvsMatch(card.verificationResponse)
                ? "Postal code matches"
                : "Postal code doesn't match"}
            </strong>
          </Typography>
          <CarrierDetails mobileNumberDetails={mobileNumberDetails} />
          <Typography variant="body2" style={{ marginTop: "1rem" }}>
            3DS
          </Typography>
          <Typography variant="body1">
            Challange Appeared: {order.isThreeDS ? "Yes" : "No"}
          </Typography>
          <Typography variant="body1">
            Authentication Value: {order.threedsAuthenticationValue}
          </Typography>
          <Typography variant="body1">
            Message Version: {order.threedsMessageVersion}
          </Typography>
          <Typography variant="body1">
            Server Transaction ID: {order.threedsServerTransID}
          </Typography>
          <Typography variant="body1">
            Transaction Status: {order.threedsTransStatus}
          </Typography>
          <Typography variant="body1">
            Directory Server Transaction ID: {order.threedsDSTransID}
          </Typography>
          <Typography variant="body1">ECI: {order.threedsEci}</Typography>
          <Typography variant="body1">
            Message Type: {order.threedsMessageType}
          </Typography>
        </>
      )}
    </React.Fragment>
  );
}

export default OrderDetails;