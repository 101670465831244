import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class RowSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  componentWillMount() {
    this.setState({
      value: this.props.row,
      section: this.props.section,
    });
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.row !== this.props.row) {
      this.setState({ value: nextProps.row });
    }

    if (nextProps.section !== this.props.section) {
      this.setState({ section: nextProps.section });
    }
  }

  handleSelection = (event) => {
    this.setState({
      value: event.target.value,
    });

    this.props.onRowSelection(event.target.value);
  };

  menus() {
    const arr = [];
    const { value } = this.state;

    for (let i = 1; i <= 29; i++) {
      arr.push(
        <MenuItem selected={value === i} value={i} key={i}>
          Row {i}
        </MenuItem>
      );
    }

    return arr;
  }

  render() {
    const { section } = this.state;
    console.log(section);

    return (
      <FormControl>
        <InputLabel>Row</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: "row",
            id: "row",
          }}
          placeholder="Seat Row"
          fullWidth
        >
          {this.state.section && !this.state.section.includes("Loge") ? (
            this.menus()
          ) : (
            <MenuItem selected={this.state.value === "A"} value={"A"}>
              Row A
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
}

RowSelect.propsType = {
  classes: PropTypes.object.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  row: PropTypes.string.isRequired,
};

export default RowSelect;
