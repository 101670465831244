import React, { Component } from "react";
import { API } from "aws-amplify";

import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { Select, MenuItem} from "@material-ui/core";

const styles = {
  root: {
    flexGrow: 1,
  },
};

class EmergencyMode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "",
      isResetting: false,
      reset: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const status = await this.status();

    this.setState({
      isAuthenticating: false,
      mode: status,
    });
  }

  status = () => {
    return API.get("v2", `marketplace/emergencyMode/get-emergency-mode`);
  };

  changeMode() {
    const { currUser } = this.props;
    return API.post("v2", "marketplace/emergencyMode/set-emergency-mode", {
      headers: { email: currUser.email },
      body: {
        mode: this.state.mode,
      },
    });
  }

  handleChange(e) {
    this.setState({
      mode: e.target.value,
    });
  }

  async onSubmit(e) {
    e.preventDefault();

    if (this.state.mode === "") {
      alert("Please selet Mode");

      return;
    }

    this.setState({
      isResetting: true,
    });

    try {
      await this.changeMode();

      this.setState({
        isResetting: false,
      });

      alert("Mode have been change");
    } catch ({ response }) {
      const { data } = response;

      this.setState({
        isResetting: false,
      });

      console.log(data.message);
      alert(data.message);
    }
  }

  renderForm() {
    const { isResetting } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Select
          disabled={isResetting}
          fullWidth={true}
          name="mode"
          value={this.state.mode}
          placeholder="Mode"
          onChange={this.handleChange}
          style={{ display: "block", marginBottom: "1rem" }}
        >
          <MenuItem value=""></MenuItem>
          <MenuItem value="False">No</MenuItem>
          <MenuItem value="True">Yes</MenuItem>
        </Select>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          disabled={isResetting || this.state.mode === ""}
        >
          Change Mode
        </Button>
      </form>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root} id="Seats">
        <Grid container>
          <Grid item xs={4}>
            <Typography
              style={{ marginTop: "1.5rem", marginBottom: "3rem" }}
              variant="display2"
            >
              Emergency Mode
            </Typography>
            {this.renderForm()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(EmergencyMode);
