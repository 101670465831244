  const defaultRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 84; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };
  
  const lowerZoneRows = () => {
    const arr = [];
  
    for (let i = 1; i <= 46; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };

  const upperZoneRows = () => {
    const arr = [];
  
    for (let i = 47; i <= 84; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  };

  const twistedTeaPartyDeckRows = () => [
    {
      value: 'GA2',
      label: 'Row GA2',
    }
  ];
  
  const coorsLightPartyDeckRows = () => [
    {
      value: 'GA7',
      label: 'Row GA7',
    }
  ];

  const endzoneRows = () => [
    {
      value: 'GA',
      label: 'Row GA',
    }
  ];

  export default {
    TTPD_ROW: twistedTeaPartyDeckRows(),
    CLPD_ROW: coorsLightPartyDeckRows(),
    LOWER_ZONE_ROWS: lowerZoneRows(),
    UPPER_ZONE_ROWS: upperZoneRows(),
    DEFAULT_ROWS: defaultRows(),
    ENDZONE_ROWS: endzoneRows()
  };
  