import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import STAMPEDERS_ROWS from "../constants/StampedersSeatRows";

class StampedersRowSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  getRows() {
    const { section } = this.props;
    if (section.includes("Lower Zone")) {
      return STAMPEDERS_ROWS.LOWER_ZONE_ROWS;
    } else if (section.includes("CLUBHOUSE")) {
      return STAMPEDERS_ROWS.CLUBHOUSE_ROWS;
    } else {
      return STAMPEDERS_ROWS.DEFAULT_ROWS;
    }
  }

  handleSelection = (event) => {
    this.setState({
      value: event.target.value,
    });

    this.props.onRowSelection(event.target.value);
  };

  render() {
    return (
      <FormControl>
        <InputLabel>Row</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: "row",
            id: "row",
          }}
          placeholder="Seat Row"
          fullWidth
        >
          {this.props.section &&
            this.getRows().map((row, index) => (
              <MenuItem selected={row.value === this.props.value} value={row.value} key={index}>
                {row.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
}

StampedersRowSelect.propsType = {
  classes: PropTypes.object.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  row: PropTypes.string.isRequired,
};

export default StampedersRowSelect;
