import React, { Component } from "react";
import moment from "moment";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { API } from "aws-amplify";
import { getLocalizedDateTime } from "../../libs/shared/helpers";
import { getTeamsBySlug } from "../../libs/shared/team-slug";
import Autocomplete from "./Autocomplete";

class EditModal extends Component {
  constructor(props) {
    super();
    const { selectedData } = props;
    const localizedDate = getLocalizedDateTime(
      selectedData.dateTime,
      selectedData.timezone,
      "HH:mm"
    );
    this.state = {
      eventId: selectedData.id,
      shortName: selectedData.shortName,
      longName: selectedData.longName,
      isTBD: selectedData.isTBD,
      isTestGame: selectedData.isTestGame,
      dateTime: `${localizedDate.stringDate}T${localizedDate.stringTime}`,
      timezone: selectedData.timezone,
      isArchived: selectedData.isArchived,
      homeTeamSlug: selectedData.homeTeamSlug,
      opponent: selectedData.opponent,
      isDateDefined: selectedData.isDateDefined,
      isSubmitting: false,
      isShowOptions: false,
    };
  }
  handleClose = () => {
    this.setState({ ...this.state, isShowOptions: false });
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = (value) => {
    this.props.onClose(value);
  };

  convertToUTC = (date, timezone) => {
    const offset =
      moment["tz"](moment(new Date(date)), timezone).utcOffset() * 60000;
    const offsetInHour = offset / 1000 / 60 / 60;
    const timeOfset = offsetInHour > 0 ? offsetInHour : offsetInHour * -1;

    const timzoneDateObject = new Date(`${date}:00.000-0${timeOfset}:00`);
    const utcStringDate = timzoneDateObject.toISOString();
    var parsedUTCDate = new Date(utcStringDate);
    return parsedUTCDate.valueOf();
  };

  handleChange = (event) => {
    const value =
      event.target.id === "isTestGame" ||
        event.target.id === "isTBD" ||
        event.target.id === "isDateDefined"
        ? event.target.checked
        : event.target.value;
    this.setState({ ...this.state, [event.target.id]: value });
  };

  handleSubmit = async () => {
    const {
      eventId,
      shortName,
      longName,
      isTBD,
      isTestGame,
      dateTime,
      timezone,
      isArchived,
      homeTeamSlug,
      opponent,
      isDateDefined,
    } = this.state;
    const { currUser } = this.props;
    const utcMiliDate = this.convertToUTC(dateTime, timezone);
    this.setState({ ...this.state, isSubmitting: true, isShowOptions: false });
    try {
      await API.post("v2", "marketplace/events/update", {
        headers: { email: currUser.email },
        body: {
          eventId,
          shortName,
          longName,
          isTBD,
          isTestGame,
          dateTime: utcMiliDate,
          isArchived: isArchived,
          opponent: opponent,
          isDateDefined: isDateDefined,
        },
      });

      window.location = `/game-page?selectedTeam=${homeTeamSlug}`;
      this.setState({ ...this.state, isSubmitting: false });
    } catch (e) {
      alert(e);
      this.setState({ ...this.state, isSubmitting: false });
    }
  };

  render() {
    const { open } = this.props;
    const {
      shortName,
      longName,
      isTBD,
      isTestGame,
      dateTime,
      isArchived,
      isSubmitting,
      opponent,
      homeTeamSlug,
      isShowOptions,
    } = this.state;
    const dialogTitle = isArchived ? "Archive Game" : "Edit Game";
    const dialogDesc = isArchived
      ? `The game: "${shortName}" will be archived.`
      : "";

    return (
      <Dialog open={open} onClose={this.handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{dialogDesc}</DialogContent>
        <DialogContent>
          {isArchived ? (
            <></>
          ) : (
            <>
              <label
                className="MuiFormLabel-root-249"
                data-shrink="true"
                htmlFor="Opponent"
              >
                Opponent
              </label>
              {isShowOptions ? (
                <div>
                  <Autocomplete
                    suggestions={getTeamsBySlug(homeTeamSlug)}
                    onSelect={(opponent) => {
                      this.setState({
                        ...this.state,
                        opponent,
                        isShowOptions: false,
                      });
                    }}
                  />
                </div>
              ) : (
                <div
                  className="filter-input"
                  onClick={() =>
                    this.setState({ ...this.state, isShowOptions: true })
                  }
                >
                  {opponent}
                </div>
              )}
              <TextField
                id={"shortName"}
                value={shortName}
                onChange={this.handleChange}
                autoFocus
                margin="dense"
                label="Short Name"
                type="text"
                fullWidth
              />
              <TextField
                id={"longName"}
                value={longName}
                onChange={this.handleChange}
                autoFocus
                margin="dense"
                label="Long Name"
                type="text"
                fullWidth
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id={"isTBD"}
                    checked={isTBD}
                    onChange={this.handleChange}
                    color="primary"
                  />
                }
                label="Is TBD"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id={"isTestGame"}
                    checked={isTestGame}
                    onChange={this.handleChange}
                    color="primary"
                  />
                }
                label="Is Test Game"
              />
              <TextField
                id={"dateTime"}
                value={dateTime}
                label="Game Date"
                onChange={this.handleChange}
                autoFocus
                margin="dense"
                type="datetime-local"
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={isSubmitting ? () => { } : this.handleSubmit}
            color="primary"
          >
            {isSubmitting ? "Processing..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  componentDidUpdate(prevPops) {
    const { selectedData } = this.props;
    if (JSON.stringify(prevPops) !== JSON.stringify(this.props)) {
      const localizedDate = getLocalizedDateTime(
        selectedData.dateTime,
        selectedData.timezone,
        "HH:mm"
      );
      this.setState({
        eventId: selectedData.id,
        shortName: selectedData.shortName,
        longName: selectedData.longName,
        isTBD: selectedData.isTBD,
        isTestGame: selectedData.isTestGame,
        dateTime: `${localizedDate.stringDate}T${localizedDate.stringTime}`,
        timezone: selectedData.timezone,
        isArchived: selectedData.isArchived,
        homeTeamSlug: selectedData.homeTeamSlug,
        opponent: selectedData.opponent,
        isDateDefined: selectedData.isDateDefined,
      });
    }
  }
}

export default EditModal;
