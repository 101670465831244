import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';

import Routes from './Routes';
import Nav from './components/Nav';

const styles = theme => ({
  main: {
    padding: '1rem',
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: null,
    };

    this.handleLogout = this.handleLogout.bind(this);
  }

  setUserAttributes = ({ attributes, signInUserSession }) => {
    this.setState({
      user: {
        ...attributes,
        userGroup: signInUserSession.accessToken.payload[
          'cognito:groups'
        ][0].toLowerCase(),
      },
    });
  };

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  async handleLogout() {
    await Auth.signOut();

    this.userHasAuthenticated(false);
    window.location.replace('/login');
  }

  async componentDidMount() {
    axios.interceptors.request.use(async (config) => {
      try {
        await Auth.currentSession(); // Validate session
        return config;
      } catch (error) {
        if (error.code === 'UserNotFoundException') {
          console.warn('Session expired or user signed out:', error);
          await this.handleLogout();
        }
        return Promise.reject(error);
      }
    });

    try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);

        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        this.setUserAttributes(user);
      }
    } catch (e) {
      if (e !== 'No current user') {
        console.warn(e);
        await this.handleLogout();
      }
    } finally {
      this.setState({ isAuthenticating: false });
    }
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      currUser: this.state.user,
      handleLogout: this.handleLogout,
      setUserAttributes: this.setUserAttributes,
    };

    const { classes } = this.props;

    return (
      !this.state.isAuthenticating && (
        <div className={classes.root}>
          <Nav
            isAuthenticated={this.state.isAuthenticated}
            logout={this.handleLogout}
            currUser={this.state.user}
          />
          <main className={classes.main}>
            <Routes childProps={childProps} />
          </main>
        </div>
      )
    );
  }
}

export default withStyles(styles)(App);
