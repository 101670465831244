import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, TextField, FormControl, Grid } from "@material-ui/core";
import { Auth } from "aws-amplify";

import { isValidUserGroup } from "../libs/rbac";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      mfa: "",
      user: null,
      requireMFA: false,
      isSubmitting: false,
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isSubmitting: true,
    });
    // sign in using sms mfa code
    let signInResponse = null;
    try {
      if (this.state.requireMFA === true) {
        signInResponse = await Auth.confirmSignIn(
          this.state.signInResponse,
          this.state.mfa,
          "SMS_MFA"
        );
      } else {
        signInResponse = await Auth.signIn(
          this.state.email,
          this.state.password
        );
        if (signInResponse.challengeName === "SMS_MFA") {
          this.setState({
            requireMFA: true,
            signInResponse: signInResponse,
            isSubmitting: false,
          });
          alert("Please enter the MFA code sent to your phone");
          return;
        }
      }
      const { signInUserSession } = signInResponse;
      const userGroups =
        signInUserSession.accessToken.payload["cognito:groups"];

      if (!userGroups || !isValidUserGroup(userGroups[0].toLowerCase())) {
        await Auth.signOut();

        this.props.userHasAuthenticated(false);
        alert("You have no access to this page...");
      } else {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
        this.props.userHasAuthenticated(true);
        this.props.setUserAttributes(user);
      }

      this.setState({
        isSubmitting: false,
        requireMFA: false,
        signInResponse: null,
        mfa: "",
      });
    } catch (e) {
      this.setState({
        isSubmitting: false,
        requireMFA: false,
        signInResponse: null,
        mfa: "",
      });
      alert(e.message);
    }
  };

  render() {
    return (
      <div className="Login">
        <Grid container justify="center">
          <Grid item xs={6}>
            <form
              onSubmit={this.handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormControl>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  margin="normal"
                  onChange={this.handleChange}
                />
              </FormControl>

              <FormControl>
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  margin="normal"
                  onChange={this.handleChange}
                />
              </FormControl>
              {this.state.requireMFA === true ? (
                <FormControl>
                  <TextField
                    id="mfa"
                    label="MFA Code"
                    type="number"
                    margin="normal"
                    onChange={this.handleChange}
                  />
                </FormControl>
              ) : null}
              <FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!this.validateForm() || this.state.isSubmitting}
                  type="submit"
                  size="large"
                >
                  Login
                </Button>
              </FormControl>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Login.props = {
  userHasAuthenticated: PropTypes.func.isRequired,
};

export default Login;
